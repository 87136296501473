import React, { useState, useEffect } from "react"
import { withStyles, makeStyles } from "@material-ui/core/styles"
import clsx from "clsx"

import { withContext } from "../../../../Utils/context"
import { str2num } from "../../../../Utils/helpers"
import axios from "axios"

import {
  TextField,
  CircularProgress,
  Dialog,
  Divider,
  Fab,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  Toolbar,
  Typography,
  FormControlLabel,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  List,
  ListItem,
  Box,
} from "@material-ui/core"
import {
  Add as AddIcon,
  Refresh as RefreshIcon,
  FilterList as FilterListIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  CheckBox as CheckBoxIcon,
} from "@material-ui/icons"
import Avatar from "@material-ui/core/Avatar"

import Searchbar from "../../../layout/Searchbar"
import AddUserForm from "../../../layout/AddUserForm"
import Pagination from "../../../layout/Pagination"
import UserListTableHead from "./UserListTableHead"
import UserTableRow from "./UserTableRow"

const USER_API = process.env.REACT_APP_USER_URL

const BodyAvatar = withStyles(theme => ({
  root: {
    width: 27,
    height: 27,
    border: `2px solid ${theme.palette.background.paper}`,

    fontSize: "11px",
    fontWeight: 500,
    "&:hover": {
      cursor: "pointer",
    },
  },
}))(Avatar)

const BodyAvatarSquare = withStyles(theme => ({
  root: {
    fontSize: "10.2px",
    fontWeight: 500,
    width: "65px",
    borderRadius: "16px",
    height: "24px",
    "&:hover": {
      cursor: "pointer",
    },
  },
}))(Avatar)

const defaultsLicense = [
  {
    displayName: "Active",
    searchName: ["ACTIVE"],
    filterBy: "accountStatus",
    isChecked: false,
    iconName: "accountStatusActive",
  },
  {
    displayName: "Disabled",
    searchName: ["DISABLED"],
    filterBy: "accountStatus",
    isChecked: false,
    iconName: "accountStatusDisabled",
  },
  {
    displayName: "Public cloud",
    searchName: ["COGNITO_PRIMARY_USERPOOL"],
    filterBy: "identityProvider",
    isChecked: false,
    iconName: "identityProviderPrimary",
  },
  {
    displayName: "Federated",
    searchName: ["EXTERNAL_PROVIDER"],
    filterBy: "identityProvider",
    isChecked: false,
    iconName: "identityProviderFederated",
  },
  {
    displayName: "No license assigned",
    searchName: [],
    filterBy: "entitlements",
    iconName: "noEntitlements",
    isChecked: false,
  },
]

const UserListPage = props => {
  const params = new URLSearchParams(props.location.search)
  const classes = useStyles()

  // const theme = useTheme()

  // type stage =
  //  | { s: 'LOADING'               }
  //  | { s: 'ERROR'                 }
  //  | { s: 'EMPTY',   data: []     }
  //  | { s: 'SUCCESS', data: User[] }
  const [stage, setStage] = useState({ s: "EMPTY" })

  const [modalVisible, setModalVisible] = useState(false)
  const [modalClosing, setModalClosing] = useState(false)
  const [filterDialog, setFilterDialog] = useState(false)
  const [filterUsageType, setFilterUsageType] = useState([])
  const [filterSearch, setFilterSearch] = useState("")

  const [searchBy, setSearchBy] = useState(params.get("searchBy") ?? "")
  const [sortBy, setSortBy] = useState({
    key: params.get("sortBy") ?? "createdDate",
    value: params.get("direction") ?? "desc",
  })
  const [pagination, setPagination] = useState({
    limit: str2num(params.get("limit") ?? 50),
    offset: str2num(params.get("offset") ?? 0),
  })

  const [totalUserCount, setTotalUserCount] = useState(0)
  const [usageType, setUsageType] = useState(defaultsLicense)
  const [searchLicense, setSearchLicense] = React.useState(usageType)
  const filterStateActive = filterUsageType.length > 0

  React.useEffect(() => {
    const results = usageType
      .filter(filterLicenseType)
      .filter(item =>
        item.displayName.toLowerCase().includes(filterSearch.toLowerCase()),
      )
    setSearchLicense(results)
  }, [filterSearch, usageType])

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_USER_URL}/entitlements/meta`)
      .then(
        x =>
          x.data?.data.sort((a, b) =>
            a.displayName.localeCompare(b.displayName),
          ) || [],
      )
      .then(x =>
        x.map(obj => ({
          ...obj,
          filterBy: "entitlements",
          isChecked: false,
          searchName: [obj.entitlement],
        })),
      )
      .then(licenses =>
        setUsageType(currentState => [...currentState, ...licenses]),
      )
      .then(
        setUsageType(currentState => [
          ...new Map(
            currentState.map(item => [item.displayName, item]),
          ).values(),
        ]),
      )
  }, [])

  useEffect(() => {
    setStage({ s: "LOADING" })
    let data = {
      sortBy,
      limit: pagination.limit,
      offset: pagination.offset,
    }
    const params = new URLSearchParams()
    params.append("sortBy", sortBy.key)
    params.append("direction", sortBy.value)
    params.append("limit", pagination.limit)
    params.append("offset", pagination.offset)

    if (searchBy !== "") {
      data.searchBy = searchBy
      params.append("searchBy", searchBy)
    }

    if (filterUsageType.length > 0) {
      let groupFilterItem = filterUsageType.reduce((r, a) => {
        r[a.filterBy] = [...(r[a.filterBy] || []), ...a.searchName]
        return r
      }, {})

      const filterBy = []
      for (const key in groupFilterItem) {
        filterBy.push({ key: key, value: groupFilterItem[key] })
      }
      data.filterBy = filterBy
    }

    axios
      .post(`${USER_API}/`, data)
      .then(response => {
        const { records: data, totalCount } = response.data
        setTotalUserCount(totalCount || 0)
        data.length > 0
          ? setStage({ s: "SUCCESS", data })
          : setStage({ s: "EMPTY", data })
        props.history.replace(
          window.location.pathname + "?" + params.toString(),
        )
      })
      .catch(_error => setStage({ s: "ERROR" }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy, pagination, filterUsageType])

  const handleSearchChange = value => {
    setSearchBy(value)
    setPagination({
      limit: pagination.limit,
      offset: 0,
    })
  }

  const handleFilterSearchChange = e => {
    setFilterSearch(e.target.value)
  }

  const handleSort = key => {
    // reset pagination
    setPagination({ offset: 0, limit: pagination.limit })
    sortBy.key === key
      ? // toggle sort direction
        setSortBy({ key, value: sortBy.value === "asc" ? "desc" : "asc" })
      : // use default direction
        setSortBy({
          key,
          value: ["createdDate"].includes(key) ? "desc" : "asc",
        })
  }

  const handleChangePage = (_event, newPage) => {
    setPagination({
      limit: pagination.limit,
      offset: newPage * pagination.limit,
    })
  }

  const handleChangeRowsPerPage = e => {
    if (stage.s === "EMPTY" || stage.s === "SUCCESS") {
      setPagination({
        limit: e.target.value,
        offset: 0,
      })
    }
  }

  const handleReload = () =>
    setPagination({
      limit: pagination.limit,
      offset: pagination.offset,
    })

  const clearFilter = () => {
    const list = usageType.map(item => ({ ...item, isChecked: false }))
    setUsageType(list)
    setFilterSearch("")
  }

  const handleUsageChange = e => {
    const { checked, name } = e.target

    const isEntitlement = usageType
      .filter(filterLicenseType)
      .some(el => el.displayName === name)

    let list
    if (name === "No license assigned" && checked) {
      list = usageType.map(item => {
        return {
          ...item,
          isChecked:
            item.displayName === "No license assigned"
              ? true
              : item.filterBy === "entitlements"
              ? false
              : item.isChecked,
        }
      })
    } else {
      list = usageType.map(item =>
        item.displayName === name
          ? { ...item, isChecked: checked }
          : item.displayName === "No license assigned" && isEntitlement
          ? { ...item, isChecked: false }
          : item,
      )
    }
    list = [...new Map(list.map(item => [item.displayName, item])).values()]
    setUsageType(list)
  }

  const filterDefaults = () => {
    // for the filter
    let list = usageType.map(item => ({ ...item, isChecked: false }))
    list = [...list, ...filterUsageType]
    list = [...new Map(list.map(item => [item.displayName, item])).values()]

    setUsageType(list)
  }

  const applyFilterChanges = () => {
    setFilterUsageType(usageType.filter(item => item.isChecked))
    setPagination({
      limit: pagination.limit,
      offset: 0,
    })
    setFilterDialog(false)
  }
  const cancelFilter = () => {
    setFilterDialog(false)
  }

  const getEntitlementDisplay = entls => {
    if (!entls) return
    const entitlements = entls?.split(`,`).map(ent => {
      const entitleFound = usageType.find(i => i.entitlement === ent)
      return entitleFound
        ? {
            displayName: entitleFound.displayName,
            entitlement: entitleFound.entitlement,
          }
        : { displayName: "", entitlement: ent }
    })
    return entitlements
  }

  const UserTable = () =>
    stage.s === "LOADING" ? (
      <div className={classes.center}>
        <CircularProgress />
      </div>
    ) : stage.s === "ERROR" ? (
      <Typography variant="body1" className={classes.center}>
        An error occurred fetching users
      </Typography>
    ) : stage.s === "EMPTY" ? (
      <Typography variant="body1" className={classes.center}>
        No users found
      </Typography>
    ) : (
      <Table size="small">
        <UserListTableHead
          activeKey={sortBy.key}
          direction={sortBy.value}
          onSort={handleSort}
        />
        <TableBody>
          {stage.data.slice(0, pagination.limit).map((user, i) => (
            <>
              <UserTableRow
                key={user.id}
                userId={user.id}
                displayName={user.displayName}
                emailAddress={user.emailAddress}
                organisationId={user.organisationId}
                organisationName={user.organisationName}
                accountStatus={user.accountStatus}
                biIdentityId={user["biIdentityId"]}
                primaryIdentityId={user["primaryIdentityId"]}
                identityProvider={user.identityProvider}
                entitlements={getEntitlementDisplay(user.entitlements)}
                createdDate={user.createdDate}
              />
            </>
          ))}
        </TableBody>
      </Table>
    )

  function filterLicenseType(i) {
    return i.filterBy === "entitlements" && i.iconName !== "noEntitlements"
  }

  function filterStatusType(i) {
    return i?.filterBy === "accountStatus"
  }
  function filterIdentityType(i) {
    return i?.filterBy === "identityProvider"
  }

  const noEntitlements = usageType.find(i => i.iconName === "noEntitlements")

  document.title = "Users"
  return (
    <Grid container>
      <Grid container justify="space-between">
        <Typography component="h4" variant="h6" className={classes.title}>
          Users
        </Typography>
        <Fab
          variant="extended"
          size="small"
          color="primary"
          className={classes.addUser}
          onClick={() => setModalVisible(true)}
        >
          <AddIcon fontSize="small" className={classes.addIcon} />
          Create User
        </Fab>
      </Grid>
      <Grid item xs={12}>
        <Paper className={clsx("slowfadein", classes.paper)}>
          <Toolbar className={classes.toolbar}>
            <Searchbar
              value={searchBy}
              placeholder="Search by name, email, company"
              disabled={false}
              onChange={handleSearchChange}
            />

            <Typography variant="body2">
              Total records:{" "}
              <Typography variant="subtitle2" component="span">
                {totalUserCount.toLocaleString()}
              </Typography>
            </Typography>
            <Box m={1} />

            <IconButton
              aria-label="Reload"
              size="small"
              onClick={handleReload}
              disabled={stage.s === "LOADING"}
            >
              <RefreshIcon />
            </IconButton>
            <IconButton
              className={classes.filter}
              aria-label="Filter List"
              size="small"
              onClick={() => setFilterDialog(true)}
              // disabled={loading}
              style={
                filterStateActive
                  ? { color: "#ff9800" }
                  : { color: "#0000008a" }
              }
            >
              <FilterListIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <UserTable />
          <Pagination
            data={stage.data}
            rowCount={totalUserCount}
            className={"pagination__show-count"}
            offset={pagination.offset}
            rowsPerPage={pagination.limit}
            rowsPerPageOptions={[50, 100, 250]}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </Grid>
      <Dialog
        open={filterDialog}
        onEnter={filterDefaults}
        aria-labelledby="Advance filter"
        maxWidth="sm"
        fullWidth={true}
        onClose={() => setFilterDialog(true)}
      >
        <Grid container>
          <Grid item xs={12}>
            <DialogTitle id="filter-dialog-title">
              Filter user records by...
            </DialogTitle>
            <DialogContent>
              <Grid
                container
                spacing={2}
                direction="row"
                justify="center"
                alignItems="flex-start"
                style={{ height: "100%" }}
              >
                <Grid item xs={6}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={12} sm={6}>
                          <Box m={1} />
                          Status:
                          <Box m={1} />
                          {usageType.filter(filterStatusType).map(i => {
                            const isActive =
                              i.iconName === "accountStatusActive"
                            return (
                              <Grid item xs={12}>
                                <FormControlLabel
                                  control={
                                    <>
                                      <Checkbox
                                        color="primary"
                                        icon={
                                          <CheckBoxOutlineBlankIcon fontSize="small" />
                                        }
                                        checkedIcon={
                                          <CheckBoxIcon fontSize="small" />
                                        }
                                        name={i.displayName}
                                        value={i.displayName}
                                        checked={i.isChecked}
                                        onChange={handleUsageChange}
                                      />

                                      <BodyAvatarSquare
                                        variant="square"
                                        style={{
                                          backgroundColor: isActive
                                            ? "#c4df9b"
                                            : "#e47f63",
                                          color: isActive
                                            ? "rgba(0, 0, 0, 0.87)"
                                            : "#fafafa",
                                        }}
                                      >
                                        {isActive ? "ACTIVE" : "DISABLED"}
                                      </BodyAvatarSquare>
                                    </>
                                  }
                                />
                              </Grid>
                            )
                          })}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Box m={5.25} />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Box m={1} />
                          Identity type:
                          <Box m={1} />
                          {usageType.filter(filterIdentityType).map(i => {
                            const isPrimary =
                              i.iconName === "identityProviderPrimary"
                            return (
                              <Grid item xs={12}>
                                <FormControlLabel
                                  control={
                                    <>
                                      <Checkbox
                                        color="primary"
                                        icon={
                                          <CheckBoxOutlineBlankIcon fontSize="small" />
                                        }
                                        checkedIcon={
                                          <CheckBoxIcon fontSize="small" />
                                        }
                                        name={i.displayName}
                                        value={i.displayName}
                                        checked={i.isChecked}
                                        onChange={handleUsageChange}
                                      />
                                      <BodyAvatar
                                        style={{
                                          marginRight: "4px",
                                          backgroundColor: isPrimary
                                            ? "#eecc70"
                                            : "#774ebf",
                                          color: isPrimary
                                            ? "rgba(0, 0, 0, 0.87)"
                                            : "#fafafa",
                                        }}
                                      >
                                        {isPrimary ? "P" : "F"}
                                      </BodyAvatar>
                                    </>
                                  }
                                  label={i.displayName}
                                />
                              </Grid>
                            )
                          })}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Box m={1} />
                      License type:
                      <Box m={1} />
                      <Grid container>
                        <Grid item xs={12}>
                          <Box m={1} />
                          <TextField
                            variant="outlined"
                            onChange={handleFilterSearchChange}
                            value={filterSearch}
                            size="small"
                            fullWidth
                            label="Filter..."
                          />
                          <Box m={1} />

                          <Grid
                            container
                            className={classes.fixedHeightLicense}
                          >
                            <List>
                              {searchLicense.map(i => (
                                <>
                                  <ListItem
                                    alignItems="flex-start"
                                    dense={true}
                                    disableGutters="true"
                                  >
                                    <FormControlLabel
                                      control={
                                        <>
                                          <Checkbox
                                            color="primary"
                                            icon={
                                              <CheckBoxOutlineBlankIcon fontSize="small" />
                                            }
                                            checkedIcon={
                                              <CheckBoxIcon fontSize="small" />
                                            }
                                            name={i.displayName}
                                            value={i.displayName}
                                            checked={i.isChecked}
                                            onChange={handleUsageChange}
                                          />
                                        </>
                                      }
                                      label={i.displayName}
                                    />
                                  </ListItem>
                                </>
                              ))}
                            </List>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Box m={2} />
                          <Divider />
                          <Box m={1} />
                          {
                            <FormControlLabel
                              control={
                                <>
                                  <Checkbox
                                    color="primary"
                                    icon={
                                      <CheckBoxOutlineBlankIcon fontSize="small" />
                                    }
                                    checkedIcon={
                                      <CheckBoxIcon fontSize="small" />
                                    }
                                    name={noEntitlements.displayName}
                                    value={noEntitlements.displayName}
                                    checked={noEntitlements.isChecked}
                                    onChange={handleUsageChange}
                                  />
                                </>
                              }
                              label={noEntitlements.displayName}
                            />
                          }
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Grid
                container
                justify="space-between"
                style={{
                  padding: "15px",
                }}
              >
                <Grid item xs={6}>
                  <Button variant="outlined" onClick={clearFilter}>
                    Clear
                  </Button>
                </Grid>

                <Grid item xs={6}>
                  <Grid container justify="flex-end">
                    <Grid item justify="flex-end">
                      <Button onClick={cancelFilter}>Cancel</Button>
                    </Grid>
                    <Grid item justify="flex-end">
                      <Button
                        onClick={applyFilterChanges}
                        variant="contained"
                        color="primary"
                      >
                        Apply
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogActions>
          </Grid>
        </Grid>
      </Dialog>

      <Dialog
        scroll="body"
        open={modalVisible}
        onClose={() => setModalClosing(true)}
        aria-labelledby="Add new user"
      >
        <AddUserForm
          dismiss={() => setModalVisible(false)}
          modalClosing={modalClosing}
        />
      </Dialog>
    </Grid>
  )
}

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: 500,
    color: "#4a4a4a",
    fontSize: "18px/14px",
    marginBottom: theme.spacing(2),
  },
  paper: {
    minWidth: 620,
  },
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  flex: {
    flex: 1,
  },
  filter: {
    marginLeft: theme.spacing(1),
  },
  statusHeading: {
    [theme.breakpoints.up("sm")]: {
      textAlign: "right",
      paddingRight: "8px",
    },
  },
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "200px",
  },
  addUser: {
    minWidth: "130px !important",
    color: "white",
    fontSize: ".9em",
  },
  addIcon: {
    fontSize: "1.3em",
  },
  smallIcon: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  fixedHeightLicense: {
    height: 300,
    overflowY: "scroll",
    overflowX: "hidden",
    [theme.breakpoints.up("lg")]: {
      height: 300,
    },
    // keep scrollbar appearing
    "&::-webkit-scrollbar": {
      "-webkit-appearance": "none",
      width: "7px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      "-webkit-box-shadow": "0 0 1px rgba(255, 255, 255, 0.5)",
    },
  },
}))

export default withContext(UserListPage)
