import React, { useState } from "react"
import PropTypes from "prop-types"
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core"
import CountryCodeMap from "../../Utils/countryDataList.json"

const SelectCountryList = props => {
  const [value, setValue] = useState(props.value)

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 400,
        width: 250,
      },
    },
  }

  function changeHandler(event) {
    setValue(event.target.value)
    props.onChange(event.target.value)
  }

  return (
    <FormControl variant="outlined" size="small" fullWidth>
      <InputLabel id="select-company">Country</InputLabel>
      <Select
        labelId="select-company"
        required
        size="small"
        label="Company"
        value={value}
        onChange={changeHandler}
        MenuProps={MenuProps}
      >
        <MenuItem key={""} value={""}>
          None
        </MenuItem>
        {CountryCodeMap.map(item => (
          <MenuItem key={item.label} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
SelectCountryList.propTypes = {
  onChange: PropTypes.func.isRequired,
}
export default SelectCountryList
