import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import classNames from "classnames"
import { Grid, Typography, Paper } from "@material-ui/core"
import { TopCompanies, HighUsage, TopProjects } from "./tables"

import ActivitySummaryChart from "./charts/ActivitySummary"
import Trends from "./Trends"

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: 300,
    color: "#4a4a4a",
    fontSize: "18px/14px",
    marginBottom: theme.spacing(2),
  },
  paddingBottom: {
    padding: 0,
    paddingBottom: 40,
  },
  chartContainer: {
    display: "flex",
    flexDirection: "column",
  },
  chartPaper: { padding: theme.spacing(5) },
  fixedHeight: {
    height: 300,
    [theme.breakpoints.up("lg")]: {
      height: 500,
    },
  },
}))

function DashboardPage() {
  const classes = useStyles()
  const chartPaper = classNames(
    classes.chartPaper,
    classes.fixedHeight,
    "fastfadein",
    classes.paddingBottom,
  )

  return (
    <Grid container spacing={2} style={{ maxWidth: "100%" }}>
      <Grid item xs={12} lg={7} classnames={classes.chartContainer}>
        <Typography component="h4" variant="h6" className={classes.title}>
          Activity Summary
        </Typography>
        <Paper className={chartPaper}>
          <ActivitySummaryChart />
        </Paper>
      </Grid>
      <Grid item xs={12} lg={5}>
        <Typography component="h4" variant="h6" className={classes.title}>
          &nbsp;
          {/* Narrative */}
        </Typography>
        <Paper className="fastfadein">
          <Trends />
        </Paper>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Typography component="h4" variant="h6" className={classes.title}>
          Top Companies by Word Count
        </Typography>
        <Paper className="slowfadein">
          <TopCompanies />
        </Paper>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Typography component="h4" variant="h6" className={classes.title}>
          High Usage
        </Typography>
        <Paper className="slowfadein">
          <HighUsage />
        </Paper>
      </Grid>
      <Grid item xs={12} md={8}>
        <Typography component="h4" variant="h6" className={classes.title}>
          Top Projects by Word Count
        </Typography>
        <Paper className="slowfadein">
          <TopProjects />
        </Paper>
      </Grid>
    </Grid>
  )
}

export default DashboardPage
