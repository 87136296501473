import React from "react"
import clsx from "clsx"
import { lighten, makeStyles } from "@material-ui/core/styles"
import { withContext } from "../../../../../Utils/context"
import axios from "axios"
import Searchbar from "../../../../layout/Searchbar"
import {
  Dialog,
  DialogTitle as MuiDialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  Toolbar,
  Paper,
  CircularProgress,
  Grid,
  Button,
} from "@material-ui/core"
import { Add as AddIcon, Close as CloseIcon } from "@material-ui/icons"
import AddUserForm from "../../../../layout/AddUserForm"
import Pagination from "../../../../layout/Pagination"
import { TruncateAsRequired } from "../../../../layout/TruncateAsRequired"

const USER_API = process.env.REACT_APP_USER_URL

const dialogTitleUseStyles = makeStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}))

function DialogTitle(props) {
  const classes = dialogTitleUseStyles()

  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{props.children}</Typography>
      <IconButton
        aria-label="Close"
        className={classes.closeButton}
        onClick={props.onClose}
      >
        <CloseIcon />
      </IconButton>
    </MuiDialogTitle>
  )
}

const useStyles = makeStyles(theme => ({
  dialogPaper: {
    width: 750,
  },
  title: {
    fontWeight: 400,
  },
  content: {
    padding: 0,
  },
  highlight: {
    backgroundColor: lighten(theme.palette.primary.light, 0.85),
  },
  dangerButton: {
    backgroundColor: theme.palette.danger.main,
    color: "white",
  },
  cancelButton: {
    marginRight: theme.spacing(1),
  },
}))

function UserSelect(props) {
  const classes = useStyles()
  const [searchBy, setSearchBy] = React.useState("")
  const [sortBy, setSortBy] = React.useState({
    key: "displayName",
    value: "asc",
  })
  const [loading, setLoading] = React.useState(true)
  const [error, setError] = React.useState(false)
  const [data, setData] = React.useState([])
  const [pagination, setPagination] = React.useState({
    limit: 7,
    offset: 0,
  })
  const [selectedId, setSelectedId] = React.useState("")
  const [modalVisible, setModalVisible] = React.useState(false)
  const [modalClosing, setModalClosing] = React.useState(false)

  React.useEffect(() => {
    setLoading(true)
    setError(false)

    let data = {
      filterBy: [
        {
          key: "organisationId",
          value: null,
        },
      ],
      sortBy,
      limit: pagination.limit,
      offset: pagination.offset,
    }

    if (searchBy !== "") {
      data.searchBy = searchBy
    }

    axios({
      method: "post",
      url: `${USER_API}/`,
      data,
    })
      .then(response => {
        setLoading(false)
        setData(response.data.records)
      })
      .catch(error => {
        setLoading(false)
        setError(true)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open, sortBy, pagination])

  React.useEffect(() => {
    setPagination({
      limit: 7,
      offset: 0,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchBy])

  const toggleOpen = () => props.onToggleOpen()

  const handleSearchChange = value => setSearchBy(value)

  const handleSort = value => {
    if (sortBy.key === value) {
      setSortBy({
        key: value,
        value: sortBy.value === "asc" ? "desc" : "asc",
      })
    } else {
      setSortBy({
        key: value,
        value: "asc",
      })
    }
  }

  function handleSelect(event, id) {
    if (selectedId === id) {
      setSelectedId("")
    } else {
      setSelectedId(id)
    }
  }

  const handleChangePage = (event, newPage) =>
    setPagination({
      limit: 7,
      offset: newPage * pagination.limit,
    })

  const handleSave = () => {
    props.onSave(selectedId)
    props.onToggleOpen()
  }

  return (
    <React.Fragment>
      <Dialog
        open={props.open}
        onClose={toggleOpen}
        aria-labelledby="user-select-title"
        classes={{ paper: classes.dialogPaper }}
      >
        <DialogTitle
          id="user-select-title"
          onClose={toggleOpen}
          className={classes.title}
        >
          Select a user
        </DialogTitle>
        <DialogContent dividers className={classes.content}>
          <Toolbar>
            <Paper style={{ width: "100%" }}>
              <Searchbar
                placeholder="Search by name"
                disabled={false}
                onChange={handleSearchChange}
              />
            </Paper>
          </Toolbar>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={sortBy.key === "displayName"}
                    direction={sortBy.value}
                    onClick={() => handleSort("displayName")}
                  >
                    User name
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortBy.key === "emailAddress"}
                    direction={sortBy.value}
                    onClick={() => handleSort("emailAddress")}
                  >
                    Email
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading || error ? (
                <TableRow style={{ height: 37 * 7 }}>
                  <TableCell padding="checkbox" align="center" colSpan={2}>
                    {loading ? (
                      <CircularProgress />
                    ) : (
                      <Typography variant="body1">
                        Sorry an error occurred fetching users
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
              ) : (
                <React.Fragment>
                  {data.length === 0 ? (
                    <TableRow style={{ height: 37 * 7 }}>
                      <TableCell colSpan={2} align="center">
                        <Typography variant="body1">No users found</Typography>
                      </TableCell>
                    </TableRow>
                  ) : (
                    <React.Fragment>
                      {data.map(user => (
                        <TableRow
                          key={user.id}
                          hover
                          tabIndex={-1}
                          className={clsx("slowfadein", {
                            [classes.highlight]: selectedId === user.id,
                          })}
                          style={{ cursor: "pointer" }}
                          onClick={event => handleSelect(event, user.id)}
                        >
                          <TableCell>
                            <TruncateAsRequired>
                              {user.displayName}
                            </TruncateAsRequired>
                          </TableCell>
                          <TableCell>
                            <TruncateAsRequired>
                              {user.emailAddress}
                            </TruncateAsRequired>
                          </TableCell>
                        </TableRow>
                      ))}
                      {data.length < 7 && (
                        <TableRow
                          style={{ height: 33 * (7 - data.length) }}
                          colSpan={2}
                        >
                          <TableCell />
                        </TableRow>
                      )}
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
            </TableBody>
          </Table>

          <Pagination
            className="pagination__hide-count select-user"
            data={data}
            offset={pagination.offset}
            rowsPerPage={pagination.limit}
            rowsPerPageOptions={[]}
            onChangePage={handleChangePage}
          />
        </DialogContent>
        <DialogActions>
          <Grid container>
            <Grid item xs={6} align="left">
              <Button
                variant="outlined"
                size="small"
                color="primary"
                className={classes.addUser}
                onClick={() => {
                  props.onToggleOpen()
                  setModalVisible(true)
                }}
              >
                <AddIcon fontSize="small" className={classes.addIcon} />
                Create A New User
              </Button>
            </Grid>
            <Grid item xs={6} align="right">
              <Button
                onClick={toggleOpen}
                size="small"
                className={classes.cancelButton}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                size="small"
                variant="contained"
                disabled={selectedId === ""}
                onClick={handleSave}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      <Dialog
        scroll="body"
        open={modalVisible}
        onClose={() => setModalClosing(true)}
        aria-labelledby="Add new user"
      >
        <AddUserForm
          organisationName={props.organisationName}
          organisationId={props.organisationId}
          dismiss={() => {
            setModalVisible(false)
            props.refreshUsers(true)
          }}
          modalClosing={modalClosing}
        />
      </Dialog>
    </React.Fragment>
  )
}

export default withContext(UserSelect)
