import React from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import classNames from "classnames"

import { Link } from "react-router-dom"
import { format } from "date-fns"

import { TableRow, TableCell, Chip } from "@material-ui/core"

import { TruncateAsRequired } from "../../../layout/TruncateAsRequired"

const Cell = ({ title, children }) => (
  <TableCell>
    <TruncateAsRequired title={title}>{children}</TruncateAsRequired>
  </TableCell>
)

const nowUTC = date => {
  let now = date ? new Date(date) : new Date()
  now.setTime(now.getTime() + now.getTimezoneOffset() * 60 * 1000)
  return now
}

const OrganisationTableRow = ({
  organisationId,
  displayName,
  partnerId,
  partnerName,
  userCount,
  accountStatus,
  createdDate,
}) => {
  const classes = useStyles()

  let accountStatusStyle
  switch (accountStatus) {
    case "ACTIVE":
      accountStatusStyle = classNames(classes.chip, classes.success)
      break
    case "SUSPENDED":
      accountStatusStyle = classNames(classes.chip, classes.warning)
      break
    case "DISABLED":
      accountStatusStyle = classNames(classes.chip, classes.disabled)
      break
    default:
      accountStatusStyle = classes.chip
      break
  }

  return (
    <TableRow className="slowfadein">
      <Cell title={displayName}>
        <Link to={"/company/" + organisationId}>{displayName}</Link>
      </Cell>
      <Cell title={partnerName}>
        {partnerName ? (
          <Link to={"/partner/" + partnerId}>{partnerName}</Link>
        ) : (
          "—" // em dash
        )}
      </Cell>
      <TableCell align="center">{userCount}</TableCell>
      <TableCell align="center">
        <Chip
          label={accountStatus}
          size="small"
          className={accountStatusStyle}
        />
      </TableCell>
      <TableCell align="right">
        {format(nowUTC(createdDate), "MMMM dd, yyyy")}
      </TableCell>
    </TableRow>
  )
}

const useStyles = makeStyles(theme => ({
  chip: {
    fontSize: "8pt",
    fontWeight: 500,
  },
  success: {
    backgroundColor: theme.palette.success.light,
  },
  warning: {
    backgroundColor: theme.palette.warning.light,
  },
  disabled: {
    backgroundColor: theme.palette.danger.dark,
    color: "#fff",
  },
  primary: {
    backgroundColor: theme.palette.primary.light,
    color: "#fff",
  },
  mailIcon: {
    fontSize: "16px",
    verticalAlign: "middle",
    marginRight: "4px",
    color: "rgba(0, 0, 0, 0.38)",
  },
}))

OrganisationTableRow.propType = {
  organisationId: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  partnerId: PropTypes.string,
  partnerName: PropTypes.string,
  userCount: PropTypes.string.isRequired,
  accountStatus: PropTypes.string.isRequired,
  createdDate: PropTypes.string.isRequired,
}

export default OrganisationTableRow
