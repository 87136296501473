import React from "react"
import PropTypes from "prop-types"

import {
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
} from "@material-ui/core"

const UserListTableHead = ({ activeKey, direction, onSort }) => {
  return (
    <TableHead>
      <TableRow>
        <TableCell style={{ width: "25%" }}>
          <TableSortLabel
            active={activeKey === "displayName"}
            direction={direction}
            onClick={() => onSort("displayName")}
          >
            User name
          </TableSortLabel>
        </TableCell>
        <TableCell style={{ width: "25%" }}>
          <TableSortLabel
            active={activeKey === "emailAddress"}
            direction={direction}
            onClick={() => onSort("emailAddress")}
          >
            Email
          </TableSortLabel>
        </TableCell>
        <TableCell style={{ width: "20%" }}>
          <TableSortLabel
            active={activeKey === "organisationName"}
            direction={direction}
            onClick={() => onSort("organisationName")}
          >
            Company
          </TableSortLabel>
        </TableCell>
        <TableCell align="center">
          <TableSortLabel
            active={activeKey === "accountStatus"}
            direction={direction}
            onClick={() => onSort("accountStatus")}
          >
            Status
          </TableSortLabel>
        </TableCell>
        <TableCell>Identities</TableCell>
        <TableCell align="right" style={{ width: "15%" }}>
          License
        </TableCell>
        <TableCell align="right" style={{ width: "15%" }}>
          <TableSortLabel
            active={activeKey === "createdDate"}
            direction={activeKey === "createdDate" ? direction : "desc"}
            onClick={() => onSort("createdDate")}
          >
            Join date
          </TableSortLabel>
        </TableCell>
      </TableRow>
    </TableHead>
  )
}

UserListTableHead.propTypes = {
  activeKey: PropTypes.string.isRequired,
  direction: PropTypes.string.isRequired,
  onSort: PropTypes.func.isRequired,
}

export default UserListTableHead
