import React from "react"
import {
  ResponsiveContainer,
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Line,
  Tooltip,
} from "recharts"

import TickFormatter from "../../../../../Utils/tickFormatter"
import NumberAbbreviator from "../../../../../Utils/numberAbbreviator"
import ChartTooltip from "../../../../layout/ChartTooltip"

const strokes = [
  "#358dff",
  "#f9ce32",
  "#48c462",
  "#ec4d6a",
  "#54dac5",
  "#8446de",
  "#f9cd32",
  "#36c1bf",
  "#e39663",
  "#7865ca",
  "#648ce5",
  "#443fc4",
  "#65a0e5",
  "#394276",
]

const ProjectsWordCountChart = props => {
  return (
    <ResponsiveContainer>
      <LineChart
        data={props.data}
        margin={{
          top: 38,
          left: 34,
          right: 12,
          bottom: 10,
        }}
      >
        <CartesianGrid vertical={false} horizontal stroke="#eee" />
        <XAxis
          dataKey="date"
          interval={0}
          padding={{ right: 24 }}
          tickFormatter={dateToFormat =>
            TickFormatter(props.dates, dateToFormat, props.data)
          }
          tick={{ fontSize: 12, fill: "#9e9e9e" }}
          tickMargin={4}
          stroke="#eee"
        />
        <YAxis
          tick={{ fontSize: 12, fill: "#9e9e9e", dx: 4 }}
          orientation="right"
          stroke="#fff"
          tickFormatter={NumberAbbreviator}
        />
        <Tooltip content={<ChartTooltip />} />
        {props.projectNames.map((projectName, i) => (
          <Line
            key={i}
            type="monotone"
            dataKey={projectName}
            stroke={strokes[i % strokes.length]}
            strokeWidth={1.8}
            dot={false}
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  )
}

export default ProjectsWordCountChart
