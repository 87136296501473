import React from "react"
import { makeStyles } from "@material-ui/core/styles"

import { Grid, Typography, Paper } from "@material-ui/core"

const ReportingPage = () => {
  const classes = useStyles()

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={8}>
        <Typography component="h4" variant="h6" className={classes.title}>
          Reporting
        </Typography>
        <Paper className={classes.paper}>
          <Typography paragraph className={classes.placeholderText}>
            In progress for customized reporting
          </Typography>
        </Paper>
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: 300,
    color: "#4a4a4a",
    fontSize: "18px/14px",
    marginBottom: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(5),
  },
  placeholderText: {
    color: "rgba(0, 0, 0, 0.38)",
  },
}))

export default ReportingPage
