import React from "react"
import PropTypes from "prop-types"
import addSeconds from "date-fns/addSeconds"

import { BrowserRouter as Router, Switch, Route } from "react-router-dom"

const SSORouter = ({ onAuthenticated }) => {
  const handleSSO = hash => {
    const params = new URLSearchParams(hash.slice(1)) // leave out the starting "#"
    const expirySeconds = params.get("expires_in")
    const token = params.get("id_token")

    const expires = addSeconds(new Date(), expirySeconds).valueOf()

    onAuthenticated(token, expires)
  }

  return (
    <Router>
      <Switch>
        <Route
          path="/signin/sso"
          render={({ location }) => {
            handleSSO(location.hash)
            return ""
          }}
        />
        <Route
          render={_ => {
            window.location.href = process.env.REACT_APP_AUTH_REDIRECT_URL
            return ""
          }}
        />
      </Switch>
    </Router>
  )
}

SSORouter.propType = {
  onAuthenticated: PropTypes.func.isRequired,
}

export default SSORouter
