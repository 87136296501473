import React from "react"
import { withContext } from "../../../../Utils/context"
import axios from "axios"
import { format } from "date-fns"
import DatePicker from "../../../layout/DatePicker"
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  CircularProgress,
  Typography,
  Grid,
  TablePagination,
} from "@material-ui/core"
import { Link } from "react-router-dom"

const REPORTING_API = process.env.REACT_APP_REPORTING_URL

const nowUTC = date => {
  let now = date ? new Date(date) : new Date()
  now.setTime(now.getTime() + now.getTimezoneOffset() * 60 * 1000)
  return now
}

function TopProjects() {
  const [loading, setLoading] = React.useState(true)
  const [error, setError] = React.useState(false)
  const [dates, setDates] = React.useState({
    startDate: nowUTC().setDate(nowUTC().getDate() - 27),
    endDate: nowUTC(),
  })
  const [totalRows, setTotalRows] = React.useState(0)
  const [pagination, setPagination] = React.useState({
    limit: 10,
    offset: 0,
  })
  const [data, setData] = React.useState([])

  React.useEffect(() => {
    setLoading(true)
    setError(false)
    axios({
      method: "POST",
      url: `${REPORTING_API}/t130/project/top/`,
      data: {
        limit: pagination.limit,
        offset: pagination.offset,
        startDate: format(dates.startDate, "yyyy-MM-dd"),
        endDate: format(dates.endDate, "yyyy-MM-dd"),
      },
    })
      .then(response => {
        setLoading(false)
        setData(response.data.result.projects)
        setTotalRows(response.data.result.totalTableCount)
      })
      .catch(error => {
        setLoading(false)
        setError(true)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination, dates])

  const handleDatesChange = dates => {
    setDates(dates)
  }

  const handleChangePage = (_event, newPage) => {
    setPagination({
      limit: pagination.limit,
      offset: newPage * pagination.limit,
    })
  }

  const handleChangeRowsPerPage = ({ target: { value } }) => {
    if (!error && !loading) {
      setPagination({
        limit: value,
        offset: 0,
      })
    }
  }

  return (
    <React.Fragment>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Project name</TableCell>
            <TableCell align="right">Project owner</TableCell>
            <TableCell align="right">Word count</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <TableRow style={{ height: 33 * 10 }}>
              <TableCell padding="checkbox" align="center" colSpan={3}>
                <CircularProgress />
              </TableCell>
            </TableRow>
          ) : (
            <React.Fragment>
              {error ? (
                <TableRow style={{ height: 33 * 10 }}>
                  <TableCell padding="checkbox" align="center" colSpan={3}>
                    <Typography variant="body1">
                      Sorry an error occurred fetching
                      <br />
                      data for the project report
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : (
                <React.Fragment>
                  {data.length === 0 ? (
                    <TableRow style={{ height: 33 * 10 }}>
                      <TableCell padding="checkbox" align="center" colSpan={3}>
                        <Typography variant="body1">
                          No project data found for date range
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ) : (
                    <React.Fragment>
                      {data.map((project, i) => (
                        <TableRow key={i} className="slowfadein">
                          <TableCell style={{ fontSize: 14 }}>
                            {project.projectName}
                          </TableCell>
                          <TableCell align="right">
                            <Link to={"/user/" + project.userId}>
                              {project.displayName}
                            </Link>
                          </TableCell>
                          <TableCell align="right">
                            {project.wordCount.toLocaleString()}
                          </TableCell>
                        </TableRow>
                      ))}
                      {data.length < 10 && (
                        <TableRow style={{ height: 33 * (10 - data.length) }}>
                          <TableCell colSpan={3} />
                        </TableRow>
                      )}
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </TableBody>
      </Table>
      <Grid container justify="space-between" alignItems="center">
        <Grid item>
          <DatePicker dates={dates} onDatesChange={handleDatesChange} />
        </Grid>
        <Grid item>
          <TablePagination
            rowsPerPageOptions={[10, 20, 30]}
            component="div"
            count={totalRows}
            rowsPerPage={pagination.limit}
            page={
              pagination.offset !== 0 ? pagination.offset / pagination.limit : 0
            }
            backIconButtonProps={{
              "aria-label": "Previous Page",
            }}
            nextIconButtonProps={{
              "aria-label": "Next Page",
            }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default withContext(TopProjects)
