import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Container } from "@material-ui/core"

import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom"

import DashboardPage from "./dashboard/DashboardPage"
import ReportingPage from "./reporting/ReportingPage"
import UserListPage from "./user/list/UserListPage"
import UserDetailPage from "./user/detail/UserDetailPage"
import OrganisationListPage from "./organisation/list/OrganisationListPage"
import OrganisationDetailPage from "./organisation/detail/OrganisationDetailPage"
import PartnerListPage from "./partner/list/PartnerListPage"
import PartnerDetailPage from "./partner/detail/PartnerDetailPage"
import NotFound from "./NotFound"

import Topbar from "../layout/navigation/Topbar"
import Sidenav from "../layout/navigation/Sidenav"

const Router = () => {
  const classes = useStyles()

  return (
    <BrowserRouter>
      <div className={classes.root} id="content">
        <Topbar />
        <Sidenav />
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Container maxWidth="xl" className="mediumfadein">
            <Switch>
              <Route path="/dashboard" exact component={DashboardPage} />
              <Route path="/users" exact component={UserListPage} />
              <Route path="/companies" exact component={OrganisationListPage} />
              <Route path="/partners" component={PartnerListPage} />
              <Route path="/reporting" exact component={ReportingPage} />

              <Route path="/user/:userId" component={UserDetailPage} />
              <Route
                path="/company/:organisationId"
                component={OrganisationDetailPage}
              />
              <Route path="/partner/:partnerId" component={PartnerDetailPage} />

              <Redirect from="/signin/sso" to="/dashboard" />
              <Redirect from="/" to="/dashboard" />
              <Route component={NotFound} />
            </Switch>
          </Container>
        </main>
      </div>
    </BrowserRouter>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
  },
  content: {
    flexGrow: 1,
    // TODO: investigate why this makes pagination buttons on safari need two taps to register click [CSP-338]
    // overflow: "auto",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
}))

export default Router
