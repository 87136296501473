import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import {
  Button,
  Popper,
  Paper,
  Typography,
  Divider,
  MenuItem,
} from "@material-ui/core"
import { ArrowDropDown as ArrowDropDownIcon } from "@material-ui/icons"
import {
  isEqual,
  isToday,
  isYesterday,
  format,
  differenceInDays,
} from "date-fns"
import DateRangePicker from "../layout/CustomDatepicker"

const useStyles = makeStyles(theme => ({
  selectButton: {
    color: "#757575",
    marginLeft: theme.spacing(2),
    fontSize: 13,
    fontWeight: 400,
    textTransform: "none",
  },
  menuHeader: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  headerDate: {
    color: "rgba(0, 0, 0, 0.38)",
    fontSize: 11,
  },
  headerText: {
    marginBottom: theme.spacing(0.5),
  },
  selectionDivider: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
  menuItem: {
    padding: 3,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    minHeight: 0,
    fontSize: 15,
    fontWeight: 500,
  },
  selected: {
    color: "#346df1",
    backgroundColor: "#fff !important",
  },
  bottomDiv: {
    height: theme.spacing(1),
  },
  keyboardInput: {
    width: 116,
  },
  selectedKeyboard: {
    background: "#c8dcfc",
  },
  topDayWrapper: {
    margin: "2px 0px",
  },
  dayWrapper: {
    padding: "0 6px",
  },
  highlight: {
    background: "#c8dcfc",
  },
  firstHighlight: {
    extend: "highlight",
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
    paddingLeft: 0,
    marginLeft: 6,
  },
  endHighlight: {
    extend: "highlight",
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%",
    paddingRight: 0,
    marginRight: 6,
  },
  day: {
    width: 28,
    height: 28,
    fontSize: 12,
    lineHeight: 0,
    color: "inherit",
    "&.nonCurrentMonthDay": {
      color: theme.palette.text.disabled,
    },
  },
  nonCurrentMonthDay: {
    color: theme.palette.text.disabled,
  },

  highlightNonCurrentMonthDay: {
    color: "#676767",
  },
  firstSelection: {
    background: "#3466f1",
    color: theme.palette.common.white,
  },
  customButton: {
    marginBottom: theme.spacing(0.5),
    color: "#757575",
    fontSize: 15,
    fontWeight: 400,
    textTransform: "none",
  },
  applyButton: {
    marginRight: theme.spacing(1),
    color: "#2565e5",
  },
}))

const dateRanges = [
  "Today",
  "Yesterday",
  "Last 7 days",
  "Last 28 days",
  "Last 90 days",
]

const nowUTC = date => {
  let now = date ? new Date(date) : new Date()
  now.setTime(now.getTime() + now.getTimezoneOffset() * 60 * 1000)
  return now
}

const dateRangePicker = ({ endDate, startDate }) => {
  const refDate = nowUTC()
  const isTodayDate =
    isEqual(endDate, startDate) &&
    differenceInDays(refDate.setDate(refDate.getDate()), endDate) === 0
  const isYesterdayDate =
    isEqual(endDate, startDate) &&
    differenceInDays(refDate.setDate(refDate.getDate()), endDate) === 1
  const dayDiff = isTodayDate
    ? 0
    : isYesterdayDate
    ? 1
    : differenceInDays(endDate, refDate) === 0 &&
      differenceInDays(refDate, startDate) > 1
    ? differenceInDays(endDate, startDate)
    : -1
  return (
    {
      0: "Today",
      1: "Yesterday",
      6: "Last 7 days",
      27: "Last 28 days",
      89: "Last 90 days",
    }[dayDiff] || "Custom"
  )
}

function CustomDatePicker({ style, placement, dates, disabled, ...props }) {
  const defaultRange =
    dates.startDate && dates.endDate ? dateRangePicker(dates) : "Last 28 days"
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const popoverOpen = Boolean(anchorEl)
  const id = popoverOpen ? "custom-date-popover" : undefined
  const [dateRange, setDateRange] = React.useState(defaultRange)
  const [customOpen, setCustomOpen] = React.useState(false)
  const minDate = new Date("01/01/2018")

  const initialDateRange = { startDate: minDate, endDate: nowUTC() }
  const [dateRangeCustom, setDateRangeCustom] = React.useState(initialDateRange)

  React.useEffect(() => {
    setDateRange(defaultRange)
  }, [defaultRange, dateRange])

  React.useEffect(() => {
    if (anchorEl) {
      document.addEventListener("mousedown", handleClick)
    } else {
      document.removeEventListener("mousedown", handleClick)
    }
    // Specify how to clean up after this effect:
    return function cleanup() {
      document.removeEventListener("mousedown", handleClick)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anchorEl])

  const handleClick = event => {
    if (!document.getElementById(id).contains(event.target)) {
      setAnchorEl(null)
      setCustomOpen(false)
    }
  }

  const handlePopoverClick = event => {
    setAnchorEl(event.currentTarget)
    setDateRangeCustom({
      startDate: dates.startDate,
      endDate: dates.endDate,
    })
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
    setCustomOpen(false)
  }

  function handleChange(value) {
    if (value !== "Custom") {
      let start = nowUTC()
      let end = nowUTC()
      if (value === "Yesterday") {
        start.setDate(start.getDate() - 1)
        end.setDate(end.getDate() - 1)
      } else if (value === "Last 7 days") {
        start.setDate(start.getDate() - 6)
        end.setDate(end.getDate())
      } else if (value === "Last 28 days") {
        start.setDate(start.getDate() - 27)
        end.setDate(end.getDate())
      } else if (value === "Last 90 days") {
        start.setDate(start.getDate() - 89)
        end.setDate(end.getDate())
      }
      props.onDatesChange({ startDate: start, endDate: end })
    }
    setDateRange(value)
    setAnchorEl(null)
  }

  const handleCustomClick = value => {
    setCustomOpen(true)
  }

  const handleApply = ({ startDate, endDate }) => {
    setDateRange("Custom")
    props.onDatesChange({ startDate: startDate, endDate: endDate })
    setAnchorEl(null)
    setCustomOpen(false)
  }

  return (
    <React.Fragment>
      <Button
        size="small"
        className={classes.selectButton}
        onClick={handlePopoverClick}
        aria-describedby={id}
        disabled={disabled}
      >
        {dateRange === "Custom" ? (
          <span style={style ? style : null}>
            {format(dates.startDate, "MMM dd, yyyy")}&nbsp;-&nbsp;
            {format(dates.endDate, "MMM dd, yyyy")}
          </span>
        ) : (
          <span style={style ? style : null}>{dateRange}</span>
        )}
        <ArrowDropDownIcon />
      </Button>
      <Popper
        id={id}
        open={popoverOpen}
        anchorEl={anchorEl}
        placement={placement ? placement : "top-start"}
        disablePortal={false}
        modifiers={{
          flip: {
            enabled: false,
          },
          preventOverflow: {
            enabled: true,
            boundariesElement: "scrollParent",
          },
        }}
      >
        <Paper>
          {!customOpen ? (
            <React.Fragment>
              <div className={classes.menuHeader}>
                <Typography className={classes.headerDate} variant="subtitle2">
                  CUSTOM (UTC)
                </Typography>
                <Typography className={classes.headerText} variant="subtitle2">
                  {format(dates.startDate, "MMM dd, yyyy")}&nbsp;-&nbsp;
                  {format(dates.endDate, "MMM dd, yyyy")}
                </Typography>
              </div>
              <Divider className={classes.selectionDivider} />
              {dateRanges.map(value => (
                <MenuItem
                  key={value}
                  value={value}
                  className={classes.menuItem}
                  classes={{ selected: classes.selected }}
                  selected={dateRange === value}
                  onClick={() => handleChange(value)}
                >
                  {value}
                </MenuItem>
              ))}
              <Divider className={classes.selectionDivider} />
              <MenuItem
                value="Custom"
                className={classes.menuItem}
                classes={{ selected: classes.selected }}
                selected={dateRange === "Custom"}
                onClick={handleCustomClick}
              >
                Custom...
              </MenuItem>
              <div className={classes.bottomDiv} />
            </React.Fragment>
          ) : (
            <React.Fragment>
              <DateRangePicker
                initialDateRange={dateRangeCustom}
                onAccept={handleApply}
                onCancel={handlePopoverClose}
                maxDate={initialDateRange.endDate}
                minDate={minDate}
              />
            </React.Fragment>
          )}
        </Paper>
      </Popper>
    </React.Fragment>
  )
}

export default CustomDatePicker
