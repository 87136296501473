import React, { useState, useEffect } from "react"
import { CssBaseline, Snackbar, IconButton } from "@material-ui/core"
import axios from "axios"

import SSORouter from "./SSORouter"
import PagesRouter from "./pages/Router"

import { Provider } from "../Utils/context"
import DateFnsUtils from "@date-io/date-fns"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"

import { Close as CloseIcon } from "@material-ui/icons"
import { MuiThemeProvider } from "@material-ui/core/styles"

import theme from "../Utils/theme"
import { timer, copyToClipboard } from "../Utils/helpers"
import ls from "../Utils/localStorage"

const setupAuthorisationToken = token =>
  new Promise(res => {
    const authHeader = axios.defaults.headers.common.authorization

    if (!authHeader)
      axios.defaults.headers.common.authorization = `Bearer ${token}`

    res({ tokenSet: true })
  })

const App = props => {
  const csp_auth = ls.get()
  const token = csp_auth?.token
  const tokenExpiry = csp_auth?.expiry

  const [loading, setLoading] = useState(true)
  const [authenticated, setAuthenticated] = useState(!!token)
  const [sidenavOpen, setSidenavOpen] = useState(false)
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackMessage, setSnackMessage] = useState("An error has occurred!")

  // Listen for change in localstorage
  // and log user out if login token was removed.
  useEffect(() => {
    window.addEventListener("storage", handleStorageChange)
    return () => window.removeEventListener("storage", handleStorageChange)

    function handleStorageChange({ key, newValue }) {
      if (key === ls.defaultKey && !newValue) {
        // newValue === false <==> token removed from localstorage
        logUserOut()
      }
    }
  }, [])

  useEffect(() => {
    startAutoLogoutTimer()

    function startAutoLogoutTimer() {
      if (!token) {
        setAuthenticated(false)
        setLoading(false)
        return
      }

      setupAuthorisationToken(token).then(_ => {
        setAuthenticated(true)
        setLoading(false)
      })

      // log user out at expiry
      const expiry = tokenExpiry || 0
      const millisUntilExpiry = expiry - Date.now()
      timer.start(millisUntilExpiry, logUserOut)
    }
  }, [token, tokenExpiry])

  props.hideLoader()
  const handleAuth = (token, expiry) => {
    if (!token) return
    ls.set({ token, expiry })
    setAuthenticated(true)
  }

  const logUserOut = () => {
    setAuthenticated(false)
    axios.defaults.headers.common.authorization = null

    // Check if localstorage is already cleared(by different tab) to avoid
    // clearing twice and triggering another localstorage change event
    const authContent = ls.get()
    !!authContent && ls.remove()
  }

  function createError(error) {
    setSnackMessage("An error has occurred!")
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx

      if (error.response.status === 401) {
        // Not authenticated

        if (error.response.data.message === "The incoming token has expired") {
          setAuthenticated(false)
        } else {
          setSnackMessage("Token is not authenticated for the request")
        }
      } else if (error.response.status === 400) {
        // Bad request

        setSnackMessage("Error occurred from invalid request")
      }
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js

      console.error("Request Error", error.message)
      setSnackMessage("No response was received for the request")
    } else if (error.customMessage) {
      setSnackMessage(error.customMessage)
    } else {
      // Something happened in setting up the request that triggered an Error

      console.error("Set up request error", error.message)
      setSnackMessage("Error occurred when setting up the request")
    }
    setSnackbarOpen(true)
  }

  const createSuccess = message => {
    setSnackMessage(message)
    setSnackbarOpen(true)
  }

  const copyToClipboardAndNotify = async textToCopy => {
    const success = await copyToClipboard(textToCopy)
    success
      ? createSuccess("Copied to clipboard")
      : alert(`Could not copy to clipboard. Please copy this: \n${textToCopy}`)
  }

  const handleSnackbarClose = () => setSnackbarOpen(false)

  if (loading) {
    return <></>
  }

  // If not authenticated user will be redirected to single page sign on.
  if (!authenticated) {
    return <SSORouter onAuthenticated={handleAuth} />
  }

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <Provider
        value={{
          createSuccess,
          createError,
          logUserOut,
          setSidenavOpen,
          sidenavOpen,
          copyToClipboard: copyToClipboardAndNotify,
        }}
      >
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <PagesRouter />
        </MuiPickersUtilsProvider>
      </Provider>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        autoHideDuration={4000}
        ContentProps={{
          "aria-describedby": "message-id",
        }}
        message={<span id="message-id">{snackMessage}</span>}
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            onClick={handleSnackbarClose}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </MuiThemeProvider>
  )
}

export default App
