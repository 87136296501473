import React, { useState, useEffect } from "react"
import { lighten, makeStyles } from "@material-ui/core/styles"
import clsx from "clsx"

import { withContext } from "../../../../../Utils/context"
import axios from "axios"

import {
  Dialog,
  DialogTitle as MuiDialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  Toolbar,
  Paper,
  TablePagination,
  CircularProgress,
  Grid,
  Button,
} from "@material-ui/core"
import { Close as CloseIcon } from "@material-ui/icons"

import Searchbar from "../../../../layout/Searchbar"

const ORG_API = process.env.REACT_APP_ORG_URL

const DialogTitle = props => {
  const classes = dialogTitleUseStyles()

  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{props.children}</Typography>
      <IconButton
        aria-label="Close"
        className={classes.closeButton}
        onClick={props.onClose}
      >
        <CloseIcon />
      </IconButton>
    </MuiDialogTitle>
  )
}

const dialogTitleUseStyles = makeStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}))

const OrganisationSelect = props => {
  const classes = useStyles()

  const [searchBy, setSearchBy] = useState("")
  const [sortBy, setSortBy] = useState({
    key: "displayName",
    value: "asc",
  })
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [data, setData] = useState([])
  const [totalRows, setTotalRows] = useState(0)
  const [pagination, setPagination] = useState({
    limit: 7,
    offset: 0,
  })
  const [selectedId, setSelectedId] = useState("")

  useEffect(() => {
    setLoading(true)
    setError(false)

    let data = {
      filterBy: [
        {
          key: "partnerId",
          value: null,
        },
      ],
      sortBy,
      limit: pagination.limit,
      offset: pagination.offset,
    }

    if (searchBy !== "") {
      data.searchBy = searchBy
    }

    axios({
      method: "post",
      url: `${ORG_API}/`,
      data,
    })
      .then(response => {
        setLoading(false)
        setData(response.data.records)
        setTotalRows(response.data.totalTableCount)
      })
      .catch(error => {
        setLoading(false)
        setError(true)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open, sortBy, pagination])

  useEffect(() => {
    setPagination({
      limit: 7,
      offset: 0,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchBy])

  const toggleOpen = () => props.onToggleOpen()

  const handleSearchChange = value => setSearchBy(value)

  const handleSort = value => {
    if (sortBy.key === value) {
      setSortBy({
        key: value,
        value: sortBy.value === "asc" ? "desc" : "asc",
      })
    } else {
      setSortBy({
        key: value,
        value: "asc",
      })
    }
  }

  function handleSelect(event, id) {
    if (selectedId === id) {
      setSelectedId("")
    } else {
      setSelectedId(id)
    }
  }

  const handleChangePage = (event, newPage) =>
    setPagination({
      limit: 7,
      offset: newPage * pagination.limit,
    })

  const handleSave = () => {
    props.onSave(selectedId)
    props.onToggleOpen()
  }

  return (
    <>
      <Dialog
        open={props.open}
        onClose={toggleOpen}
        aria-labelledby="company-select-title"
        classes={{ paper: classes.dialogPaper }}
      >
        <DialogTitle
          id="company-select-title"
          onClose={toggleOpen}
          className={classes.title}
        >
          Select a company
        </DialogTitle>
        <DialogContent dividers className={classes.content}>
          <Toolbar>
            <Paper style={{ width: "100%" }}>
              <Searchbar
                placeholder="Search by name"
                disabled={false}
                onChange={handleSearchChange}
              />
            </Paper>
          </Toolbar>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={sortBy.key === "displayName"}
                    direction={sortBy.value}
                    onClick={() => handleSort("displayName")}
                  >
                    Company name
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading || error ? (
                <TableRow style={{ height: 37 * 7 }}>
                  <TableCell padding="checkbox" align="center">
                    {loading ? (
                      <CircularProgress />
                    ) : (
                      <Typography variant="body1">
                        Sorry an error occurred fetching companies
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
              ) : (
                <>
                  {data.length === 0 ? (
                    <TableRow style={{ height: 37 * 7 }}>
                      <TableCell align="center">
                        <Typography variant="body1">
                          No companies found
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ) : (
                    <>
                      {data.map(organisation => (
                        <TableRow
                          key={organisation.id}
                          hover
                          tabIndex={-1}
                          className={clsx("slowfadein", {
                            [classes.highlight]: selectedId === organisation.id,
                          })}
                          style={{ cursor: "pointer" }}
                          onClick={event =>
                            handleSelect(event, organisation.id)
                          }
                        >
                          <TableCell>{organisation.displayName}</TableCell>
                        </TableRow>
                      ))}
                      {data.length < 7 && (
                        <TableRow style={{ height: 33 * (7 - data.length) }}>
                          <TableCell />
                        </TableRow>
                      )}
                    </>
                  )}
                </>
              )}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={totalRows}
            rowsPerPage={7}
            page={
              pagination.offset !== 0 ? pagination.offset / pagination.limit : 0
            }
            backIconButtonProps={{
              "aria-label": "Previous Page",
            }}
            nextIconButtonProps={{
              "aria-label": "Next Page",
            }}
            onChangePage={handleChangePage}
          />
        </DialogContent>
        <DialogActions>
          <Grid container>
            <Grid item xs={12} align="right">
              <Button
                onClick={toggleOpen}
                size="small"
                className={classes.cancelButton}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                size="small"
                variant="contained"
                disabled={selectedId === ""}
                onClick={handleSave}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  )
}

const useStyles = makeStyles(theme => ({
  dialogPaper: {
    width: 750,
  },
  title: {
    fontWeight: 400,
  },
  content: {
    padding: 0,
  },
  highlight: {
    backgroundColor: lighten(theme.palette.primary.light, 0.85),
  },
  dangerButton: {
    backgroundColor: theme.palette.danger.main,
    color: "white",
  },
  cancelButton: {
    marginRight: theme.spacing(1),
  },
}))

export default withContext(OrganisationSelect)
