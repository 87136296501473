import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { withContext } from "../../../../Utils/context"
import axios from "axios"
import { format } from "date-fns"
import DatePicker from "../../../layout/DatePicker"
import { Divider, Typography, Grid } from "@material-ui/core"
import {
  ResponsiveContainer,
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Line,
  Tooltip,
} from "recharts"

import TickFormatter from "../../../../Utils/tickFormatter"
import NumberAbbreviator from "../../../../Utils/numberAbbreviator"
import ChartTooltip from "../../../layout/ChartTooltip"

const REPORTING_API = process.env.REACT_APP_REPORTING_URL

const useStyles = makeStyles(theme => ({
  chartDivider: {
    marginBottom: 4,
  },
  keyContainer: {
    marginTop: 4,
    marginRight: theme.spacing(3),
  },
  keyBox: {
    height: 12,
    width: 12,
    backgroundColor: "#C51C1C",
    display: "inline-block",
    marginRight: theme.spacing(1),
  },
  wordCountLabel: {
    color: "#757575",
    fontSize: 13,
    fontWeight: 400,
    display: "inline-block",
    marginRight: theme.spacing(3),
  },
  userCountLabel: {
    color: "#757575",
    fontSize: 13,
    fontWeight: 400,
    display: "inline-block",
  },
}))

const nowUTC = date => {
  let now = date ? new Date(date) : new Date()
  now.setTime(now.getTime() + now.getTimezoneOffset() * 60 * 1000)
  return now
}

function ActivitySummaryChart() {
  const classes = useStyles()
  const [dates, setDates] = React.useState({
    startDate: nowUTC().setDate(nowUTC().getDate() - 27),
    endDate: nowUTC(),
  })
  const [data, setData] = React.useState([])

  React.useEffect(() => {
    const data = {
      startDate: format(dates.startDate, "yyyy-MM-dd"),
      endDate: format(dates.endDate, "yyyy-MM-dd"),
    }
    const config = {}
    const rCount = axios.post(
      `${REPORTING_API}/c110/wordcount/total/`,
      data,
      config,
    )
    const rUser = axios.post(`${REPORTING_API}/c140/user/total/`, data, config)
    axios.all([rCount, rUser]).then(([wordCountRes, userRes]) => {
      let newData = []
      wordCountRes.data.results.forEach(item => {
        const user = userRes.data.results.find(x => x.date === item.date)
        if (user) {
          newData.push({
            date: item.date,
            wordCount: item.wordCount,
            userCount: user.userCount,
          })
        }
      })

      // Check if this refactor is correct before replacing
      //
      // const newData = wordCountRes.data.results.map(({ date, wordCount }) =>
      //   userRes.data.results
      //     .filter(u => u.date === date)
      //     .map(({ userCount }) => ({
      //       date,
      //       wordCount,
      //       userCount
      //     }))
      // ).flatten(2)

      setData(newData)
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dates])

  const handleDatesChange = dates => {
    // NOTE: This is to prevent the date range and data format error on the chart
    setData([])
    setDates(dates)
  }

  return (
    <React.Fragment>
      <ResponsiveContainer>
        <LineChart
          data={data}
          margin={{
            top: 38,
            left: 18,
            right: 18,
            bottom: 10,
          }}
        >
          <CartesianGrid vertical={false} horizontal stroke="#eee" />
          <XAxis
            dataKey="date"
            interval={0}
            padding={{ left: 24, right: 24 }}
            tickFormatter={dateToFormat =>
              TickFormatter(dates, dateToFormat, data)
            }
            tick={{ fontSize: 12, fill: "#9e9e9e" }}
            tickMargin={4}
            stroke="#eee"
          />
          <YAxis
            tick={{ fontSize: 12, fill: "#9e9e9e", dx: 4 }}
            orientation="right"
            yAxisId="right"
            stroke="#fff"
            label={{
              value: "Word count",
              fill: "#9e9e9e",
              angle: 90,
              position: "right",
            }}
            tickFormatter={NumberAbbreviator}
          />
          <YAxis
            tick={{ fontSize: 12, fill: "#9e9e9e" }}
            yAxisId="left"
            stroke="#fff"
            domain={[
              0,
              data.length !== 0 ? data[data.length - 1].userCount + 300 : 4,
            ]}
            allowDecimals={false}
            label={{
              value: "User count",
              fill: "#9e9e9e",
              angle: -90,
              position: "left",
            }}
          />
          <Tooltip content={<ChartTooltip />} />
          <Line
            type="monotone"
            yAxisId="right"
            dataKey="wordCount"
            stroke="#346df1"
            strokeWidth={1.8}
            dot={false}
          />
          <Line
            type="monotone"
            yAxisId="left"
            dataKey="userCount"
            stroke="#C51C1C"
            strokeWidth={1.8}
            dot={false}
          />
        </LineChart>
      </ResponsiveContainer>
      <Divider className={classes.chartDivider} />
      <Grid container justify="space-between">
        <Grid item>
          <DatePicker dates={dates} onDatesChange={handleDatesChange} />
        </Grid>
        <Grid item>
          <div className={classes.keyContainer}>
            <div
              className={classes.keyBox}
              style={{ backgroundColor: "#346df1" }}
            />
            <Typography className={classes.wordCountLabel}>
              Word Count
            </Typography>
            <div
              className={classes.keyBox}
              style={{ backgroundColor: "#C51C1C" }}
            />
            <Typography className={classes.userCountLabel}>
              User Count
            </Typography>
          </div>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default withContext(ActivitySummaryChart)
