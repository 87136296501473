import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import classNames from "classnames"
import { withContext } from "../../../Utils/context"
import {
  Drawer,
  IconButton,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@material-ui/core"
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  Person as PersonIcon,
  BubbleChart as BubbleChartIcon,
  CardMembership as CardMembershipIcon,
  ThumbUpAlt as ThumbUpAltIcon,
  BarChart as BarChartIcon,
} from "@material-ui/icons"
import { NavLink } from "react-router-dom"

const drawerWidth = 180

const useStyles = makeStyles(theme => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  links: { height: "100%", marginBottom: 10 },
  list: { padding: 0 },
  tab: {
    boxSizing: "border-box",
  },
  activeTab: {
    borderLeft: `4px solid ${theme.palette.primary.main}`,
    "& div": {
      paddingLeft: 6,
    },
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 3,
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  icon: {
    fill: "#000",
  },
  itemText: {
    color: "#000",
  },
  active: {
    borderLeft: `4px solid ${theme.palette.primary.main}`,
    paddingLeft: 12, // account for the added border
  },
  expandToggle: {
    position: "absolute",
    bottom: 0,
  },
}))

function Sidenav(props) {
  const classes = useStyles()
  const handleDrawerClose = () => props.setSidenavOpen(false)
  const handleDrawerToggle = () => props.setSidenavOpen(!props.sidenavOpen)

  const LinkItem = ({ path, label, icon }) => (
    <NavLink
      to={path}
      activeClassName={classes.activeTab}
      className={classes.tab}
    >
      <ListItem button>
        <ListItemIcon className="mediumfadein">
          <Tooltip title={label} placement="right">
            {icon}
          </Tooltip>
        </ListItemIcon>
        <ListItemText primary={label} className={classes.itemText} />
      </ListItem>
    </NavLink>
  )

  return (
    <Drawer
      variant="permanent"
      className={classNames({
        [classes.drawer]: true,
        [classes.drawerOpen]: props.sidenavOpen,
        [classes.drawerClose]: !props.sidenavOpen,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: props.sidenavOpen,
          [classes.drawerClose]: !props.sidenavOpen,
        }),
      }}
      open={props.sidenavOpen}
    >
      <div className={classes.toolbar}>
        <IconButton onClick={handleDrawerClose}>
          <ChevronLeftIcon />
        </IconButton>
      </div>

      <LinkItem
        path="/dashboard"
        label="Dashboard"
        icon={<BubbleChartIcon className={classes.icon} />}
      />

      <LinkItem
        path="/users"
        label="Users"
        icon={<PersonIcon className={classes.icon} />}
      />
      <LinkItem
        path="/companies"
        label="Companies"
        icon={<CardMembershipIcon className={classes.icon} />}
      />
      <LinkItem
        path="/partners"
        label="Partners"
        icon={<ThumbUpAltIcon className={classes.icon} />}
      />

      <Divider className="mediumfadein" />
      <LinkItem
        path="/reporting"
        label="Reporting"
        icon={<BarChartIcon className={classes.icon} />}
      />
      <List className={classes.expandToggle}>
        <ListItem button key="NavToggle" onClick={handleDrawerToggle}>
          <ListItemIcon className="mediumfadein">
            {props.sidenavOpen ? (
              <Tooltip title="Close menu" placement="right">
                <ChevronLeftIcon className={classes.icon} />
              </Tooltip>
            ) : (
              <Tooltip title="Open menu" placement="right">
                <ChevronRightIcon className={classes.icon} />
              </Tooltip>
            )}
          </ListItemIcon>
        </ListItem>
      </List>
    </Drawer>
  )
}

export default withContext(Sidenav)
