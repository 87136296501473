import React from "react"
import { Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

const getStyles = makeStyles({
  value: {
    fontSize: 12,
  },
})

export default () => {
  const styl = getStyles()
  return (
    <Typography variant="subtitle2" className={styl.value}>
      No data available.
    </Typography>
  )
}
