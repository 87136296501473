import React, { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"

import { withContext } from "../../../../Utils/context"
import { maxCharLength } from "../../../../Utils/helpers"
import axios from "axios"

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Fab,
  FormControl,
  InputLabel,
  Select,
  Input,
  MenuItem,
  Button,
  CircularProgress,
  Typography,
} from "@material-ui/core"
import { Add as AddIcon } from "@material-ui/icons"
import { useHistory } from "react-router-dom"

const PARTNER_API = process.env.REACT_APP_PARTNER_URL

const checkName = searchTerm =>
  axios
    .post(PARTNER_API, {
      limit: 1,
      offset: 0,
      sortBy: { key: "displayName", value: "asc" },
      searchBy: searchTerm,
    })
    .catch(console.error)
    .then(res => res?.data?.records)
    .then(companies =>
      companies?.some(
        c => c.displayName.toLowerCase() === searchTerm.toLowerCase(),
      ),
    )

const useDebounce = (searchTerm, delay = 1000) => {
  const [debouncedVal, setDebouncedVal] = React.useState(searchTerm)

  React.useEffect(() => {
    const timerRef = setTimeout(() => setDebouncedVal(searchTerm), delay)

    return () => clearTimeout(timerRef)
  }, [searchTerm, delay])

  return debouncedVal
}

const CreationModal = () => {
  const classes = useStyles()
  const history = useHistory()

  const [open, setOpen] = useState(false)
  const [displayName, setDisplayName] = useState("")
  const [accountStatus, setAccountStatus] = useState("ACTIVE")
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [checkingDuplicate, setCheckingDuplicate] = React.useState(false)
  const [displayNameMinLength, setDisplayNameMinLength] = React.useState(false)
  const [displayNameMaxLength, setDisplayNameMaxLength] = React.useState(false)
  const [displayNameMatch, setDisplayNameMatch] = React.useState(false)

  const debouncedDisplayName = useDebounce(displayName, 500)

  const toggleDialog = () => setOpen(!open)

  const handleNameChange = ev => {
    const val = ev.target.value
    setCheckingDuplicate(true)
    setDisplayName(val)
  }

  const handleStatusChange = ({ target: { value } }) => setAccountStatus(value)

  React.useEffect(() => {
    const updateDuplicateStatus = () => {
      if (!debouncedDisplayName) {
        setCheckingDuplicate(false)
        return
      }

      // reset all error msg state
      setDisplayNameMinLength(false)
      setDisplayNameMaxLength(false)
      setDisplayNameMatch(false)

      if (debouncedDisplayName.length > maxCharLength) {
        setDisplayNameMaxLength(true)
        return
      }
      if (debouncedDisplayName.length < 2) {
        setDisplayNameMinLength(true)
        return
      }

      checkName(debouncedDisplayName)
        .then(setDisplayNameMatch)
        .then(_ => setCheckingDuplicate(false))
    }

    updateDuplicateStatus()
  }, [debouncedDisplayName])

  const handleCreate = () => {
    setLoading(true)
    setError(false)
    axios
      .post(`${PARTNER_API}/create`, {
        displayName: debouncedDisplayName.trim(),
        accountStatus,
      })
      .then(({ data }) => history.push(`/partner/${data.partnerId}`))
      .catch(_error => {
        setLoading(false)
        setError(true)
      })
  }
  const createDisabled =
    displayNameMatch ||
    displayNameMinLength ||
    displayNameMaxLength ||
    loading ||
    checkingDuplicate

  const displayNameErrorMsg = displayNameMatch
    ? "A partner with this name already exists."
    : displayNameMinLength
    ? "Partner name cannot have less than 2 characters."
    : displayNameMaxLength
    ? `Partner name cannot have more than ${maxCharLength} characters.`
    : false

  return (
    <>
      <Fab
        variant="extended"
        color="primary"
        size="small"
        className={classes.icon}
        onClick={toggleDialog}
      >
        <AddIcon fontSize="small" />
        Create Partner
      </Fab>
      <Dialog
        open={open}
        onClose={toggleDialog}
        aria-labelledby="form-dialog-title"
        aria-describedby="form-dialog-description"
        maxWidth="xs"
      >
        <DialogTitle id="form-dialog-title">Create Partner</DialogTitle>
        {loading ? (
          <div className={classes.progressWrap}>
            <CircularProgress className={classes.progress} />
          </div>
        ) : (
          <>
            {error ? (
              <Typography variant="body1">
                Sorry an error occurred creating the partner
              </Typography>
            ) : (
              <DialogContent>
                <DialogContentText
                  id="form-dialog-description"
                  style={{ wordBreak: "unset" }}
                >
                  To create a partner, please enter a name and select an account
                  status.
                </DialogContentText>
                <TextField
                  id="displayName"
                  label="Partner Name"
                  error={
                    !!displayNameMatch ||
                    !!displayNameMinLength ||
                    !!displayNameMaxLength
                  }
                  helperText={displayNameErrorMsg}
                  value={displayName}
                  name="displayName"
                  onChange={handleNameChange}
                  margin="normal"
                  fullWidth
                  required
                  variant="outlined"
                />
                <FormControl fullWidth required margin="normal">
                  <InputLabel htmlFor="status-helper">
                    Account status
                  </InputLabel>
                  <Select
                    value={accountStatus}
                    name="accountStatus"
                    onChange={handleStatusChange}
                    input={<Input name="status" id="status-helper" />}
                  >
                    <MenuItem value="ACTIVE">Active</MenuItem>
                    <MenuItem value="DISABLED">Disabled</MenuItem>
                    <MenuItem value="SUSPENDED">Suspended</MenuItem>
                  </Select>
                </FormControl>
              </DialogContent>
            )}
          </>
        )}
        <DialogActions>
          <Button onClick={toggleDialog} size="small" disabled={loading}>
            Cancel
          </Button>
          <Button
            onClick={handleCreate}
            variant="contained"
            color="primary"
            size="small"
            disabled={createDisabled}
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const useStyles = makeStyles(theme => ({
  icon: {
    marginRight: theme.spacing(1),
  },
  progressWrap: {
    padding: theme.spacing(6),
  },
  progress: {
    position: "absolute",
    left: "50%",
  },
}))

export default withContext(CreationModal)
