import React, { useState, useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles"
import classNames from "classnames"
import {
  Box,
  Grid,
  Paper,
  Typography,
  Popover,
  IconButton,
  AppBar,
  Toolbar,
  Tooltip,
  CircularProgress,
} from "@material-ui/core"
import { Close as CloseIcon } from "@material-ui/icons"

import axios from "axios"
import { format } from "date-fns"

import { withContext } from "../../../../Utils/context"
import { downloadFile, capitalize } from "../../../../Utils/helpers"
import TruncateAsRequired from "../../../layout/TruncateAsRequired"

import PdfIcon from "../../../assets/pictureAsPdf.svg"
import CsvIcon from "../../../assets/pictureAsCsv.svg"

import ProjectsTable from "./tables/ProjectsTable"
import ProjectsWordCountChart from "./charts/ProjectsWordCount"
import PrintButton from "./pdf/PrintButton"
import SinglePage from "./pdf/SinglePage"
import DatePicker from "../../../layout/DatePicker"

const REPORTING_API = process.env.REACT_APP_REPORTING_URL

const nowUTC = date => {
  let now = date ? new Date(date) : new Date()
  now.setTime(now.getTime() + now.getTimezoneOffset() * 60 * 1000)
  return now
}

const usageTypeDispalyName = {
  excel: "Excel",
  powerbi: "Power BI",
  API: "API",
  tibco: "TIBCO",
}

const Projects = ({ user }) => {
  const classes = useStyles()

  const [dates, setDates] = useState({
    startDate: nowUTC().setDate(nowUTC().getDate() - 27),
    endDate: nowUTC(),
  })
  const [totalWordCount, setTotalWordCount] = useState(0)
  const [selectedProjects, setSelectedProjects] = useState([])
  const [selectedWordCount, setSelectedWordCount] = useState(0)
  const [offset, setOffset] = useState(0)
  const [data, setData] = useState([])
  const [projectNames, setProjectNames] = useState([])
  const [projects, setProjects] = useState([])
  const [anchorEl, setAnchorEl] = useState(null)
  const [loading, setLoading] = useState(true)
  const [pdfLoading, setPdfLoading] = useState(false)
  const [projectLoading, setProjectLoading] = useState(true)
  const [sortBy, setSortBy] = useState({
    key: "wordCount",
    value: "desc",
  })

  const projectPaper = classNames(classes.projectFixedHeight, "slowfadein")

  const sortSelectedProjects = () => {
    if (selectedProjects.length < 1) return
    setPdfLoading(true)
    let projectsSetIdUsageType = []

    const projectsSelected = selectedProjects.map(x => {
      return { projectSetId: x.projectSetId, usageType: x.usageType }
    })
    projectsSetIdUsageType = [{ projectSetIds: projectsSelected }]

    axios
      .post(`${REPORTING_API}/t120/user/${user.id}/project`, {
        sortBy: sortBy,
        offset: 0,
        selectAll: true,
        startDate: format(dates.startDate, "yyyy-MM-dd"),
        endDate: format(dates.endDate, "yyyy-MM-dd"),
        filterBy: projectsSetIdUsageType,
      })
      .then(response => {
        const { records } = response.data.results
        setSelectedProjects(records)
      })
      .catch(() => {})
      .finally(() => setPdfLoading(false))
  }

  useEffect(() => {
    axios
      .post(`${REPORTING_API}/c160/user/${user.id}/wordcount/`, {
        startDate: format(dates.startDate, "yyyy-MM-dd"),
        endDate: format(dates.endDate, "yyyy-MM-dd"),
      })
      .then(({ data: { results } }) => {
        const wc = results.reduce((acc, x) => acc + x.wordCount, 0)
        setTotalWordCount(wc)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dates])

  useEffect(() => {
    if (selectedProjects.length === 0) {
      setSelectedWordCount(0)
      setData([])
      setProjectNames([])
      setProjects([])
      return
    }
    const projects = selectedProjects.map(x => {
      return { projectSetId: x.projectSetId, usageType: x.usageType }
    })
    axios
      .post(`${REPORTING_API}/c120/user/${user.id}/project/wordcount/`, {
        startDate: format(dates.startDate, "yyyy-MM-dd"),
        endDate: format(dates.endDate, "yyyy-MM-dd"),
        projects: projects,
      })
      .then(response => {
        let newTotal = 0
        let newData = []
        let newProjectNames = []
        let newProjects = []

        response.data.results.map(project => {
          newProjects.push(project)
          newProjectNames.push(
            project.projectName +
              "-" +
              project.projectSetId +
              "-" +
              project.usageType,
          )
          return project.rows.map(row => {
            const index = newData.findIndex(x => x.date === row.date)
            newTotal += row.wordCount
            if (index === -1) {
              return newData.push({
                date: row.date,
                [project.projectName +
                "-" +
                project.projectSetId +
                "-" +
                project.usageType]: row.wordCount,
              })
            } else {
              return (newData[index] = {
                ...newData[index],
                [project.projectName +
                "-" +
                project.projectSetId +
                "-" +
                project.usageType]: row.wordCount,
              })
            }
          })
        })
        setSelectedWordCount(newTotal)
        setData(newData)
        setProjectNames(newProjectNames)
        setProjects(selectedProjects)
        setLoading(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dates, selectedProjects])

  const handleDatesChange = dates => {
    // NOTE: This is to prevent the date range and data format error on the chart
    setData([])
    setDates(dates)
    // Set project loading when date changes
    setProjectLoading(true)
    setOffset(0)
  }

  const onSelectProject = prj => {
    const alreadySelected = selectedProjects.some(
      s => s.projectSetId === prj.projectSetId && s.usageType === prj.usageType,
    )
    const newSet = alreadySelected
      ? selectedProjects.filter(
          s =>
            s.projectSetId !== prj.projectSetId ||
            s.usageType !== prj.usageType,
        )
      : selectedProjects.concat(prj)

    setSelectedProjects(newSet)
  }

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
    sortSelectedProjects()
    setLoading(true)
    setProjects([])
    setSelectedWordCount(0)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  selectedProjects.map(x => {
    const usageType = usageTypeDispalyName[x.usageType]
      ? usageTypeDispalyName[x.usageType]
      : capitalize(x.usageType)

    return x.projectName + " (" + usageType + ")"
  })

  // Download to CSV
  const handleCsv = () => {
    let csv = "Date"
    let projectTotals = []
    projects.forEach(project => {
      csv =
        csv +
        `,${project.projectName} (${
          usageTypeDispalyName[project.usageType]
            ? usageTypeDispalyName[project.usageType]
            : capitalize(project.usageType)
        })`
      projectTotals.push(0)
    })
    csv = csv + "\n"

    data.forEach(row => {
      csv = csv + row.date
      projects.forEach((project, i) => {
        csv =
          csv +
          "," +
          row[
            project.projectName +
              "-" +
              project.projectSetId +
              "-" +
              project.usageType
          ]
        projectTotals[i] =
          projectTotals[i] +
          row[
            project.projectName +
              "-" +
              project.projectSetId +
              "-" +
              project.usageType
          ]
      })
      csv = csv + "\n"
    })

    csv = csv + "Total Word Count," + projectTotals.join(",")

    const filename = `Word_Count_Report_${user.displayName}_${format(
      dates.startDate,
      "yyyyddMM",
    )}-${format(dates.endDate, "yyyyddMM")}.csv`

    downloadFile(filename, csv)
  }

  const open = Boolean(anchorEl)
  const id = open ? "simple-popover" : undefined

  // Popover for PDF export
  const ExportPDFPopover = () => (
    <div
      style={{
        width: "210mm",
        minHeight: "297mm",
      }}
    >
      <AppBar
        position="static"
        style={{
          backgroundColor: "#fff",
        }}
      >
        <Toolbar>
          <Grid container justify="space-between">
            <Grid item>
              <PrintButton
                displayName={user.displayName}
                dates={dates}
                totalWordCount={totalWordCount.toLocaleString()}
                selectedProjectsCount={selectedProjects.length.toLocaleString()}
                onClose={handleClose}
                loading={!projects.length >= 1}
              />
            </Grid>
            <Grid item>
              <IconButton
                style={{
                  marginRight: "25mm",
                }}
                onClick={handleClose}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>

      <SinglePage
        displayName={user.displayName}
        dates={dates}
        data={data}
        projects={projects}
        projectNames={projectNames}
        totalWordCount={totalWordCount.toLocaleString()}
        selectedWordCount={selectedWordCount.toLocaleString()}
        loading={loading && selectedProjects.length !== 0}
      />
    </div>
  )

  return (
    <>
      <Paper className={classes.paper}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div className="slowfadein">
              <Grid container justify="space-between">
                <Grid item>
                  <Typography variant="body2" component="div">
                    Customer:{" "}
                    <TruncateAsRequired
                      variant="subtitle2"
                      title={user.displayName}
                    >
                      {user.displayName}
                    </TruncateAsRequired>
                  </Typography>
                  <Box m={0.4} />
                  <Typography variant="body2">
                    Date Range (UTC):
                    <DatePicker
                      disabled={projectLoading}
                      dates={dates}
                      onDatesChange={handleDatesChange}
                      placement="bottom-start"
                      style={{
                        fontSize: "0.875rem",
                        fontWeight: "500",
                        lineHeight: "1.57",
                        letterSpacing: "0.00714em",
                        color: "rgba(0, 0, 0, 0.87)",
                        marginBottom: "3px",
                      }}
                    />
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2">
                    Total Word Count:{" "}
                    <Typography variant="subtitle2" component="span">
                      {totalWordCount.toLocaleString()}
                    </Typography>
                  </Typography>
                  <Box m={1} />
                  <Typography variant="body2">
                    Word Count for Selected Projects:{" "}
                    <Typography variant="subtitle2" component="span">
                      {selectedWordCount.toLocaleString()}
                    </Typography>
                  </Typography>
                  <Box m={1} />
                  <Typography variant="body2">
                    Projects Selected:{" "}
                    <Typography variant="subtitle2" component="span">
                      {selectedProjects.length}
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2">
                    Export to:&nbsp;
                    <Tooltip title="Export to CSV" placement="top">
                      <IconButton onClick={handleCsv} style={{ padding: 4 }}>
                        <img src={CsvIcon} width={22} height={22} alt="csv" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Create PDF report" placement="top">
                      <IconButton onClick={handleClick} style={{ padding: 4 }}>
                        <img src={PdfIcon} width={22} height={22} alt="csv" />
                      </IconButton>
                    </Tooltip>
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <ProjectsTable
              userId={user.id}
              dates={dates}
              onDatesChange={handleDatesChange}
              selectedProjects={selectedProjects}
              offset={offset}
              setOffset={setOffset}
              onSelectProject={onSelectProject}
              setSelectedProjects={setSelectedProjects}
              changeSortOrder={setSortBy}
              projectLoading={projectLoading}
              setProjectLoading={setProjectLoading}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Typography variant="h6" className={classes.subtitle}>
              Projects Word Count
            </Typography>
            <Box m={2.5} />
            <Paper className={projectPaper} elevation={3}>
              <ProjectsWordCountChart
                userId={user.id}
                dates={dates}
                data={data}
                projectNames={projectNames}
              />
            </Paper>
          </Grid>
        </Grid>
      </Paper>

      {pdfLoading ? (
        <CircularProgress style={{ marginLeft: "50%" }} />
      ) : (
        <Popover
          id={id}
          open={open}
          onClose={handleClose}
          anchorReference="anchorPosition"
          anchorPosition={{ top: 100, left: 500 }}
        >
          <ExportPDFPopover />
        </Popover>
      )}
    </>
  )
}

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(3),
  },
  subtitle: {
    fontWeight: 300,
    color: "#4a4a4a",
    fontSize: "16px",
    paddingTop: "6px",
  },
  projectMinFixedHeight: {
    minHeight: 480,
  },
  projectFixedHeight: {
    height: 535,
  },
  paddingBottom: {
    padding: 0,
    paddingBottom: 40,
  },
}))

export default withContext(Projects)
