/* eslint-disable no-sequences */
import React, { useState, useContext } from "react"
import axios from "axios"
import format from "date-fns/format"
import { AuthContext } from "../../../../Utils/context"

import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core"
import makeStyles from "@material-ui/core/styles/makeStyles"

import AddIcon from "@material-ui/icons/Add"
import ClearIcon from "@material-ui/icons/Clear"

const Api = {
  ENTITLEMENTS_META_URI: `${process.env.REACT_APP_USER_URL}/entitlements/meta`,
  LICENSE_HIERARCHY: [
    "bi-creator-3-premium",
    "bi-creator-2-intermediate",
    "bi-creator-3-basic",
    "studio-app",
    "excel-plugin",
    "exchange-app",
    "docs-app",
  ],

  makeEntitlementsURI: uid =>
    `${process.env.REACT_APP_USER_URL}/${uid}/entitlements`,

  fetchAllEntitlementTypes: () =>
    axios.get(Api.ENTITLEMENTS_META_URI).then(x => x.data?.data || []),

  userFetchEntitlements: uid =>
    axios
      .get(Api.makeEntitlementsURI(uid))
      .then(x => x.data?.entitlements || []),

  userPatchEntitlements: (uid, entitlements) =>
    axios.patch(Api.makeEntitlementsURI(uid), { entitlements }),

  sort: xs =>
    xs.sort(
      (left, right) =>
        Api.LICENSE_HIERARCHY.indexOf(left.entitlement) -
        Api.LICENSE_HIERARCHY.indexOf(right.entitlement),
    ),
}

const Licenses = ({ user }) => {
  const classes = getStyles()
  const { createSuccess, createError } = useContext(AuthContext)

  const [userLicenses, setUserLicenses] = useState(null)
  const [allLicenses, setAllLicenses] = useState(null)
  const [formOpen, setFormOpen] = useState(false)
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [licenseToRemove, removeAfterConfirmation] = useState()

  const [loading, setLoading] = useState(false)

  const diableLicense = user.identity.identityProvider === "EXTERNAL_PROVIDER"

  const fns = {
    toDateStr: d => format(new Date(d), "dd MMMM yyyy"),
    showDialog: () => setFormOpen(true),
    hideDialog: () => setFormOpen(false),
    hideConfirmDialog: () => setConfirmOpen(false),

    showConfirmDialog: license => () => {
      removeAfterConfirmation(license)
      setConfirmOpen(true)
    },
    // fetch/refetch data
    hydrate() {
      Api.userFetchEntitlements(user.id).then(setUserLicenses)
      !allLicenses && Api.fetchAllEntitlementTypes().then(setAllLicenses)
    },

    addNewLicense(license) {
      fns.hideDialog()
      setLoading(true)
      Api.userPatchEntitlements(user.id, [
        ...userLicenses.map(x => x.entitlement),
        license,
      ])
        .then(res => {
          if (!res) return

          createSuccess("Sucessfully added license")
          fns.hydrate()
        })
        .catch(() => createError("There was an error adding the license"))
        .finally(() => setLoading(false))
    },

    removeLicense: license => {
      const entitlements = userLicenses
        .filter(l => l.entitlement !== license)
        .map(l => l.entitlement)

      return () => {
        fns.hideConfirmDialog()
        setLoading(true)
        Api.userPatchEntitlements(user.id, entitlements)
          .catch(() => setLoading(false))
          .then(_res => {
            createSuccess("License successfully removed")
            setLoading(false)
            fns.hydrate()
          })
      }
    },
  }

  if (!userLicenses || !allLicenses) {
    fns.hydrate()
    return (
      <Paper className={classes.root}>
        <Box>Fetching latest data...</Box>
      </Paper>
    )
  }

  const AddEntitlementDialog = ({ freeLicenses }) => {
    // Filtering entitlements of mapType: "CSP" only Refer to CSP-793
    freeLicenses = freeLicenses
      .filter(li => li.mapType === "CSP")
      .sort((a, b) => a.displayName.localeCompare(b.displayName))

    const firstEntry =
      freeLicenses.length === 0
        ? { entitlement: "studio-app" }
        : freeLicenses[0]
    const [newLicense, setNewLicense] = useState(firstEntry.entitlement)

    return (
      <Dialog open={formOpen} aria-labelledby="Add License">
        <Box minWidth={350} minHeight={50} padding={2}>
          <DialogTitle id="form-dialog-title">Add New License</DialogTitle>
          <DialogContent>
            <Select
              labelId="select-new-license"
              id="select-new-license"
              value={newLicense}
              onChange={e => setNewLicense(e.target.value)}
              style={{ width: 350, marginBottom: 30 }}
            >
              {freeLicenses.map(e => (
                <MenuItem key={e.entitlement} value={e.entitlement}>
                  {e.displayName}
                </MenuItem>
              ))}
            </Select>
          </DialogContent>
          <DialogActions>
            <Button onClick={fns.hideDialog}>Cancel</Button>
            <Button
              onClick={() => fns.addNewLicense(newLicense)}
              color="primary"
              variant="contained"
            >
              Add license
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    )
  }

  const RemoveLicenceConfirmationDialog = () => (
    <Dialog
      open={confirmOpen}
      aria-labelledby="confirm-removal-title"
      aria-describedby="confirm-removal"
    >
      <DialogTitle id="confirm-removal-title">Confirm Removal</DialogTitle>
      <DialogContent>
        <DialogContentText id="confirm-removal">
          Remove license for this user?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={fns.hideConfirmDialog}>cancel</Button>
        <Button
          onClick={fns.removeLicense(licenseToRemove)}
          color="primary"
          autoFocus
        >
          Remove
        </Button>
      </DialogActions>
    </Dialog>
  )

  // Available licenses that the user doesn't already have
  const freeLicenses = allLicenses.filter(
    m => !userLicenses.some(e => e.entitlement === m.entitlement),
  )

  if (userLicenses.length === 0) {
    return (
      <Paper className={classes.root}>
        <Typography style={{ marginBottom: 20 }}>
          This user does not have a license.
        </Typography>
        <Button
          color="primary"
          variant="contained"
          className={classes.actionBtn}
          onClick={fns.showDialog}
          startIcon={<AddIcon />}
          size="small"
          disabled={freeLicenses.length === 0 || diableLicense}
        >
          Add a license
        </Button>
        <AddEntitlementDialog freeLicenses={freeLicenses} />
        {loading && (
          <Box className={classes.overlay}>
            <CircularProgress />
          </Box>
        )}
      </Paper>
    )
  }

  const LicenseRow = license => {
    return (
      <TableRow key={license.entitlement}>
        <TableCell className={classes.grey}>
          <Typography variant="subtitle2" display="inline">
            {license.displayName}
          </Typography>
        </TableCell>
        <TableCell className={classes.grey}>
          <pre className={classes.code}>{license.entitlement}</pre>
        </TableCell>
        {<TableCell>{fns.toDateStr(license.updatedDate)}</TableCell>}
        <TableCell>
          {/* {!e.mapType !== "PLAN_ID" && ( */}
          {!license.mapType?.startsWith("bi-creator") && (
            <Button
              variant="outlined"
              size="small"
              onClick={fns.showConfirmDialog(license.entitlement)}
              startIcon={<ClearIcon />}
              className={classes.deleteButton}
            >
              Remove
            </Button>
          )}
        </TableCell>
      </TableRow>
    )
  }

  const EntitlementsTable = () => (
    <Table size="small" className={classes.table}>
      <TableHead>
        <TableRow>
          <TableCell>License</TableCell>
          <TableCell>Entitlement</TableCell>
          <TableCell>Created Date</TableCell>
          <TableCell>
            <Button
              color="primary"
              variant="contained"
              className={classes.actionBtn}
              onClick={fns.showDialog}
              startIcon={<AddIcon />}
              disabled={freeLicenses.length === 0}
              size="small"
            >
              Add License
            </Button>
          </TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {userLicenses
          .map(license => {
            const e = allLicenses.find(
              l => l.entitlement === license.entitlement,
            )
            e.updatedDate = license.updatedDate // add updated date to license
            return e
          })
          .sort((a, b) => a.displayName.localeCompare(b.displayName))
          .map(LicenseRow)}
      </TableBody>
    </Table>
  )

  return (
    <Paper className={classes.root}>
      <Grid container spacing={3} justify="space-between">
        <EntitlementsTable />
      </Grid>
      {loading && (
        <Box className={classes.overlay}>
          <CircularProgress />
        </Box>
      )}

      <RemoveLicenceConfirmationDialog />
      <AddEntitlementDialog freeLicenses={freeLicenses} />
    </Paper>
  )
}

const getStyles = makeStyles(theme => ({
  root: {
    minHeight: 300,
    padding: theme.spacing(2),
    position: "relative",
  },
  overlay: {
    position: "absolute",
    background: "rgba(0,0,0,0.2)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
  },
  table: {
    // maxWidth: "50vw",
  },
  code: {
    fontFamily: "Roboto Mono",
    margin: 0,
  },
  actionBtn: {
    minWidth: 140,
  },
  deleteButton: {
    width: 140,
    color: theme.palette.danger.dark,
    borderColor: theme.palette.danger.dark,
  },
  angleIcon: {
    fontSize: 14,
    marginRight: 5,
  },
  grey: {
    color: "rgba(0, 0, 0, 0.54)",
  },
}))

export default Licenses
