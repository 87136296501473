import { createMuiTheme } from "@material-ui/core/styles"
import green from "@material-ui/core/colors/green"

export default createMuiTheme({
  palette: {
    primary: {
      light: "#6cb3e9",
      main: "#0081be",
      dark: "#163e5d",
    },
    warning: {
      light: "#eecc70",
      main: "#eeb270",
    },
    danger: {
      main: "#c7473e",
      dark: "#e47f63",
    },
    success: {
      main: green[500],
      light: "#c4df9b",
    },
  },
})
