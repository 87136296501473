import React, { useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles"
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Avatar,
  Popover,
  Paper,
  MenuList,
  MenuItem,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from "@material-ui/core"
import classNames from "classnames"
import {
  Person as PersonIcon,
  PowerSettingsNew as PowerSettingsNewIcon,
} from "@material-ui/icons"
import LogoIcon from "./logoIcon.svg"
import { version } from "../../../../package.json"
import { withContext } from "../../../Utils/context"
import { parseJwt } from "../../../Utils/helpers"
import ls from "../../../Utils/localStorage"
import { Link } from "react-router-dom"

const ENV = process.env.REACT_APP_ENV
const gitbuild = process.env.REACT_APP_GIT_SHA

const useStyles = makeStyles(theme => ({
  appBar: {
    backgroundColor: theme.palette.primary.main,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    // marginLeft: drawerWidth,
    // width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  toolbar: {
    paddingLeft: 0,
  },
  logoWrapper: {
    overflowX: "hidden",
    width: theme.spacing(7) + 3,
  },
  logoIcon: {
    width: theme.spacing(4),
  },
  appTitle: {
    flex: 1,
    marginLeft: theme.spacing(3),
    fontWeight: 400,
  },
  logo: {
    height: "30px",
    paddingTop: "10px",
  },
  button: {
    padding: 0,
  },
  avatar: {
    backgroundColor: "#616161",
    color: "#c4c4c4",
    margin: 10,
  },
  version: {
    marginTop: theme.spacing(1),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  gutterMargin: {
    ...theme.mixins.gutters(),
  },
}))

function Topbar({ sidenavOpen, logUserOut }) {
  const classes = useStyles()
  const [avatarOpen, setAvatarOpen] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [email, setEmail] = React.useState(null)
  const [firstName, setFirstName] = React.useState(" ")
  const [lastName, setLastName] = React.useState(" ")
  const popoverId = avatarOpen ? "avatar-popover" : null

  useEffect(() => {
    const token = ls.get()?.token

    if (!token || token === "123") {
      setFirstName("Offline")
      setLastName("Env")
      return
    }

    const { given_name, family_name, email } = parseJwt(token)
    if (given_name && family_name) {
      setFirstName(given_name)
      setLastName(family_name)
    } else {
      setEmail(email)
    }
  }, [])

  const handlePopOpen = event => {
    setAnchorEl(event.currentTarget)
    setAvatarOpen(true)
  }

  const handlePopClose = () => {
    setAnchorEl(null)
    setAvatarOpen(false)
  }

  return (
    <AppBar
      position="fixed"
      className={classNames({
        [classes.appBar]: true,
        [classes.appBarShift]: sidenavOpen,
      })}
    >
      <Toolbar className={classes.toolbar}>
        <div className={classes.logoWrapper}>
          <List>
            <Link to="/">
              <ListItem button key="Dashboard">
                <ListItemIcon className="mediumfadein">
                  <img src={LogoIcon} alt="logo" className={classes.logoIcon} />
                </ListItemIcon>
              </ListItem>
            </Link>
          </List>
        </div>
        <Typography
          variant="h6"
          color="inherit"
          className={classes.appTitle}
          noWrap
        >
          Customer Service Portal
        </Typography>
        {ENV !== "production" && (
          <Typography
            variant="body1"
            color="inherit"
            className="mediumfadein"
            noWrap
          >
            {ENV}&nbsp;
            {version}&nbsp;b{gitbuild}
          </Typography>
        )}
        <Button
          aria-describedby={popoverId}
          onClick={handlePopOpen}
          className={classes.button}
        >
          <Avatar className={classes.avatar}>
            {email ? email[0] : firstName[0] + lastName[0]}
          </Avatar>
        </Button>
        <Popover
          id={popoverId}
          open={avatarOpen}
          anchorEl={anchorEl}
          onClose={handlePopClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Paper>
            <MenuList>
              <MenuItem>
                <ListItemIcon>
                  <PersonIcon />
                </ListItemIcon>
                <ListItemText
                  primary={email ? email : `${firstName[0]} ${lastName[0]}`}
                />
              </MenuItem>
              <MenuItem onClick={logUserOut}>
                <ListItemIcon>
                  <PowerSettingsNewIcon />
                </ListItemIcon>
                <ListItemText primary="Sign out" />
              </MenuItem>
              <Divider />
              <Typography
                variant="caption"
                display="block"
                align="center"
                className={classes.version}
              >
                {version}&nbsp;b{gitbuild}
              </Typography>
            </MenuList>
          </Paper>
        </Popover>
      </Toolbar>
    </AppBar>
  )
}

export default withContext(Topbar)
