import React, { useState, useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles"
import clsx from "clsx"

import { withContext } from "../../../../Utils/context"
import { str2num } from "../../../../Utils/helpers"
import axios from "axios"

import {
  Grid,
  Typography,
  Paper,
  Toolbar,
  IconButton,
  Divider,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  CircularProgress,
  Tooltip,
} from "@material-ui/core"
import {
  Refresh as RefreshIcon,
  FilterList as FilterListIcon,
} from "@material-ui/icons"

import Searchbar from "../../../layout/Searchbar"
import CreationDialog from "../creation/CreationModal"
import PartnerListTableHead from "./PartnerListTableHead"
import PartnerTableRow from "./PartnerTableRow"

const PARTNER_API = process.env.REACT_APP_PARTNER_URL

const PartnerListPage = props => {
  const params = new URLSearchParams(props.location.search)
  const classes = useStyles()

  const [searchBy, setSearchBy] = useState(params.get("searchBy") ?? "")
  const [sortBy, setSortBy] = useState({
    key: params.get("sortBy") ?? "displayName",
    value: params.get("direction") ?? "asc",
  })
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [data, setData] = useState([])
  const [totalRows, setTotalRows] = useState(0)
  const [pagination, setPagination] = useState({
    limit: str2num(params.get("limit") ?? 50),
    offset: str2num(params.get("offset") ?? 0),
  })

  useEffect(() => {
    setLoading(true)
    setError(false)

    const body = {
      sortBy,
      limit: pagination.limit,
      offset: pagination.offset,
    }
    const params = new URLSearchParams()
    params.append("sortBy", sortBy.key)
    params.append("direction", sortBy.value)
    params.append("limit", pagination.limit)
    params.append("offset", pagination.offset)

    if (searchBy) {
      body.searchBy = searchBy
      params.append("searchBy", searchBy)
    }

    axios
      .post(PARTNER_API, body)
      .then(response => {
        props.history.replace(
          window.location.pathname + "?" + params.toString(),
        )
        setLoading(false)
        setData(response?.data?.records)
        setTotalRows(response.data.totalTableCount)
      })
      .catch(_ => {
        setLoading(false)
        setError(true)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy, pagination])

  const handleSearchChange = value => {
    setSearchBy(value)
    setPagination({
      limit: pagination.limit,
      offset: 0,
    })
  }

  const handleSort = key => {
    setPagination({ limit: pagination.limit, offset: 0 })

    sortBy.key === key
      ? // toggle sort direction
        setSortBy({ key, value: sortBy.value === "asc" ? "desc" : "asc" })
      : // use default direction
        setSortBy({
          key,
          value: ["organisationCount", "createdDate"].includes(key)
            ? "desc"
            : "asc",
        })
  }

  const handleChangePage = (_event, newPage) => {
    setPagination({
      limit: pagination.limit,
      offset: newPage * pagination.limit,
    })
  }

  const handleChangeRowsPerPage = ({ target: { value } }) => {
    if (!error && !loading) {
      setPagination({
        limit: value,
        offset: 0,
      })
    }
  }

  const handleReload = () =>
    setPagination({
      limit: pagination.limit,
      offset: pagination.offset,
    })

  return (
    <Grid container>
      <Grid container justify="space-between">
        <Typography component="h4" variant="h6" className={classes.title}>
          Partners
        </Typography>
        <CreationDialog />
      </Grid>
      <Grid item xs={12}>
        <Paper className={clsx("slowfadein", classes.paper)}>
          <Toolbar className={classes.toolbar}>
            <div className={classes.flex}>
              <Searchbar
                value={searchBy}
                placeholder="Search by partner name"
                disabled={false}
                onChange={handleSearchChange}
              />
            </div>
            <IconButton
              aria-label="Reload"
              size="small"
              onClick={handleReload}
              disabled={loading}
            >
              <Tooltip title="Reload" placement="left">
                <RefreshIcon />
              </Tooltip>
            </IconButton>
            <IconButton
              className={classes.filter}
              aria-label="Filter"
              size="small"
              disabled={true}
            >
              <FilterListIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <Table size="small">
            <PartnerListTableHead
              activeKey={sortBy.key}
              direction={sortBy.value}
              onSort={handleSort}
            />
            <TableBody>
              {loading || error ? (
                <TableRow style={{ height: 37 * 10 }}>
                  <TableCell padding="checkbox" align="center" colSpan={6}>
                    {loading ? (
                      <CircularProgress />
                    ) : (
                      <Typography variant="body1">
                        Sorry an error occurred fetching partners
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
              ) : (
                <>
                  {data.length === 0 ? (
                    <TableRow style={{ height: 37 * 10 }}>
                      <TableCell padding="checkbox" align="center" colSpan={6}>
                        <Typography variant="body1">
                          No partners found
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ) : (
                    <>
                      {data.map((partner, i) => (
                        <PartnerTableRow
                          key={i}
                          partnerId={partner.id}
                          displayName={partner.displayName}
                          organisationCount={partner.organisationCount}
                          accountStatus={partner.accountStatus}
                          createdDate={partner.createdDate}
                        />
                      ))}
                    </>
                  )}
                </>
              )}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[50, 100, 250]}
            component="div"
            count={totalRows}
            rowsPerPage={pagination.limit}
            page={
              pagination.offset !== 0 ? pagination.offset / pagination.limit : 0
            }
            backIconButtonProps={{
              "aria-label": "Previous Page",
            }}
            nextIconButtonProps={{
              "aria-label": "Next Page",
            }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: 500,
    color: "#4a4a4a",
    fontSize: "18px/14px",
    marginBottom: theme.spacing(2),
  },
  paper: {
    minWidth: 620,
  },
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  flex: {
    flex: 1,
  },
  chip: {
    fontSize: "8pt",
    fontWeight: 500,
  },
  success: {
    backgroundColor: theme.palette.success.light,
  },
  warning: {
    backgroundColor: theme.palette.warning.light,
  },
  disabled: {
    backgroundColor: theme.palette.danger.dark,
    color: "#fff",
  },
  filter: {
    marginLeft: theme.spacing(1),
  },
}))

export default withContext(PartnerListPage)
