import React from "react"
import PropTypes from "prop-types"

import {
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
} from "@material-ui/core"

const PartnerListTableHead = ({ activeKey, direction, onSort }) => {
  return (
    <TableHead>
      <TableRow>
        <TableCell>
          <TableSortLabel
            active={activeKey === "displayName"}
            direction={direction}
            onClick={() => onSort("displayName")}
          >
            Partner name
          </TableSortLabel>
        </TableCell>
        <TableCell align="center">
          <TableSortLabel
            active={activeKey === "organisationCount"}
            direction={activeKey === "organisationCount" ? direction : "desc"}
            onClick={() => onSort("organisationCount")}
          >
            Total companies
          </TableSortLabel>
        </TableCell>
        <TableCell align="center">
          <TableSortLabel
            active={activeKey === "accountStatus"}
            direction={direction}
            onClick={() => onSort("accountStatus")}
          >
            Status
          </TableSortLabel>
        </TableCell>
        <TableCell align="right">
          <TableSortLabel
            align="right"
            active={activeKey === "createdDate"}
            direction={activeKey === "createdDate" ? direction : "desc"}
            onClick={() => onSort("createdDate")}
          >
            Join date
          </TableSortLabel>
        </TableCell>
      </TableRow>
    </TableHead>
  )
}

PartnerListTableHead.propTypes = {
  activeKey: PropTypes.string.isRequired,
  direction: PropTypes.string.isRequired,
  onSort: PropTypes.func.isRequired,
}

export default PartnerListTableHead
