import React, { useState, useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles"
import classNames from "classnames"
import { format } from "date-fns"

import { withContext } from "../../../Utils/context"
import axios from "axios"

import { Grid, IconButton, Typography } from "@material-ui/core"
import {
  TrendingUp as TrendingUpIcon,
  TrendingFlat as TrendingFlatIcon,
  TrendingDown as TrendingDownIcon,
} from "@material-ui/icons"

import NumberAbbreviator from "../../../Utils/numberAbbreviator"
import NoData from "../../layout/NoData"

const REPORTING_API = process.env.REACT_APP_REPORTING_URL

const buttonUseStyles = makeStyles(() => ({
  iconButton: {
    color: "#fff",
  },
  success: {
    backgroundColor: "#4AB137",
  },
  danger: {
    backgroundColor: "#B15237",
  },
  warning: {
    backgroundColor: "#D69C1B",
  },
  icon: {
    fontSize: 14,
  },
}))

const TrendIconButton = report => {
  const { monthCount, lastMonthCount } = report.report
  const classes = buttonUseStyles()

  let iconButtonStyle = classNames(classes.iconButton, classes.warning)
  let trendIcon = <TrendingFlatIcon className={classes.icon} />

  if (monthCount > lastMonthCount) {
    iconButtonStyle = classNames(classes.iconButton, classes.success)
    trendIcon = <TrendingUpIcon className={classes.icon} />
  } else if (monthCount < lastMonthCount) {
    iconButtonStyle = classNames(classes.iconButton, classes.danger)
    trendIcon = <TrendingDownIcon className={classes.icon} />
  }

  return (
    <IconButton size="small" className={iconButtonStyle}>
      {trendIcon}
    </IconButton>
  )
}

const WordTrendIconButton = report => {
  const { monthCount, lastMonthCount } = report.report
  const classes = buttonUseStyles()

  let iconButtonStyle = classNames(classes.iconButton, classes.warning)
  let trendIcon = <TrendingFlatIcon className={classes.icon} />

  if (monthCount !== 0 && lastMonthCount !== 0) {
    if (((monthCount - lastMonthCount) / lastMonthCount) * 100 > 3) {
      iconButtonStyle = classNames(classes.iconButton, classes.success)
      trendIcon = <TrendingUpIcon className={classes.icon} />
    } else if (((lastMonthCount - monthCount) / lastMonthCount) * 100 > 3) {
      iconButtonStyle = classNames(classes.iconButton, classes.danger)
      trendIcon = <TrendingDownIcon className={classes.icon} />
    }
  }

  return (
    <IconButton size="small" className={iconButtonStyle}>
      {trendIcon}
    </IconButton>
  )
}

const useStyles = makeStyles(theme => ({
  trendContainer: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    backgroundColor: "#F8F8F8",
  },
  iconButton: {
    color: "#fff",
    backgroundColor: "#D69C1B",
  },
  success: {
    backgroundColor: "#4AB137",
  },
  danger: {
    backgroundColor: "#B15237",
  },
  trendValue: {
    fontSize: 18,
    fontWeight: 400,
    marginTop: theme.spacing(1),
  },
  trendTitle: {
    fontSize: 14,
    fontWeight: 400,
  },
  narrativeText: {
    padding: theme.spacing(4),
    fontSize: 14,
    fontWeight: 400,
    wordBreak: "break-word",
  },
}))

const nowUTC = date => {
  let now = date ? new Date(date) : new Date()
  now.setTime(now.getTime() + now.getTimezoneOffset() * 60 * 1000)
  return now
}

const Trends = () => {
  const classes = useStyles()
  const [fetchComplete, setFetchComplete] = useState(false)
  const [userTrendReport, setUserTrendReport] = useState(null)
  const [organisationTrendReport, setOrganisationTrendReport] = useState(null)
  const [wordCountTrendReport, setWordCountTrendReport] = useState(null)
  const [narrative, setNarrative] = useState(null)

  useEffect(() => {
    axios.get(`${REPORTING_API}/d100/summary/`).then(response => {
      const result = response?.data?.result
      if (!result) return

      setUserTrendReport(result.user)
      setOrganisationTrendReport(result.organisation)
      setWordCountTrendReport(result.word)
      setNarrative(result.narrative)

      setFetchComplete(true)
    })
  }, [])

  if (!fetchComplete) return <div></div>

  if (
    !userTrendReport ||
    !organisationTrendReport ||
    !wordCountTrendReport ||
    !narrative
  ) {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12} align="center">
          <NoData />
        </Grid>
      </Grid>
    )
  }

  return (
    <>
      <Grid container className={classes.trendContainer}>
        <Grid item xs={3} align="center">
          <TrendIconButton report={userTrendReport} />
          <Typography className={classes.trendValue}>
            {userTrendReport.monthCount}
          </Typography>
          <Typography className={classes.trendTitle}>Total Users</Typography>
        </Grid>
        <Grid item xs={3} align="center">
          <TrendIconButton report={userTrendReport} />
          <Typography className={classes.trendValue}>
            {userTrendReport.monthCount - userTrendReport.lastMonthCount}
          </Typography>
          <Typography className={classes.trendTitle}>New Users</Typography>
        </Grid>
        <Grid item xs={3} align="center">
          <TrendIconButton report={organisationTrendReport} />
          <Typography className={classes.trendValue}>
            {organisationTrendReport.monthCount}
          </Typography>
          <Typography className={classes.trendTitle}>Companies</Typography>
        </Grid>
        <Grid item xs={3} align="center">
          <WordTrendIconButton report={wordCountTrendReport} />
          <Typography className={classes.trendValue}>
            {NumberAbbreviator(wordCountTrendReport.monthCount)}
          </Typography>
          <Typography className={classes.trendTitle}>
            {format(nowUTC(), "MMM")} Word Count
          </Typography>
        </Grid>
      </Grid>
      <Typography
        className={classes.narrativeText}
        dangerouslySetInnerHTML={{ __html: narrative }}
      />
    </>
  )
}

export default withContext(Trends)
