import React from "react"
import Page from "./Page"
import { format } from "date-fns"

import { Typography, Grid, CircularProgress } from "@material-ui/core"

import { capitalize } from "../../../../../Utils/helpers"
import PDFChart from "./PDFChart"
import arrialogo from "./arrialogo.png"

/*const strokes = [
  "#358dff",
  "#f9ce32",
  "#48c462",
  "#ec4d6a",
  "#54dac5",
  "#8446de",
  "#f9cd32",
  "#36c1bf",
  "#e39663",
  "#7865ca",
  "#648ce5",
  "#443fc4",
  "#65a0e5",
  "#394276",
]*/

const nowUTC = date => {
  let now = date ? new Date(date) : new Date()
  now.setTime(now.getTime() + now.getTimezoneOffset() * 60 * 1000)
  return now
}

const usageTypeDispalyName = {
  excel: "Excel",
  powerbi: "Power BI",
  API: "API",
  tibco: "TIBCO",
}

const SinglePage = ({
  data,
  dates,
  displayName,
  loading,
  projects,
  selectedWordCount,
  topProjects,
  totalWordCount,
  selectedProjects,
}) => {
  return (
    <Page>
      <div
        style={{
          width: "210mm",
          padding: "15mm 15mm",
          paddingBottom: "5mm",
        }}
      >
        {loading && (!projects || projects.length === 0) ? (
          <CircularProgress style={{ marginLeft: "50%" }} />
        ) : (
          <Grid
            style={{ height: "100%" }}
            container
            direction="column"
            justify="space-between"
          >
            <Grid item>
              <Grid container justify="space-between" alignItems="center">
                <Grid item>
                  <Typography
                    style={{
                      fontSize: 22,
                      marginBottom: 20,
                      fontFamily: "Helvetica Neue",
                    }}
                  >
                    Word Count Report
                  </Typography>
                </Grid>
                <Grid item>
                  <img
                    id="arria-logo"
                    src={arrialogo}
                    alt="arria natural language generation"
                    style={{ height: 92, width: 92 }}
                  />
                </Grid>
              </Grid>
              <Typography
                style={{
                  marginBottom: 10,
                  fontFamily: "Helvetica Neue",
                  fontSize: 11,
                }}
              >
                For: <b>{displayName}</b>
              </Typography>
              <Typography
                style={{
                  marginBottom: 10,
                  fontFamily: "Helvetica Neue",
                  fontSize: 11,
                }}
              >
                Report Date: &nbsp;&nbsp;
                <b>{format(nowUTC(), "d MMMM yyyy")}</b>
              </Typography>
              <Typography
                style={{
                  marginBottom: 10,
                  fontFamily: "Helvetica Neue",
                  fontSize: 11,
                }}
              >
                Usage Period:&nbsp;
                <b>
                  {format(dates.startDate, "d MMM yyyy")}&nbsp;-&nbsp;
                  {format(dates.endDate, "d MMM yyyy")}
                </b>
              </Typography>
              <Typography
                style={{
                  marginBottom: 10,
                  fontFamily: "Helvetica Neue",
                  fontSize: 11,
                }}
              >
                Word count for all projects: <b>{totalWordCount}</b>
              </Typography>
              <Typography
                style={{
                  marginBottom: 30,
                  fontFamily: "Helvetica Neue",
                  fontSize: 11,
                }}
              >
                Projects selected: <b>{projects.length}</b>
              </Typography>
              <Typography
                style={{
                  marginBottom: 5,
                  fontFamily: "Helvetica Neue",
                }}
              >
                Selected Projects:
              </Typography>
              <table
                id="project-table"
                style={{
                  marginBottom: 20,
                  width: "100%",
                  borderCollapse: "collapse",
                  fontFamily: "Helvetica Neue",
                }}
              >
                <thead>
                  <tr
                    style={{
                      backgroundColor: "#D8D8D8",
                    }}
                  >
                    <th
                      style={{
                        border: "1px solid #979797",
                        paddingLeft: "5px",
                        fontWeight: 400,
                        textAlign: "left",
                        fontSize: 10,
                      }}
                    >
                      ID
                    </th>
                    <th
                      style={{
                        border: "1px solid #979797",
                        paddingLeft: "10px",
                        fontWeight: 400,
                        textAlign: "left",
                        fontSize: 10,
                      }}
                    >
                      Project Name
                    </th>
                    <th
                      style={{
                        border: "1px solid #979797",
                        paddingLeft: "10px",
                        fontWeight: 400,
                        textAlign: "left",
                        fontSize: 10,
                      }}
                    >
                      Usage Type
                    </th>
                    <th
                      style={{
                        border: "1px solid #979797",
                        paddingRight: "10px",
                        fontWeight: 400,
                        textAlign: "right",
                        fontSize: 10,
                      }}
                    >
                      Word Count
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {projects.map((project, i) => (
                    <tr key={i}>
                      <td
                        style={{
                          border: "1px solid #979797",
                          paddingLeft: "5px",
                          fontWeight: 400,
                          textAlign: "left",
                          fontSize: 10,
                        }}
                      >
                        {project.projectSetId}
                      </td>
                      <td
                        style={{
                          border: "1px solid #979797",
                          paddingLeft: "10px",
                          fontWeight: 400,
                          textAlign: "left",
                          fontSize: 10,
                        }}
                      >
                        {project.projectName}
                      </td>
                      <td
                        style={{
                          border: "1px solid #979797",
                          paddingLeft: "10px",
                          fontWeight: 400,
                          textAlign: "left",
                          fontSize: 10,
                        }}
                      >
                        {usageTypeDispalyName[project.usageType]
                          ? usageTypeDispalyName[project.usageType]
                          : capitalize(project.usageType)}
                      </td>
                      <td
                        style={{
                          border: "1px solid #979797",
                          paddingRight: "10px",
                          fontWeight: 400,
                          textAlign: "right",
                          fontSize: 10,
                        }}
                      >
                        {project.wordCount}
                      </td>
                    </tr>
                  ))}
                  <tr
                    style={{
                      backgroundColor: "#F6F6F6",
                      border: "1px solid #979797",
                    }}
                  >
                    <td style={{ paddingLeft: "5px", fontSize: 10 }}>
                      Selected projects word count total:
                    </td>
                    <td></td>
                    <td></td>
                    <td
                      style={{
                        paddingRight: "10px",
                        textAlign: "right",
                        fontSize: 10,
                      }}
                    >
                      {selectedWordCount.toLocaleString()}
                    </td>
                  </tr>
                </tbody>
              </table>

              <PDFChart
                dates={dates}
                data={data}
                topProjects={topProjects}
                selectedProjects={selectedProjects}
              />

              <Typography
                style={{
                  fontSize: 10,
                  fontFamily: "Helvetica Neue",
                }}
              >
                {format(dates.startDate, "d MMM yyyy")}&nbsp;-&nbsp;
                {format(dates.endDate, "d MMM yyyy")}
              </Typography>
            </Grid>
            <Grid item>
              <div
                style={{
                  width: "100%",
                  height: 1,
                  backgroundColor: "#979797",
                  marginBottom: 5,
                }}
              />
              <Grid container justify="space-between">
                <Grid item>
                  <Typography
                    style={{
                      fontSize: 10,
                      fontFamily: "Helvetica Neue",
                    }}
                  >
                    Platform - CSP
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    style={{
                      fontSize: 10,
                      fontFamily: "Helvetica Neue",
                    }}
                  >
                    COMMERCIAL IN CONFIDENCE
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    style={{
                      fontSize: 10,
                      fontFamily: "Helvetica Neue",
                    }}
                  >
                    Page 1 of 1
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </div>
    </Page>
  )
}

export default SinglePage
