import React from "react"
import { withContext } from "../../../../Utils/context"
import axios from "axios"
import { format } from "date-fns"
import DatePicker from "../../../layout/DatePicker"
import { TruncateAsRequired } from "../../../layout/TruncateAsRequired"
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  CircularProgress,
  Typography,
  Grid,
  TablePagination,
} from "@material-ui/core"
import { Link } from "react-router-dom"

const REPORTING_API = process.env.REACT_APP_REPORTING_URL

const nowUTC = date => {
  let now = date ? new Date(date) : new Date()
  now.setTime(now.getTime() + now.getTimezoneOffset() * 60 * 1000)
  return now
}

function HighUsage() {
  const [didMount, setDidMount] = React.useState(false)
  const [loading, setLoading] = React.useState(true)
  const [error, setError] = React.useState(false)
  const [dates, setDates] = React.useState({
    startDate: nowUTC().setDate(nowUTC().getDate() - 27),
    endDate: nowUTC(),
  })
  const [totalRows, setTotalRows] = React.useState(0)
  const [pagination, setPagination] = React.useState({
    limit: 10,
    offset: 0,
  })
  const [data, setData] = React.useState([])

  React.useEffect(() => {
    setDidMount(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (!didMount) {
      setTimeout(() => loadData(), 1000)
    } else {
      loadData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination, dates])

  const loadData = () => {
    setLoading(true)
    setError(false)
    axios({
      method: "POST",
      url: `${REPORTING_API}/t100/organisation/top/`,
      data: {
        limit: pagination.limit,
        offset: pagination.offset,
        startDate: format(dates.startDate, "yyyy-MM-dd"),
        endDate: format(dates.endDate, "yyyy-MM-dd"),
      },
    })
      .then(response => {
        setLoading(false)
        setData(response.data.result.organisations)
        setTotalRows(response.data.result.totalTableCount)
      })
      .catch(error => {
        setLoading(false)
        setError(true)
      })
  }

  const handleDatesChange = dates => {
    setDates(dates)
  }

  const handleChangePage = (_event, newPage) => {
    setPagination({
      limit: pagination.limit,
      offset: newPage * pagination.limit,
    })
  }

  const handleChangeRowsPerPage = ({ target: { value } }) => {
    if (!error && !loading) {
      setPagination({
        limit: value,
        offset: 0,
      })
    }
  }

  return (
    <React.Fragment>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Company name</TableCell>
            <TableCell align="right">Word count</TableCell>
            <TableCell align="right">Usage cap</TableCell>
            <TableCell align="right">Contract type</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <TableRow style={{ height: 33 * 10 }}>
              <TableCell padding="checkbox" align="center" colSpan={4}>
                <CircularProgress />
              </TableCell>
            </TableRow>
          ) : (
            <React.Fragment>
              {error ? (
                <TableRow style={{ height: 33 * 10 }}>
                  <TableCell padding="checkbox" align="center" colSpan={4}>
                    <Typography variant="body1">
                      Sorry an error occurred fetching
                      <br />
                      data for the company report
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : (
                <React.Fragment>
                  {data.length === 0 ? (
                    <TableRow style={{ height: 33 * 10 }}>
                      <TableCell padding="checkbox" align="center" colSpan={4}>
                        <Typography variant="body1">
                          No company data found for date range
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ) : (
                    <React.Fragment>
                      {data.map(organisation => (
                        <TableRow
                          key={organisation.organisationId}
                          className="slowfadein"
                        >
                          <TableCell>
                            <Link
                              to={"/company/" + organisation.organisationId}
                            >
                              <TruncateAsRequired maxWidth="50vw">
                                {organisation.displayName}
                              </TruncateAsRequired>
                            </Link>
                          </TableCell>
                          <TableCell align="right">
                            {organisation.wordCount.toLocaleString()}
                          </TableCell>
                          <TableCell align="right">-</TableCell>
                          <TableCell align="right">-</TableCell>
                        </TableRow>
                      ))}
                      {data.length < 10 && (
                        <TableRow style={{ height: 33 * (10 - data.length) }}>
                          <TableCell colSpan={4} />
                        </TableRow>
                      )}
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </TableBody>
      </Table>
      <Grid container justify="space-between" alignItems="center">
        <Grid item>
          <DatePicker dates={dates} onDatesChange={handleDatesChange} />
        </Grid>
        <Grid item>
          <TablePagination
            rowsPerPageOptions={[10, 20, 30]}
            component="div"
            count={totalRows}
            rowsPerPage={pagination.limit}
            page={
              pagination.offset !== 0 ? pagination.offset / pagination.limit : 0
            }
            backIconButtonProps={{
              "aria-label": "Previous Page",
            }}
            nextIconButtonProps={{
              "aria-label": "Next Page",
            }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default withContext(HighUsage)
