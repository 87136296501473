import React, { useState, useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles"

import { withContext } from "../../../../Utils/context"
import { maxCharLength } from "../../../../Utils/helpers"
import axios from "axios"
import format from "date-fns/format"
import NoData from "../../../layout/NoData"

import {
  Button,
  CircularProgress,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from "@material-ui/core"
import { DateRange as DateRangeIcon } from "@material-ui/icons"

const PARTNER_API = process.env.REACT_APP_PARTNER_URL

const nowUTC = date => {
  let now = date ? new Date(date) : new Date()
  now.setTime(now.getTime() + now.getTimezoneOffset() * 60 * 1000)
  return now
}

const checkName = searchTerm =>
  axios
    .post(PARTNER_API, {
      limit: 1,
      offset: 0,
      sortBy: { key: "displayName", value: "asc" },
      searchBy: searchTerm,
    })
    .catch(console.error)
    .then(res => res?.data?.records)
    .then(companies =>
      companies?.some(
        c => c.displayName.toLowerCase() === searchTerm.toLowerCase(),
      ),
    )

const useDebounce = (searchTerm, delay = 1000) => {
  const [debouncedVal, setDebouncedVal] = React.useState(searchTerm)

  React.useEffect(() => {
    const timerRef = setTimeout(() => setDebouncedVal(searchTerm), delay)

    return () => clearTimeout(timerRef)
  }, [searchTerm, delay])

  return debouncedVal
}

const UpdateForm = props => {
  const classes = useStyles()

  const [partner, setPartner] = useState(props.partner)
  const [loading, setLoading] = useState(false)
  const [checkingDuplicate, setCheckingDuplicate] = React.useState(false)
  const [displayNameMinLength, setDisplayNameMinLength] = React.useState(false)
  const [displayNameMaxLength, setDisplayNameMaxLength] = React.useState(false)
  const [displayNameMatch, setDisplayNameMatch] = React.useState(false)

  const debouncedDisplayName = useDebounce(partner.displayName, 500)

  useEffect(() => {
    const updateDuplicateStatus = () => {
      if (!debouncedDisplayName) {
        setCheckingDuplicate(false)
        return
      }
      // reset all error msg state
      setDisplayNameMinLength(false)
      setDisplayNameMaxLength(false)
      setDisplayNameMatch(false)

      if (debouncedDisplayName.length > maxCharLength) {
        setDisplayNameMaxLength(true)
        return
      }

      if (debouncedDisplayName.length < 2) {
        setDisplayNameMinLength(true)
        return
      }

      if (debouncedDisplayName.trim() !== props.partner.displayName) {
        checkName(debouncedDisplayName)
          .then(setDisplayNameMatch)
          .then(_ => setCheckingDuplicate(false))
      } else {
        setCheckingDuplicate(false)
        setDisplayNameMatch(false)
      }
    }

    updateDuplicateStatus()
  }, [debouncedDisplayName, props.partner.displayName])

  const handleChange = ({ target: { value } }) => {
    setCheckingDuplicate(true)
    setPartner({
      ...partner,
      displayName: value,
    })
  }

  const handleUpdate = () => {
    setLoading(true)
    axios
      .patch(`${PARTNER_API}/${partner.id}`, {
        displayName: debouncedDisplayName.trim(),
      })
      .then(_ => {
        // TODO: Add error handling on response.
        setLoading(false)
        props.onPartnerUpdate(partner)
        props.createSuccess("Partner details successfully updated!")
      })
      .catch(_error => setLoading(false))
  }
  const updateDisabled =
    loading ||
    !partner.displayName ||
    props.partner.displayName === partner.displayName.trim() ||
    displayNameMinLength ||
    displayNameMaxLength ||
    checkingDuplicate ||
    displayNameMatch

  const displayNameErrorMsg = displayNameMatch
    ? "A partner with this name already exists."
    : displayNameMinLength
    ? "Partner name cannot have less than 2 characters."
    : displayNameMaxLength
    ? `Partner name cannot have more than ${maxCharLength} characters.`
    : false

  return (
    <Grid
      container
      direction="column"
      justify="space-between"
      className={classes.root}
    >
      <Grid item>
        <TextField
          label="Partner name"
          error={
            !!displayNameMatch ||
            !!displayNameMinLength ||
            !!displayNameMaxLength
          }
          helperText={displayNameErrorMsg}
          value={partner.displayName}
          name="displayName"
          onChange={handleChange}
          InputLabelProps={{ shrink: true }}
          fullWidth
          required
          disabled={loading}
          style={{ maxWidth: "50vw" }}
        />

        <InputLabel
          shrink
          htmlFor="created-date-input"
          className={classes.rawLabel}
        >
          Created on
        </InputLabel>
        {props.partner.createdDate ? (
          <Grid
            container
            spacing={1}
            className={classes.lightGrey}
            alignContent="center"
          >
            <Grid item>
              <DateRangeIcon fontSize="small" style={{ marginTop: 2 }} />
            </Grid>
            <Grid item>
              <Typography>
                {format(nowUTC(props.partner.createdDate), "MMMM dd, yyyy")}
              </Typography>
            </Grid>
          </Grid>
        ) : (
          <NoData />
        )}
      </Grid>
      {/* THIS IS TO FIX SAFARI BUG */}
      <Grid
        item
        style={{
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "flex-end",
        }}
      >
        <Grid container spacing={2}>
          {props.partner.displayName !== partner.displayName && (
            <Grid item>
              <Button
                onClick={() => setPartner(props.partner)}
                variant="contained"
                size="small"
              >
                Cancel
              </Button>
            </Grid>
          )}
          <Grid item>
            <div className={classes.updateWrapper}>
              <Button
                color="primary"
                variant="contained"
                size="small"
                onClick={handleUpdate}
                disabled={updateDisabled}
              >
                Update
              </Button>
              {loading && (
                <CircularProgress
                  size={24}
                  className={classes.updateProgress}
                />
              )}
            </div>
          </Grid>
          {partner.accountStatus === "ACTIVE" ? (
            <>
              <Grid item>
                <Button variant="contained" size="small" disabled={true}>
                  Suspend
                </Button>
              </Grid>
              <Grid item>
                <Button variant="contained" size="small" disabled={true}>
                  Disable
                </Button>
              </Grid>
            </>
          ) : (
            <Grid item>
              <Button variant="contained" size="small" disabled={true}>
                Enable
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    height: 400,
    padding: theme.spacing(4),
  },
  rawLabel: {
    marginTop: theme.spacing(4),
  },
  partnerManagementGrid: {
    cursor: "pointer",
  },
  lightGrey: {
    color: "rgba(0, 0, 0, 0.60)",
  },
  updateWrapper: {
    position: "relative",
  },
  updateProgress: {
    color: theme.palette.success.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}))

export default withContext(UpdateForm)
