import React, { useState, useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles"
import classNames from "classnames"
import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Typography,
  Popover,
  IconButton,
  AppBar,
  Toolbar,
  Tooltip,
} from "@material-ui/core"
import { Close as CloseIcon } from "@material-ui/icons"

import axios from "axios"
import { format } from "date-fns"

import { downloadFile, capitalize } from "../../../../Utils/helpers"
import TruncateAsRequired from "../../../layout/TruncateAsRequired"
import DatePicker from "../../../layout/DatePicker"

import PdfIcon from "../../../assets/pictureAsPdf.svg"
import CsvIcon from "../../../assets/pictureAsCsv.svg"

import ProjectsTable from "./tables/ProjectsTable"
import ProjectsWordCountChart from "./charts/ProjectsWordCount"
import PrintButton from "./pdf/PrintButton"
import SinglePage from "./pdf/SinglePage"

const REPORTING_API = process.env.REACT_APP_REPORTING_URL

const nowUTC = date => {
  let now = date ? new Date(date) : new Date()
  now.setTime(now.getTime() + now.getTimezoneOffset() * 60 * 1000)
  return now
}

const usageTypeDispalyName = {
  excel: "Excel",
  powerbi: "Power BI",
  API: "API",
  tibco: "TIBCO",
}

const Reports = ({ organisation }) => {
  const classes = useStyles()

  const [dates, setDates] = useState({
    startDate: nowUTC().setDate(nowUTC().getDate() - 27),
    endDate: nowUTC(),
  })
  const [totalWordCount, setTotalWordCount] = useState(0)
  const [selectedProjects, setSelectedProjects] = useState([])
  const [selectedWordCount, setSelectedWordCount] = useState(0)
  const [data, setData] = useState([])
  const [projectNames, setProjectNames] = useState([])
  const [anchorEl, setAnchorEl] = useState(null)
  const [anchorElCsv, setAnchorElCsv] = useState(null)
  const [loading, setLoading] = useState(true)
  const [sortBy, setSortBy] = useState({
    key: "wordCount",
    value: "desc",
  })

  const [interval, setInterval] = useState("days")
  const [topProjects, setTopProjects] = useState([])
  const [selectAll, setSelectAll] = useState(false)
  const [offset, setOffset] = useState(0)
  const [tableData, setTableData] = useState([])
  const [projectLoading, setProjectLoading] = useState(true)

  const projectPaper = classNames(classes.projectFixedHeight, "slowfadein")

  const refetchProjects = (projects = selectedProjects) => {
    // avoid api call if there is no projects selected
    if (projects.length < 1) return
    let projectsSetIdUsageType = []

    const projectsSelected = projects.map(x => {
      return { projectSetId: x.projectSetId, usageType: x.usageType }
    })
    projectsSetIdUsageType = [{ projectSetIds: projectsSelected }]

    axios
      .post(`${REPORTING_API}/t110/organisation/${organisation.id}/project`, {
        sortBy,
        offset: 0,
        selectAll: true,
        startDate: format(dates.startDate, "yyyy-MM-dd"),
        endDate: format(dates.endDate, "yyyy-MM-dd"),
        filterBy: projectsSetIdUsageType,
      })
      .then(response => {
        const { records } = response.data.results
        setSelectedProjects(records)
        setSelectedWordCount(records.reduce((a, x) => a + x.wordCount, 0))
      })
      .catch(console.error)
  }

  const selectedIds = selectedProjects.map(x => x.projectSetId)
  const idsString = selectedIds.join("")
  useEffect(() => {
    if (selectedProjects.length === 0) {
      setSelectedWordCount(0)
      setData([])
      setProjectNames([])
      return
    }

    const payload = {
      startDate: format(dates.startDate, "yyyy-MM-dd"),
      endDate: format(dates.endDate, "yyyy-MM-dd"),
    }

    if (selectAll) payload.selectAll = true
    const projects = selectedProjects.map(x => {
      return { projectSetId: x.projectSetId, usageType: x.usageType }
    })
    payload.projects =
      selectedProjects.length <= 5
        ? projects
        : selectedProjects
            .sort((left, right) => right.wordCount - left.wordCount)
            .map(x => {
              return { projectSetId: x.projectSetId, usageType: x.usageType }
            })
    // An override to limit the report for number of project
    payload.projectResultsLimit = payload.projects.length

    const url = `${REPORTING_API}/c100/organisation/${organisation.id}/project/wordcount/`
    axios
      .post(url, payload)
      .then(response => {
        setLoading(false)

        const results = response?.data?.results
        if (!results) throw new Error("fetching data errorred")

        const { usageByTime, wordCountTotal, interval, topProjects } = results

        setTopProjects(topProjects)
        setInterval(interval)
        setData(usageByTime)
        setSelectedWordCount(wordCountTotal)
        setProjectNames(projectNames)
      })
      .catch(console.error)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dates.startDate, dates.endDate, organisation.id, idsString])

  // TODO: avoid making this call just to calculate
  // total wordcount for the selected period.
  useEffect(() => {
    axios
      .post(
        `${REPORTING_API}/c150/organisation/${organisation.id}/wordcount/`,
        {
          startDate: format(dates.startDate, "yyyy-MM-dd"),
          endDate: format(dates.endDate, "yyyy-MM-dd"),
        },
      )
      .then(({ data: { results } }) => {
        const wc = results.reduce((acc, x) => acc + x.wordCount, 0)
        setTotalWordCount(wc)
      })
  }, [dates.startDate, dates.endDate, organisation.id])

  const handleDatesChange = dates => {
    // NOTE: This is to prevent the date range and data format error on the chart
    setData([])
    setDates(dates)
    // Set project loading when date changes
    setProjectLoading(true)
    setOffset(0)
  }

  const onSelectProject = prj => {
    const alreadySelected = selectedProjects.some(
      s => s.projectSetId === prj.projectSetId && s.usageType === prj.usageType,
    )
    const newSet = alreadySelected
      ? selectedProjects.filter(
          s =>
            s.projectSetId !== prj.projectSetId ||
            s.usageType !== prj.usageType,
        )
      : selectedProjects.concat(prj)

    setSelectedProjects(newSet)
  }

  const open = Boolean(anchorEl)
  const openCsv = Boolean(anchorElCsv)
  const idCsv = openCsv ? "simple-popover-csv" : undefined
  const idPdf = open ? "simple-popover-pdf" : undefined

  const handleClose = () => setAnchorEl(null)
  const handleCloseCsv = () => setAnchorElCsv(null)
  const handlePdf = event => {
    setAnchorEl(event.currentTarget)
    setLoading(true)
    refetchProjects(selectedProjects)
  }

  const handleOpenCsv = event => {
    if (interval === "months") {
      setAnchorElCsv(event.currentTarget)
    } else {
      handleCsv()
    }
  }

  // Download to CSV
  const handleCsv = event => {
    setAnchorElCsv(null)
    const header =
      "Period," +
      selectedProjects
        .map(x => {
          const usageType = usageTypeDispalyName[x.usageType]
            ? usageTypeDispalyName[x.usageType]
            : capitalize(x.usageType)

          return x.projectName + " (" + usageType + ")"
        })
        .join(",")
    const rows = data.map(
      row =>
        row.date +
        "," +
        selectedProjects
          .map(x => row[x.projectSetId + "-" + x.usageType])
          .join(","),
    )
    const totalsRow =
      "\nTotal Word Count," + selectedProjects.map(x => x.wordCount).join(",")

    const fullCsv = header + "\n" + rows.join("\n") + totalsRow

    const start = format(dates.startDate, "yyyyddMM")
    const end = format(dates.endDate, "yyyyddMM")
    const filename = `Word_Count_Report_${organisation.displayName}_${start}-${end}.csv`

    downloadFile(filename, fullCsv)
  }

  return (
    <>
      <Paper className={classes.paper}>
        <Grid container spacing={3}>
          <Grid item xs={12} className="slowfadein">
            <Grid container justify="space-between">
              <Grid item>
                <Typography variant="body2" component="div">
                  Company:{" "}
                  <TruncateAsRequired
                    variant="subtitle2"
                    title={organisation.displayName}
                  >
                    {organisation.displayName}
                  </TruncateAsRequired>
                </Typography>
                <Box m={0.4} />
                <Typography variant="body2">
                  Date Range (UTC):
                  <DatePicker
                    disabled={projectLoading}
                    dates={dates}
                    onDatesChange={handleDatesChange}
                    placement="bottom-start"
                    style={{
                      fontSize: "0.875rem",
                      fontWeight: "500",
                      lineHeight: "1.57",
                      letterSpacing: "0.00714em",
                      color: "rgba(0, 0, 0, 0.87)",
                      marginBottom: "3px",
                    }}
                  />
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2">
                  Total Word Count:{" "}
                  <Typography variant="subtitle2" component="span">
                    {totalWordCount.toLocaleString()}
                  </Typography>
                </Typography>
                <Box m={1} />
                <Typography variant="body2">
                  Word Count for Selected Projects:{" "}
                  <Typography variant="subtitle2" component="span">
                    {selectedWordCount.toLocaleString()}
                  </Typography>
                </Typography>
                <Box m={1} />
                <Typography variant="body2">
                  Projects Selected:{" "}
                  <Typography variant="subtitle2" component="span">
                    {selectedProjects.length}
                  </Typography>
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2">
                  Export to:&nbsp;
                  <Tooltip title="Export to CSV" placement="top">
                    <IconButton onClick={handleOpenCsv} style={{ padding: 4 }}>
                      <img src={CsvIcon} width={22} height={22} alt="csv" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Create PDF report" placement="top">
                    <IconButton onClick={handlePdf} style={{ padding: 4 }}>
                      <img src={PdfIcon} width={22} height={22} alt="csv" />
                    </IconButton>
                  </Tooltip>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <ProjectsTable
              changeSortOrder={setSortBy}
              dates={dates}
              onDatesChange={handleDatesChange}
              onSelectProject={onSelectProject}
              organisationId={organisation.id}
              selectAll={selectAll}
              selectedProjects={selectedProjects}
              setSelectAll={setSelectAll}
              offset={offset}
              setOffset={setOffset}
              setSelectedProjects={setSelectedProjects}
              setTableData={setTableData}
              refetchProjects={refetchProjects}
              tableData={tableData}
              projectLoading={projectLoading}
              setProjectLoading={setProjectLoading}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <Typography variant="h6" className={classes.subtitle}>
              Projects Word Count
            </Typography>
            <Box m={2.5} />
            <Paper className={projectPaper} id="wordcount-chart" elevation={3}>
              <ProjectsWordCountChart
                interval={interval}
                organisationId={organisation.id}
                dates={dates}
                data={data}
                topProjects={topProjects}
                selectedProjects={selectedProjects.slice(0, 5)}
              />
            </Paper>
          </Grid>
        </Grid>
      </Paper>
      {/* Popover for PDF export */}
      <Popover
        id={idCsv}
        anchorEl={anchorElCsv}
        open={openCsv}
        onClose={handleCloseCsv}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Paper style={{ width: "200px", padding: "5px" }}>
          <Typography
            variant="subtitle2"
            component="h6"
            style={{ padding: 10 }}
          >
            The selected date range exceeds 60 days, so this report will show
            totals by month. For daily word counts, create reports that are 60
            days or less.
          </Typography>
          <Divider className={classes.selectionDivider} />
          <Grid container justify="space-between">
            <Grid item>
              <Button size="small" onClick={handleCloseCsv}>
                cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                className={classes.buttonPadding}
                color="primary"
                size="small"
                disableElevation
                onClick={handleCsv}
              >
                continue
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Popover>
      <Popover
        id={idPdf}
        open={open}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={{ top: 100, left: 400 }}
      >
        {/* Size of a4 document */}
        <div style={{ width: "210mm", minHeight: "297mm" }}>
          <AppBar position="static" style={{ backgroundColor: "#fff" }}>
            <Toolbar>
              <Grid container justify="space-between">
                <Grid item>
                  <PrintButton
                    displayName={organisation.displayName}
                    dates={dates}
                    totalWordCount={totalWordCount.toLocaleString()}
                    selectedProjectsCount={selectedProjects.length.toLocaleString()}
                    topProjects={selectedProjects.slice(0, 5)}
                    showTotal={data?.[0]?.hasOwnProperty("totalWordCount")}
                    onClose={handleClose}
                    loading={!selectedProjects.length >= 1}
                  />
                </Grid>
                <Grid item>
                  <IconButton
                    style={{ marginRight: "25mm" }}
                    onClick={handleClose}
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
          <SinglePage
            displayName={organisation.displayName}
            projects={selectedProjects}
            projectNames={projectNames}
            totalWordCount={totalWordCount.toLocaleString()}
            selectedWordCount={selectedWordCount.toLocaleString()}
            loading={loading && selectedProjects.length !== 0}
            interval={interval}
            dates={dates}
            data={data}
            topProjects={topProjects}
            selectedProjects={selectedProjects.slice(0, 5)}
          />
        </div>
      </Popover>
    </>
  )
}

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(4),
  },
  subtitle: {
    fontWeight: 300,
    color: "#4a4a4a",
    fontSize: "16px",
    paddingTop: "6px",
  },
  projectMinFixedHeight: {
    minHeight: 480,
  },
  projectFixedHeight: {
    height: 535,
  },
  paddingBottom: {
    padding: 0,
    paddingBottom: 40,
  },
}))

export default Reports
