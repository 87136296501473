import React from "react"
import jsPDF from "jspdf"
import "jspdf-autotable"
import { Button } from "@material-ui/core"
import { format } from "date-fns"

const PrintButton = ({
  displayName,
  dates,
  totalWordCount,
  selectedProjectsCount,
  onClose,
  loading,
}) => (
  <div className="tc mb4 mt2">
    <Button
      disabled={loading}
      variant="contained"
      color="primary"
      style={{ marginLeft: "8mm" }}
      onClick={() => {
        let arriaLogo = document.getElementById("arria-logo")
        let svgElem = document
          .getElementById("pdfChart")
          .querySelectorAll("svg.recharts-surface")[0]

        const { width: svgWidth, height: svgHeight } = svgElem.getBBox()

        const svgString = new XMLSerializer().serializeToString(svgElem)

        const svg64 = btoa(svgString)
        const b64Start = "data:image/svg+xml;base64,"
        const imageB64 = b64Start + svg64

        const tempImg = new Image()

        const pdf = new jsPDF()

        const finalY = pdf.previousAutoTable.finalY || 10
        const totalPagesExp = "totalPages"
        const leftmargin = 14
        // Header
        pdf.setFontSize(20)
        pdf.setTextColor(40)
        if (arriaLogo) {
          pdf.addImage(arriaLogo, "PNG", leftmargin + 157, 7, 25, 25)
        }
        pdf.setFontSize(18)
        pdf.setFontStyle("normal")
        pdf.text("Word Count Report", leftmargin, 20)
        pdf.setFontSize(10)
        pdf.text("For:", leftmargin, 40)
        pdf.setFontSize(10)
        pdf.setFontStyle("bold")
        pdf.text(displayName, leftmargin + 8, 40)

        pdf.setFontStyle("normal")
        pdf.text("Report Date:", leftmargin, 45)
        pdf.setFontSize(10)
        pdf.setFontStyle("bold")
        pdf.text(format(dates.endDate, "d MMMM yyyy"), leftmargin + 21, 45)
        const usageDate =
          format(dates.startDate, "d MMM yyyy") +
          " - " +
          format(dates.endDate, "d MMM yyyy")
        pdf.setFontStyle("normal")
        pdf.text("Usage Period:", leftmargin, 50)
        pdf.setFontSize(10)
        pdf.setFontStyle("bold")
        pdf.text(usageDate, leftmargin + 24, 50)

        pdf.setFontStyle("normal")
        pdf.text("Word count for all projects:", leftmargin, 55)
        pdf.setFontSize(10)
        pdf.setFontStyle("bold")
        pdf.text(totalWordCount, leftmargin + 44, 55)

        pdf.setFontStyle("normal")
        pdf.text("Projects Selected:", leftmargin, 60)
        pdf.setFontSize(10)
        pdf.setFontStyle("bold")
        pdf.text(selectedProjectsCount, leftmargin + 30, 60)

        pdf.setFontSize(14)
        pdf.setFontStyle("normal")
        pdf.text("Selected Projects:", leftmargin, 72)
        // table starts
        pdf.autoTable({
          html: "#project-table",
          startY: finalY + 65,
          useCss: true,
          didDrawCell: function (data) {
            // draw indicator color box
            if (data.column.index === 3 && data.cell.section === "body") {
              const rawTd = data.cell.raw
              var div = rawTd.getElementsByTagName("div")[0]
              if (div && div.style) {
                const fillColor = div.style.backgroundColor
                const tempCanvas = document.createElement("canvas")
                const tempContext = tempCanvas.getContext("2d")
                tempCanvas.width = 2
                tempCanvas.height = 2
                tempContext.fillStyle = fillColor
                tempContext.fillRect(0, 0, tempCanvas.width, tempCanvas.height)
                const convertedImg = tempCanvas.toDataURL("image/png", 1)
                const dimension =
                  data.cell.height - data.cell.padding("vertical")
                pdf.addImage(
                  convertedImg,
                  "PNG",
                  data.cell.x + 2,
                  data.cell.y + 2,
                  dimension,
                  dimension,
                )
              }
            }
          },
          didDrawPage: function (data) {
            // Footer
            let footerTableMsg = "Page " + pdf.internal.getNumberOfPages()
            if (typeof pdf.putTotalPages === "function") {
              footerTableMsg = footerTableMsg + " of " + totalPagesExp
            }
            pdf.setFontSize(10)
            const pageSize = pdf.internal.pageSize
            const pageHeight = pageSize.height
              ? pageSize.height
              : pageSize.getHeight()
            pdf.setLineWidth(0.25)
            pdf.setDrawColor(0, 0, 0)
            pdf.line(10, pageHeight - 14, pageSize.width - 10, pageHeight - 14)
            pdf.text("Platform - CSP", 12, pageHeight - 10)
            pdf.text("COMMERCIAL IN CONFIDENCE", 81, pageHeight - 10)
            pdf.text(
              footerTableMsg,
              data.settings.margin.left + 166,
              pageHeight - 10,
            )
          },
          margin: { top: 20, bottom: 20 },
        })
        // chart image creation and adding to pdf
        tempImg.src = imageB64
        tempImg.onload = function () {
          const tempCanvas = document.createElement("canvas")
          const tempContext = tempCanvas.getContext("2d")
          const imgWidth = 180

          let scaleRatio = window.devicePixelRatio === 2 ? 0.5 : 1
          tempContext.scale(scaleRatio, scaleRatio)
          tempCanvas.width = svgWidth + 100
          tempCanvas.height = svgHeight + 100
          tempContext.drawImage(tempImg, 20, 0)
          const convertedImg = tempCanvas.toDataURL("image/png", 1)
          const imgHeight = (tempCanvas.height * imgWidth) / tempCanvas.width
          const onCurrentPage =
            pdf.internal.pageSize.getHeight() - pdf.previousAutoTable.finalY >=
            imgHeight + 20 // bottom margin
          if (onCurrentPage) {
            pdf.addImage(
              convertedImg,
              "PNG",
              14,
              pdf.previousAutoTable.finalY + 10,
            )
          } else {
            pdf.addPage()
            pdf.addImage(convertedImg, "PNG", 14, 10)
            let footerImgMsg = "Page " + pdf.internal.getNumberOfPages()
            if (typeof pdf.putTotalPages === "function") {
              footerImgMsg = footerImgMsg + " of " + totalPagesExp
            }
            pdf.setFontSize(10)
            const pageSize = pdf.internal.pageSize
            const pageHeight = pageSize.height
              ? pageSize.height
              : pageSize.getHeight()
            pdf.setLineWidth(0.25)
            pdf.setDrawColor(0, 0, 0)
            pdf.line(10, pageHeight - 14, pageSize.width - 10, pageHeight - 14)
            pdf.text("Platform - CSP", 12, pageHeight - 10)
            pdf.text("COMMERCIAL IN CONFIDENCE", 81, pageHeight - 10)
            pdf.text(footerImgMsg, 180, pageHeight - 10)
          }

          // Total page counter
          if (typeof pdf.putTotalPages === "function") {
            pdf.putTotalPages(totalPagesExp)
          }
          pdf.save(
            `Word_Count_Report_${displayName}_${format(
              dates.startDate,
              "yyyyMMdd",
            )}-${format(dates.endDate, "yyyyMMdd")}.pdf`,
          )
          onClose()
        }
      }}
    >
      Save PDF
    </Button>
  </div>
)

export default PrintButton
