import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { format, parse } from "date-fns"
import { Paper, Typography, Grid } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(1),
    width: 190,
  },
  timeLabel: {
    color: "#828282",
    fontSize: 12,
    marginBottom: theme.spacing(1),
  },
  itemContainer: {
    marginTop: theme.spacing(1),
  },
  itemTitle: {
    color: "#636363",
    fontSize: 14,
  },
  itemValue: {
    fontSize: 14,
  },
}))

const strokes = [
  "#358dff",
  "#f9ce32",
  "#48c462",
  "#ec4d6a",
  "#54dac5",
  "#8446de",
  "#f9cd32",
  "#36c1bf",
  "#e39663",
  "#7865ca",
  "#648ce5",
  "#443fc4",
  "#65a0e5",
  "#394276",
]

const nowUTC = date => {
  let now = date ? new Date(date) : new Date()
  now.setTime(now.getTime() + now.getTimezoneOffset() * 60 * 1000)
  return now
}

const ChartTooltip = ({ active, payload, label }) => {
  const classes = useStyles()

  if (!active || !label) {
    return null
  }

  const date = nowUTC(label)

  const timeLabel =
    label.length === 16
      ? format(parse(label, "yyyy-MM-dd HH:mm", nowUTC()), "h a, d MMM yyyy")
      : format(date, "EEE, d MMM yyyy")

  return (
    <Paper className={classes.paper}>
      <Typography variant="subtitle2" className={classes.timeLabel}>
        <span>{timeLabel} (UTC)</span>
      </Typography>
      {payload.map((item, i) => {
        let title = ""
        let color = "#fff"
        if (item.name === "wordCount") {
          title = "Word Count:"
          color = "#346df1"
        } else if (item.name === "userCount") {
          title = "User Count:"
          color = "#C51C1C"
        } else {
          title = item.name + ":"
          color = strokes[i % strokes.length]
        }

        return (
          <Grid
            key={item.name}
            container
            justify="space-between"
            className={classes.itemContainer}
          >
            <Grid item>
              <Typography variant="body2" className={classes.itemTitle}>
                {title}
              </Typography>
            </Grid>
            <Grid
              item
              variant="body2"
              className={classes.itemValue}
              style={{
                color: color,
              }}
            >
              <b>{item.value.toLocaleString()}</b>
            </Grid>
          </Grid>
        )
      })}
    </Paper>
  )
}

export default ChartTooltip
