import React, { useContext, useState, useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles"

import { withContext, AuthContext } from "../../../../Utils/context"
import { maxCharLength } from "../../../../Utils/helpers"
import axios from "axios"
import format from "date-fns/format"
import { Link } from "react-router-dom"
import NoData from "../../../layout/NoData"
import TruncateAsRequired from "../../../layout/TruncateAsRequired"
import DeleteIcon from "@material-ui/icons/Delete"
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputLabel,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core"
import { DateRange as DateRangeIcon } from "@material-ui/icons"

import PartnerSelect from "./PartnerSelect"

const ORG_API = process.env.REACT_APP_ORG_URL
const PARTNER_URL = process.env.REACT_APP_PARTNER_URL

const nowUTC = date => {
  let now = date ? new Date(date) : new Date()
  now.setTime(now.getTime() + now.getTimezoneOffset() * 60 * 1000)
  return now
}

const checkName = searchTerm =>
  axios
    .post(ORG_API, {
      limit: 1,
      offset: 0,
      sortBy: { key: "displayName", value: "asc" },
      searchBy: searchTerm,
    })
    .catch(console.error)
    .then(res => res?.data?.records)
    .then(companies =>
      companies?.some(
        c => c.displayName.toLowerCase() === searchTerm.toLowerCase(),
      ),
    )

const useDebounce = (searchTerm, delay = 1000) => {
  const [debouncedVal, setDebouncedVal] = React.useState(searchTerm)

  React.useEffect(() => {
    const timerRef = setTimeout(() => setDebouncedVal(searchTerm), delay)

    return () => clearTimeout(timerRef)
  }, [searchTerm, delay])

  return debouncedVal
}

const DeleteCompany = ({ handler, selectedId }) => (
  <IconButton
    disabled={selectedId === null || selectedId === "null"}
    aria-label="Remove assigned company"
    onClick={handler}
    edge="end"
  >
    <DeleteIcon fontSize="small" />
  </IconButton>
)

const UpdateForm = props => {
  const classes = useStyles()

  const [organisation, setOrganisation] = useState(props.organisation)
  const [loading, setLoading] = useState(false)
  const [checkingDuplicate, setCheckingDuplicate] = React.useState(false)
  const [displayNameMinLength, setDisplayNameMinLength] = React.useState(false)
  const [displayNameMaxLength, setDisplayNameMaxLength] = React.useState(false)
  const [displayNameMatch, setDisplayNameMatch] = React.useState(false)
  const [deleteConfirm, setDeleteConfirm] = useState(false)
  const { copyToClipboard } = useContext(AuthContext)

  const debouncedDisplayName = useDebounce(organisation.displayName, 500)

  useEffect(() => {
    const updateDuplicateStatus = () => {
      if (!debouncedDisplayName) {
        setCheckingDuplicate(false)
        return
      }
      // reset all error msg state
      setDisplayNameMinLength(false)
      setDisplayNameMaxLength(false)
      setDisplayNameMatch(false)

      if (debouncedDisplayName.length > maxCharLength) {
        setDisplayNameMaxLength(true)
        return
      }

      if (debouncedDisplayName.length < 2) {
        setDisplayNameMinLength(true)
        return
      }

      if (debouncedDisplayName.trim() !== props.organisation.displayName) {
        checkName(debouncedDisplayName)
          .then(setDisplayNameMatch)
          .then(_ => setCheckingDuplicate(false))
      } else {
        setCheckingDuplicate(false)
        setDisplayNameMatch(false)
      }
    }

    updateDuplicateStatus()
  }, [debouncedDisplayName, props.organisation.displayName])

  const handleChange = ({ target: { value } }) => {
    setCheckingDuplicate(true)
    setOrganisation({
      ...organisation,
      displayName: value,
    })
  }

  const handleUpdate = () => {
    setLoading(true)
    axios({
      method: "patch",
      url: `${ORG_API}/${organisation.id}`,
      data: {
        displayName: debouncedDisplayName.trim(),
      },
    }).then(_ => {
      // TODO: Add error handling on response.
      setLoading(false)
      props.onOrganisationUpdate(organisation)
      props.createSuccess("Company details successfully updated!")
    })
  }

  const handlePartnerChange = partnerId => {
    setDeleteConfirm(false)
    setLoading(true)
    axios({
      method: "patch",
      url: `${ORG_API}/${organisation.id}`,
      data: {
        partnerId: partnerId,
      },
    }).then(_ => {
      // TODO: Add error handling on response.
      if (partnerId) {
        axios({
          method: "get",
          url: `${PARTNER_URL}/${partnerId}`,
        }).then(response => {
          // TODO: Add error handling on response.
          setOrganisation({
            ...organisation,
            partnerId: partnerId,
            partnerName: response.data.record.displayName,
          })
        })
      } else {
        setOrganisation({
          ...organisation,
          partnerId: partnerId,
          partnerName: null,
        })
      }
      setLoading(false)
      props.onOrganisationUpdate(organisation)
      const updateMsg = partnerId
        ? "Company's partner successfully updated!"
        : "Company's partner successfully removed!"
      props.createSuccess(updateMsg)
    })
  }

  const updateDisabled =
    loading ||
    !organisation.displayName ||
    props.organisation.displayName === organisation.displayName.trim() ||
    displayNameMinLength ||
    displayNameMaxLength ||
    checkingDuplicate ||
    displayNameMatch

  const displayNameErrorMsg = displayNameMatch
    ? "A company with this name already exists."
    : displayNameMinLength
    ? "Company name cannot have less than 2 characters."
    : displayNameMaxLength
    ? `Company name cannot have more than ${maxCharLength} characters.`
    : false

  const tenantId = props.organisation.tenantId
    ? props.organisation.tenantId
    : "No data available."

  return (
    <Grid
      container
      direction="column"
      justify="space-between"
      className={classes.root}
    >
      <Grid item>
        {props.organisation.displayName ? (
          <TextField
            label="Company name"
            error={
              !!displayNameMatch ||
              !!displayNameMinLength ||
              !!displayNameMaxLength
            }
            helperText={displayNameErrorMsg}
            value={organisation.displayName}
            name="displayName"
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            required
            disabled={loading}
          />
        ) : (
          <NoData />
        )}

        <Box m={4} />

        <InputLabel shrink htmlFor="partner-input" className={classes.rawLabel}>
          Partner managed
        </InputLabel>

        <Grid container alignItems="center">
          <Grid style={{ flex: 1 }} item>
            {organisation.partnerId && (
              <Link to={"/partner/" + organisation.partnerId}>
                <TruncateAsRequired
                  color="primary"
                  width="50%"
                  title={organisation.partnerName}
                >
                  {organisation.partnerName}
                </TruncateAsRequired>
              </Link>
            )}
          </Grid>
          <PartnerSelect
            loading={loading}
            selectedId={organisation.partnerId}
            onSave={handlePartnerChange}
          />
          <DeleteCompany
            selectedId={organisation.partnerId}
            handler={() => setDeleteConfirm(true)}
          />
        </Grid>
        {/* Delete Confirm Dialog */}
        <Dialog
          open={deleteConfirm}
          onClose={() => setDeleteConfirm(false)}
          aria-labelledby="disable-confirm-title"
          aria-describedby="disable-confirm-description"
        >
          <DialogTitle id="disable-confirm-title">
            Confirm Remove Partner
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Remove this company from
              <strong> {organisation.partnerName}</strong> ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDeleteConfirm(false)}>Cancel</Button>
            <Button
              onClick={() => handlePartnerChange(null)}
              color="primary"
              autoFocus
            >
              Remove
            </Button>
          </DialogActions>
        </Dialog>

        <InputLabel
          shrink
          htmlFor="created-date-input"
          className={classes.rawLabel}
        >
          Created on
        </InputLabel>
        {props.organisation.createdDate ? (
          <Grid
            container
            spacing={1}
            className={classes.lightGrey}
            alignContent="center"
          >
            <Grid item>
              <DateRangeIcon fontSize="small" style={{ marginTop: 2 }} />
            </Grid>
            <Grid item>
              <Typography>
                {format(
                  nowUTC(props.organisation.createdDate),
                  "MMMM dd, yyyy",
                )}
              </Typography>
            </Grid>
          </Grid>
        ) : (
          <NoData />
        )}
        <InputLabel shrink htmlFor="partner-input" className={classes.rawLabel}>
          Tenant ID
        </InputLabel>

        <Grid container>
          <Grid item>
            <Button
              style={{
                padding: "0px 5px",
                borderRadius: 5,
                fontSize: ".9em",
                color: "rgba(0, 0, 0, 0.54)",
                marginTop: "5px",
              }}
              variant="outlined"
              onClick={() => copyToClipboard(tenantId)}
            >
              Copy to Clipboard
            </Button>
          </Grid>
        </Grid>
      </Grid>
      {/* THIS IS TO FIX SAFARI BUG */}
      <Grid
        item
        style={{
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "flex-end",
        }}
      >
        <Grid container spacing={2}>
          {props.organisation.displayName !== organisation.displayName && (
            <Grid item>
              <Button
                onClick={() => setOrganisation(props.organisation)}
                variant="contained"
                size="small"
              >
                Cancel
              </Button>
            </Grid>
          )}
          <Grid item>
            <div className={classes.updateWrapper}>
              <Button
                color="primary"
                variant="contained"
                size="small"
                onClick={handleUpdate}
                disabled={updateDisabled}
              >
                Update
              </Button>
              {loading && (
                <CircularProgress
                  size={24}
                  className={classes.updateProgress}
                />
              )}
            </div>
          </Grid>
          {organisation.accountStatus === "ACTIVE" ? (
            <>
              <Grid item>
                <Button variant="contained" size="small" disabled={true}>
                  Suspend
                </Button>
              </Grid>
              <Grid item>
                <Button variant="contained" size="small" disabled={true}>
                  Disable
                </Button>
              </Grid>
            </>
          ) : (
            <Grid item>
              <Button variant="contained" size="small" disabled={true}>
                Enable
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    height: 480,
    padding: theme.spacing(4),
  },
  rawLabel: {
    marginTop: theme.spacing(4),
  },
  copyLink: {
    color: theme.palette.primary.main,
  },
  verifyBtn: {
    padding: 0,
    borderRadius: 5,
    fontSize: ".9em",
  },
  lightGrey: {
    color: "rgba(0, 0, 0, 0.60)",
  },
  updateWrapper: {
    position: "relative",
  },
  updateProgress: {
    color: theme.palette.success.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}))

export default withContext(UpdateForm)
