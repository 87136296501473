import format from "date-fns/format"
import addMonths from "date-fns/addMonths"
import addDays from "date-fns/addDays"
import addYears from "date-fns/addYears"

const patterns = {
  email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
}

export const isEmailValid = email => patterns.email.test(email.toLowerCase())

export const str2num = numString => {
  const parsed = Number(numString)
  return isNaN(parsed) ? null : parsed
}

export const curry = fn =>
  (function curried(initialArgs) {
    return initialArgs.length >= fn.length
      ? fn.apply(this, initialArgs)
      : (...args) => curried(initialArgs.concat(args))
  })([])

export const nowUTC = date => {
  let now = date ? new Date(date) : new Date()
  now.setTime(now.getTime() + now.getTimezoneOffset() * 60 * 1000)
  return now
}
// Company and Partner maximum character length
export const maxCharLength = 80

// Delays fn execution by passed delay in milliseconds
//
// usage: timer.start(callback, delay)
export const timer = {
  start(delayMillis, fn) {
    const existingId = this[fn]
    if (existingId) {
      return existingId
    }

    this[fn] = setTimeout(() => {
      fn()
      this[fn] = null
    }, delayMillis)

    return this[fn]
  },
}

export const parseJwt = token => {
  var base64Url = token.split(".")[1]
  var base64 = decodeURIComponent(
    atob(base64Url)
      .split("")
      .map(c => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
      .join(""),
  )

  return JSON.parse(base64)
}

export const downloadFile = (filename, data, mimeType = "text/csv") => {
  let link = document.createElement("a")
  link.setAttribute("href", `data:${mimeType};charset=utf-8,` + encodeURI(data))
  link.setAttribute("download", filename)
  link.click()
}

export const copyToClipboard = async textToCopy => {
  if (!navigator.clipboard) {
    // Clipboard API not available
    const copySuccess = await tryCopyWithSelection(textToCopy)
    return copySuccess
  }
  try {
    await navigator.clipboard.writeText(textToCopy)
    return true
  } catch (err) {
    console.error(err)
    return false
  }
}

export const capitalize = str => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

function tryCopyWithSelection(textToCopy) {
  const el = document.createElement("span")
  el.innerText = textToCopy
  document.body.appendChild(el)

  const range = document.createRange()
  range.selectNode(el)
  window.getSelection().addRange(range)

  // TODO:
  // Doesn't work the first time without the promise
  return new Promise((resolve, _reject) => {
    const copySuccess = document.execCommand("copy")
    window.getSelection().removeAllRanges()
    document.body.removeChild(el)

    resolve(!!copySuccess)
  })
}

export function enumerateDatesBetween(left, right, interval = "month") {
  const start = new Date(left)
  const end = new Date(right)

  const addFn = {
    months: addMonths,
    days: addDays,
    years: addYears,
  }[interval]

  let dates = []
  let nextDate = start
  const fmt = {
    days: "yyyy-MM-dd",
    months: "MMMM-yyyy",
    years: "yyyy",
  }[interval]

  while (nextDate < end) {
    dates.push(format(nextDate, fmt))
    nextDate = addFn(nextDate, 1)
  }
  return dates
}
