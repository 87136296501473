import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { PhoneNumberFormat, PhoneNumberUtil } from "google-libphonenumber"
import CountryCodeMap from "../../../../Utils/countryDataList.json"
import format from "date-fns/format"
import grey from "@material-ui/core/colors/grey"
import classNames from "classnames"

import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@material-ui/core"
import {
  Done as DoneIcon,
  FileCopyOutlined as CopyIcon,
  Phone as PhoneIcon,
} from "@material-ui/icons"
import { nowUTC } from "../../../../Utils/helpers"
import { withContext } from "../../../../Utils/context"
import NoData from "../../../layout/NoData"
import TruncateAsRequired from "../../../layout/TruncateAsRequired"

const Empty = React.Fragment

const UserProfile = ({ primary }) => {
  if (!primary || !primary.profile) return <NoData />
  const { profile, phoneNumber } = primary
  const { companyName, region, jobTitle, industry, department } = profile || {}

  // check for matching country from region code
  const countryFound = CountryCodeMap.find(it => it.value === region)?.label
  const country = countryFound ? countryFound : region

  const Field = ({ label, value = "" }) => (
    <Grid item xs={6} style={s.field}>
      <Typography style={s.label}>{label}</Typography>
      {value ? (
        <Box display="block">
          <TruncateAsRequired title={value} style={s.lightGrey}>
            {value}
          </TruncateAsRequired>
        </Box>
      ) : (
        <NoData />
      )}
    </Grid>
  )

  return (
    <Grid container style={s.profile}>
      <Grid container>
        <Typography component="span" variant="subtitle2">
          User Profile
        </Typography>
      </Grid>
      <Field label="Company" value={companyName} />
      <Field label="Country" value={country} />
      <Field label="Job title" value={jobTitle} />
      <Field label="Industry" value={industry} />
      <Field label="Department" value={department} />
      <Grid item xs={6}>
        <Typography style={s.label}>Phone Number</Typography>
        <Phone number={phoneNumber} region={region} />
      </Grid>
    </Grid>
  )
}

const UsageDate = ({ date, fmt }) => {
  if (!date) return <NoData />

  const usageDate = nowUTC(date)

  return (
    <Typography variant="subtitle2" style={s.value}>
      {format(usageDate, fmt)}
    </Typography>
  )
}

const Phone = ({ number, region }) => {
  if (!number) return <NoData />
  let callingPhoneNumber = ""
  let formatedPhoneNumber = ""

  // remove + sign from the phone number if there is any
  const phoneNumber = number.replace(/^[+]/, "")

  // if region is more than 2 character length then check
  // if it has a matching country code
  const regionCode =
    region.length === 2
      ? region
      : CountryCodeMap.find(it => it.value === region)?.value

  if (regionCode) {
    const phoneUtil = PhoneNumberUtil.getInstance()
    const parsedNumber = phoneUtil.parse(phoneNumber, regionCode)
    const validNumberFormat = phoneUtil.isValidNumberForRegion(
      parsedNumber,
      regionCode,
    )
    // if phone number is valid for the region then only format
    if (validNumberFormat) {
      formatedPhoneNumber = phoneUtil.format(
        parsedNumber,
        PhoneNumberFormat.INTERNATIONAL,
      )
      callingPhoneNumber = phoneUtil.format(
        parsedNumber,
        PhoneNumberFormat.E164,
      )
    }
  }

  return (
    <a
      href={callingPhoneNumber ? "tel:" + callingPhoneNumber : "tel:" + number}
      style={{ cursor: "pointer" }}
    >
      <Grid container spacing={1} alignItems="center">
        <Grid item>
          <Typography color="primary">
            {formatedPhoneNumber ? formatedPhoneNumber : number}
          </Typography>
        </Grid>
        <Grid item>
          <PhoneIcon color="primary" fontSize="small" />
        </Grid>
      </Grid>
    </a>
  )
}

const EmailAddresses = withContext(
  ({ primary, federated, copyToClipboard, verifyPrimary }) => {
    const emailVerified = x => x?.emailVerified?.toLowerCase() === "true"
    return (
      <Grid item xs={federated ? 12 : 6}>
        {primary && (
          <Grid item xs={12} style={s.row}>
            <Typography style={s.label}>Primary Email:</Typography>
            <Typography component="span" variant="subtitle2" style={s.value}>
              {emailVerified(primary) ? (
                <>
                  Verified <DoneIcon style={s.doneIcon} />
                </>
              ) : (
                <Button
                  style={s.verifyBtn}
                  variant="outlined"
                  onClick={verifyPrimary}
                >
                  Verify
                </Button>
              )}
            </Typography>
          </Grid>
        )}
        {federated && (
          <>
            <Grid item xs={12}>
              <Typography style={{ marginTop: "25px", fontSize: "14px" }}>
                This user uses a federated identity which is managed directly by
                their organisation.
              </Typography>
            </Grid>
          </>
        )}

        {primary && (
          <Grid item xs={12} style={{ ...s.row, ...s.userIdContainer }}>
            <Typography style={s.label}>Primary ID:</Typography>

            <IconButton
              aria-label="copy"
              size="small"
              title="Copy to Clipboard"
              onClick={() => copyToClipboard(primary.identityId)}
            >
              <CopyIcon style={{ fontSize: 15, marginLeft: "-25px" }} />
            </IconButton>
            <pre id="user-id-raw" style={{ marginLeft: "-5px" }}>
              {primary.identityId}
            </pre>
          </Grid>
        )}
      </Grid>
    )
  },
)

const Activity = ({ activity }) => {
  if (!activity) return <Empty />

  const { lastLoginDate = "", lastUsageDate = "" } = activity
  const fmt = {
    login: "d MMM yyyy, h:mm a",
    usage: "d MMM yyyy, h a",
  }
  const labelStyle = { ...s.label, minWidth: "40%" }

  return (
    <Grid item xs={6}>
      <Grid item xs={12} style={s.row}>
        <Typography style={labelStyle}>Last Sign In (UTC):</Typography>
        <UsageDate date={lastLoginDate} fmt={fmt.login} />
      </Grid>
      <Grid item xs={12} style={s.row}>
        <Typography style={labelStyle}>Last Usage (UTC):</Typography>
        <UsageDate date={lastUsageDate} fmt={fmt.usage} />
      </Grid>
    </Grid>
  )
}

const Identities = ({ primary, federated, activity, verifyPrimary }) => {
  const classes = useStyles()

  if (!primary && !federated) {
    return (
      <div className={classes.root}>
        <Typography style={s.label}>
          No identity was found for this user. Please contact Platform Support.
        </Typography>
      </div>
    )
  }

  const chartPaper = classNames(
    classes.paper,
    "slowfadein",
    classes.paddingBottom,
  )

  return (
    <Paper className={chartPaper}>
      <Grid container className={classes.root}>
        <EmailAddresses
          primary={primary}
          federated={federated}
          verifyPrimary={verifyPrimary}
        />
        {federated ? null : (
          <>
            <Activity activity={activity} /> <UserProfile primary={primary} />
          </>
        )}
      </Grid>
    </Paper>
  )
}

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    minHeight: 320,
    padding: `${spacing(2)}px ${spacing(3)}px`,
  },
  paper: {
    padding: spacing(4),
    height: "95%",
  },
  paddingBottom: {
    padding: 0,
    paddingBottom: 40,
  },
}))

const s = {
  lightGrey: {
    color: "rgba(0, 0, 0, 0.60)",
  },
  field: {
    minHeight: "1.5rem",
    margin: "8px 0",
  },
  profile: {
    marginTop: 16,
  },
  row: {
    display: "flex",
    alignItems: "center",
    flex: 1,
  },
  label: {
    minWidth: "35%",
    lineHeight: "1.8rem",
    display: "inline-block",
    fontSize: 12,
    color: grey[800],
  },
  verifyBtn: {
    padding: 0,
    borderRadius: 5,
    fontSize: ".9em",
  },
  doneIcon: {
    margin: 4,
    backgroundColor: "#85BE35",
    color: "#fff",
    borderRadius: "50%",
    fontSize: "inherit",
  },
  value: {
    flex: 1,
    fontSize: 12,
    display: "inline-flex",
    alignItems: "center",
  },
  userIdContainer: {
    overflow: "hidden",
  },
  userId: {
    backgroundColor: "rgba(0, 0, 0, 0.03)",
    borderRadius: 4,
    fontFamily: "Roboto Mono, monospace",
    fontSize: 12,
    margin: 0,
    overflow: "hidden",
    padding: 4,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
}

export default withContext(Identities)
