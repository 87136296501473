import {
  differenceInCalendarDays,
  parse,
  format,
  getHours,
  getDate,
  startOfWeek,
} from "date-fns"

const utcDate = date => {
  let now = date ? new Date(date) : new Date()
  now.setTime(now.getTime() + now.getTimezoneOffset() * 60 * 1000)
  return now
}

export default (dateRange, tick, dataSet) => {
  const daysBtwn = differenceInCalendarDays(
    dateRange.endDate,
    dateRange.startDate,
  )
  // Same Day - Hours: 12am
  if (daysBtwn === 0) {
    const hour = getHours(new Date(tick))

    if (hour === 0 || hour === 6 || hour === 12 || hour === 18) {
      return format(parse(tick, "yyyy-MM-dd HH:mm", new Date()), "ha")
    } else {
      return " "
    }
  }

  const date = utcDate(tick)
  // Default tick display: 12 Nov
  let tickDisplay = format(date, "d MMM")
  // 1 - 8 Days difference
  if (daysBtwn <= 8) {
    // Show the only date, if not first data value: 13
    if (tick !== dataSet[0].date) {
      tickDisplay = format(date, "d")
    }
    // 9 - 18 Days difference
  } else if (daysBtwn <= 18) {
    if (getDate(date) === 1) {
    } else if (getDate(date) % 2 === 1) {
      if (tick !== dataSet[0].date && tick !== dataSet[1].date) {
        tickDisplay = format(date, "d")
      }
    } else {
      tickDisplay = " "
    }
    // 19 - 72 Days difference
  } else if (daysBtwn <= 72) {
    if (getDate(date) !== 1) {
      const weekStart = startOfWeek(date)

      if (format(weekStart, "yyyy-MM-dd") === tick) {
        if (
          tick !== dataSet[0].date &&
          tick !== dataSet[1].date &&
          tick !== dataSet[2].date &&
          tick !== dataSet[3].date &&
          tick !== dataSet[4].date &&
          tick !== dataSet[5].date &&
          tick !== dataSet[6].date
        ) {
          tickDisplay = format(date, "d")
        }
      } else {
        tickDisplay = " "
      }
    }
    // Default
  } else {
    if (getDate(date) % 5 > 0) {
      tickDisplay = " "
    }
  }

  return tickDisplay
}
