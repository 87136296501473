import React, { useState, useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles"
import clsx from "clsx"
import classNames from "classnames"
import axios from "axios"
import { useParams } from "react-router-dom"

import { Grid, Typography, Paper, Chip } from "@material-ui/core"
import TruncateAsRequired from "../../../layout/TruncateAsRequired"
import UpdateForm from "./UpdateForm"
import { Tabs } from "./Tabs"

const PARTNER_API = process.env.REACT_APP_PARTNER_URL

const PartnerDetailPage = () => {
  const classes = useStyles()
  const { partnerId } = useParams()

  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [partner, setPartner] = useState(null)
  const formPaper = classNames(classes.fixedHeight, "slowfadein")
  const chartPaper = classNames(
    classes.paper,
    classes.fixedHeight,
    "slowfadein",
    classes.paddingBottom,
  )

  useEffect(() => {
    // Get partner.
    axios
      .get(`${PARTNER_API}/${partnerId}`, {})
      .then(response => {
        // Check if partner in response.
        if (response?.data?.record?.id) {
          // Set partner.
          setPartner(response.data.record)
        } else {
          setErrorMessage("No partner found")
          setError(true)
        }
      })
      .catch(_error => {
        setErrorMessage("Sorry an error occurred loading the partner")
        setError(true)
      })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // After partner form has updated, this needs to be updated for the displayName.
  // UpdateForm props are not rerendered.
  const handlePartnerUpdate = newPartner => setPartner(newPartner)

  if (error) {
    return (
      <Paper className={classes.errorPaper}>
        <Typography variant="h5" align="center">
          {errorMessage}
        </Typography>
      </Paper>
    )
  }

  if (!partner) {
    return "Fetching latest data..."
  }

  return (
    <>
      <Grid container alignItems="center" className={classes.header}>
        <TruncateAsRequired component="h4" variant="h6" maxWidth="80vw">
          {partner.displayName}
        </TruncateAsRequired>
        <Chip
          label={partner.accountStatus}
          size="small"
          className={clsx(classes.chip, {
            [classes.success]: partner.accountStatus === "ACTIVE",
            [classes.warning]: partner.accountStatus === "SUSPENDED",
            [classes.danger]: partner.accountStatus === "DISABLED",
          })}
        />
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} md={5}>
          <Typography variant="h6" className={classes.subtitle}>
            Partner Details
          </Typography>
          <Paper className={formPaper}>
            <UpdateForm
              partner={partner}
              onPartnerUpdate={handlePartnerUpdate}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={7}>
          <Typography variant="h6" className={classes.subtitle}>
            Total Word Count
          </Typography>
          <Paper className={chartPaper}></Paper>
        </Grid>
        <Tabs partner={partner} />
      </Grid>
    </>
  )
}

const useStyles = makeStyles(theme => ({
  errorPaper: {
    padding: theme.spacing(8),
    width: "100%",
    fontWeight: 300,
  },
  header: {
    marginBottom: theme.spacing(2.5),
  },
  chip: {
    marginLeft: theme.spacing(1),
    fontSize: "8pt",
    color: "#000",
    fontWeight: 500,
  },
  success: {
    backgroundColor: theme.palette.success.light,
  },
  warning: {
    backgroundColor: theme.palette.warning.light,
  },
  danger: {
    backgroundColor: theme.palette.danger.dark,
    color: "#fff",
  },
  subtitle: {
    fontWeight: 300,
    color: "#4a4a4a",
    fontSize: "16px",
    marginBottom: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(5),
  },
  fixedHeight: {
    height: 400,
  },
  paddingBottom: {
    padding: 0,
    paddingBottom: 40,
  },
}))

export default PartnerDetailPage
