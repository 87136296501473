import React from "react"
import {
  ResponsiveContainer,
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Line,
} from "recharts"

import TickFormatter from "../../../../../Utils/tickFormatter"
import NumberAbbreviator from "../../../../../Utils/numberAbbreviator"

const strokes = [
  "#358dff",
  "#f9ce32",
  "#48c462",
  "#ec4d6a",
  "#54dac5",
  "#8446de",
  "#f9cd32",
  "#36c1bf",
  "#e39663",
  "#7865ca",
  "#648ce5",
  "#443fc4",
  "#65a0e5",
  "#394276",
]

function PDFChart({ data, dates, projectNames }) {
  return (
    <div
      id="pdfChart"
      style={{
        width: "180mm",
        height: "112mm",
      }}
    >
      <ResponsiveContainer>
        <LineChart
          data={data}
          margin={{
            top: 38,
            left: 34,
            right: 6,
            bottom: 0,
          }}
        >
          <CartesianGrid vertical={false} horizontal stroke="#eee" />
          <XAxis
            dataKey="date"
            interval={0}
            padding={{ right: 24 }}
            tickFormatter={dateToFormat =>
              TickFormatter(dates, dateToFormat, data)
            }
            tick={{ fontSize: 12, fill: "#9e9e9e" }}
            tickMargin={4}
            stroke="#eee"
          />
          <YAxis
            tick={{ fontSize: 12, fill: "#9e9e9e", dx: 4 }}
            orientation="right"
            stroke="#fff"
            tickFormatter={NumberAbbreviator}
          />
          {projectNames.map((projectName, i) => (
            <Line
              key={i}
              type="monotone"
              dataKey={projectName}
              stroke={strokes[i % strokes.length]}
              strokeWidth={1.8}
              dot={false}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </div>
  )
}

export default PDFChart
