import React, { useState, useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles"
import clsx from "clsx"

import { withContext } from "../../../../../Utils/context"
import axios from "axios"
import { Link } from "react-router-dom"

import {
  Typography,
  Toolbar,
  IconButton,
  Divider,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableSortLabel,
  TablePagination,
  CircularProgress,
  Chip,
  Button,
} from "@material-ui/core"
import { Refresh as RefreshIcon, Add as AddIcon } from "@material-ui/icons"

import Searchbar from "../../../../layout/Searchbar"
import OrganisationSelect from "./OrganisationSelect"

const ORG_API = process.env.REACT_APP_ORG_URL

const Organisations = props => {
  const classes = useStyles()

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [searchBy, setSearchBy] = useState("")
  const [sortBy, setSortBy] = useState({
    key: "displayName",
    value: "asc",
  })
  const [data, setData] = useState([])
  const [totalRows, setTotalRows] = useState(0)
  const [pagination, setPagination] = useState({
    limit: 10,
    offset: 0,
  })
  const [organisationSelectOpen, setOrganisationSelectOpen] = useState(false)

  useEffect(() => {
    setError(false)
    setLoading(true)

    let data = {
      filterBy: [
        {
          key: "partnerId",
          value: props.partnerId,
        },
      ],
      sortBy,
      limit: pagination.limit,
      offset: pagination.offset,
    }

    if (searchBy !== "") {
      data.searchBy = searchBy
    }

    axios
      .post(`${ORG_API}/`, data)
      .then(response => {
        setLoading(false)
        setData(response.data.records)
        setTotalRows(response.data.totalTableCount)
      })
      .catch(_error => {
        setLoading(false)
        setError(true)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy, pagination])

  useEffect(() => {
    setPagination({
      limit: pagination.limit,
      offset: 0,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchBy])

  const toggleSelectOpen = () =>
    setOrganisationSelectOpen(!organisationSelectOpen)

  const handleSearchChange = value => setSearchBy(value)

  const handleSort = key => {
    setPagination({ offset: 0, limit: pagination.limit })
    sortBy.key === key
      ? // toggle sort direction
        setSortBy({ key, value: sortBy.value === "asc" ? "desc" : "asc" })
      : // use default direction
        setSortBy({ key, value: key === "userCount" ? "desc" : "asc" })
  }

  const handleChangePage = (event, newPage) => {
    setPagination({
      limit: pagination.limit,
      offset: newPage * pagination.limit,
    })
  }

  const handleChangeRowsPerPage = ({ target: { value } }) => {
    if (!error && !loading) {
      setPagination({
        limit: value,
        offset: 0,
      })
    }
  }

  const handleReload = () =>
    setPagination({
      limit: pagination.limit,
      offset: pagination.offset,
    })

  const handleOrganisationSelect = organisationId => {
    setOrganisationSelectOpen(false)
    setLoading(true)
    axios({
      method: "patch",
      url: `${ORG_API}/${organisationId}`,
      data: {
        partnerId: props.partnerId,
      },
    })
      .then(response => {
        setLoading(false)
        handleReload()
        props.createSuccess("Company successfully added to partner")
      })
      .catch(error => {
        setLoading(false)
        setError(true)
      })
  }

  return (
    <>
      <Toolbar className={classes.toolbar}>
        <div className={classes.flex}>
          <Searchbar
            placeholder="Search by company name"
            disabled={false}
            onChange={handleSearchChange}
          />
        </div>
        <IconButton
          title="Add Company"
          aria-label="Add company"
          size="small"
          onClick={toggleSelectOpen}
          className={classes.icon}
        >
          <AddIcon />
        </IconButton>
        <IconButton
          title="reload"
          aria-label="Reload"
          size="small"
          onClick={handleReload}
          disabled={loading}
        >
          <RefreshIcon />
        </IconButton>
      </Toolbar>
      <Divider />
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>
              <TableSortLabel
                active={sortBy.key === "displayName"}
                direction={sortBy.value}
                onClick={() => handleSort("displayName")}
              >
                Company name
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={sortBy.key === "userCount"}
                direction={sortBy.key === "userCount" ? sortBy.value : "desc"}
                onClick={() => handleSort("userCount")}
              >
                Total users
              </TableSortLabel>
            </TableCell>
            <TableCell align="center">
              <TableSortLabel
                active={sortBy.key === "accountStatus"}
                direction={sortBy.value}
                onClick={() => handleSort("accountStatus")}
              >
                Status
              </TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading || error ? (
            <TableRow style={{ height: 33 * pagination.limit }}>
              <TableCell padding="checkbox" align="center" colSpan={3}>
                {loading ? (
                  <CircularProgress />
                ) : (
                  <Typography variant="body1">
                    Sorry an error occurred fetching companies
                  </Typography>
                )}
              </TableCell>
            </TableRow>
          ) : (
            <>
              {data.length === 0 ? (
                <TableRow style={{ height: 33 * pagination.limit }}>
                  <TableCell padding="checkbox" align="center" colSpan={3}>
                    <Typography variant="body1">
                      {searchBy === "" ? (
                        <span>No companies associated</span>
                      ) : (
                        <span>No companies found with '{searchBy}'</span>
                      )}
                      <br />
                      <Button
                        color="primary"
                        variant="contained"
                        size="small"
                        onClick={toggleSelectOpen}
                        style={{ marginTop: "8px" }}
                      >
                        Add company
                      </Button>
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : (
                <>
                  {data.map((organisation, i) => (
                    <TableRow key={i} className="slowfadein">
                      <TableCell style={{ fontSize: 14 }}>
                        <Link to={"/company/" + organisation.id}>
                          {organisation.displayName}
                        </Link>
                      </TableCell>
                      <TableCell>{organisation.userCount}</TableCell>
                      <TableCell align="center">
                        <Chip
                          label={organisation.accountStatus}
                          size="small"
                          className={clsx(classes.chip, {
                            [classes.success]:
                              organisation.accountStatus === "ACTIVE",
                            [classes.warning]:
                              organisation.accountStatus === "SUSPENDED",
                            [classes.disabled]:
                              organisation.accountStatus === "DISABLED",
                          })}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                  {data.length < pagination.limit && (
                    <TableRow
                      style={{ height: 33 * (pagination.limit - data.length) }}
                    >
                      <TableCell colSpan={3} />
                    </TableRow>
                  )}
                </>
              )}
            </>
          )}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        component="div"
        count={totalRows}
        rowsPerPage={pagination.limit}
        page={
          pagination.offset !== 0 ? pagination.offset / pagination.limit : 0
        }
        backIconButtonProps={{
          "aria-label": "Previous Page",
        }}
        nextIconButtonProps={{
          "aria-label": "Next Page",
        }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      <OrganisationSelect
        open={organisationSelectOpen}
        onToggleOpen={toggleSelectOpen}
        onSave={handleOrganisationSelect}
      />
    </>
  )
}

const useStyles = makeStyles(theme => ({
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  flex: {
    flex: 1,
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  mailIcon: {
    fontSize: "16px",
    verticalAlign: "middle",
    marginRight: "4px",
    color: "rgba(0, 0, 0, 0.38)",
  },
  chip: {
    fontSize: "8pt",
    fontWeight: 500,
  },
  success: {
    backgroundColor: theme.palette.success.light,
  },
  warning: {
    backgroundColor: theme.palette.warning.light,
  },
  disabled: {
    backgroundColor: theme.palette.danger.dark,
    color: "#fff",
  },
}))

export default withContext(Organisations)
