import React from "react"
import ProjectsWordCountChart from "../charts/ProjectsWordCount"

function PDFChart(props) {
  return (
    <div id="pdfChart" style={{ width: "180mm", height: "112mm" }}>
      <ProjectsWordCountChart {...props} showLegend={true} />
    </div>
  )
}

export default PDFChart
