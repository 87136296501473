import React, { useState, useEffect } from "react"
import { lighten, makeStyles } from "@material-ui/core/styles"
import clsx from "clsx"

import { withContext } from "../../../../Utils/context"
import axios from "axios"

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle as MuiDialogTitle,
  Divider,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from "@material-ui/core"
import { Edit as EditIcon, Close as CloseIcon } from "@material-ui/icons"

import Searchbar from "../../../layout/Searchbar"
import TruncateAsRequired from "../../../layout/TruncateAsRequired"

const ORG_API = process.env.REACT_APP_ORG_URL

const DialogTitle = props => {
  const classes = dialogTitleUseStyles()

  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{props.children}</Typography>
      <IconButton
        aria-label="Close"
        className={classes.closeButton}
        onClick={props.onClose}
      >
        <CloseIcon />
      </IconButton>
    </MuiDialogTitle>
  )
}

const dialogTitleUseStyles = makeStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}))

const OrganisationSelect = ({
  onSave,
  selectedId: parentSelectedId,
  // loading: parentLoading,
}) => {
  const classes = useStyles()

  const [open, setOpen] = useState(false)
  const [searchBy, setSearchBy] = useState("")
  const [sortBy, setSortBy] = useState({ key: "displayName", value: "asc" })
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [data, setData] = useState([])
  const [totalRows, setTotalRows] = useState(0)
  const [pagination, setPagination] = useState({ limit: 7, offset: 0 })
  const [selectedId, setSelectedId] = useState(null)

  useEffect(() => {
    if (!open) return
    setLoading(true)
    setError(false)

    let data = {
      sortBy,
      limit: pagination.limit,
      offset: pagination.offset,
    }

    if (searchBy !== "") {
      data.searchBy = searchBy
    }

    axios
      .post(ORG_API, data)
      .then(response => {
        setLoading(false)
        setData(response.data.records)
        setTotalRows(response.data.totalTableCount)
      })
      .catch(_error => {
        setLoading(false)
        setError(true)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy, pagination])

  useEffect(() => {
    setPagination({
      limit: 7,
      offset: 0,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchBy])

  const toggleOpen = () => {
    if (open) {
      setOpen(false)
      setSelectedId(null)
    } else {
      setOpen(true)
      setSelectedId(parentSelectedId)
      setSearchBy("")
      setPagination({
        limit: 7,
        offset: 0,
      })
    }
  }

  const handleSearchChange = value => setSearchBy(value)

  const toggleSort = () =>
    setSortBy({
      key: "displayName",
      value: sortBy.value === "asc" ? "desc" : "asc",
    })

  const handleSelect = (_ev, id) => setSelectedId(selectedId === id ? null : id)

  const handleChangePage = (_, newPage) =>
    setPagination({
      limit: 7,
      offset: newPage * pagination.limit,
    })

  const handleSave = () => {
    onSave(selectedId)
    setOpen(false)
  }

  return (
    <>
      <IconButton aria-label="Change assigned company" onClick={toggleOpen}>
        <EditIcon fontSize="small" />
      </IconButton>
      <Dialog
        open={open}
        onClose={toggleOpen}
        aria-labelledby="company-select-title"
        classes={{ paper: classes.dialogPaper }}
      >
        <DialogTitle
          id="company-select-title"
          onClose={toggleOpen}
          className={classes.title}
        >
          {parentSelectedId ? "Change Assigned Company" : "Assign a Company"}
        </DialogTitle>
        <DialogContent dividers style={{ padding: 0 }}>
          <Searchbar
            placeholder="Search by name"
            disabled={false}
            onChange={handleSearchChange}
          />
          <Divider />
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={true}
                    direction={sortBy.value}
                    onClick={toggleSort}
                  >
                    Company name
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading || error ? (
                <TableRow style={{ height: 37 * 7 }}>
                  <TableCell padding="checkbox" align="center">
                    {loading ? (
                      <CircularProgress />
                    ) : (
                      <Typography variant="body1">
                        Sorry an error occurred fetching companies
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
              ) : (
                <>
                  {data.length === 0 ? (
                    <TableRow style={{ height: 37 * 7 }}>
                      <TableCell padding="checkbox" align="center">
                        <Typography variant="body1">
                          No companies found
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ) : (
                    <>
                      {data.map(organisation => (
                        <TableRow
                          key={organisation.id}
                          hover
                          tabIndex={-1} // why is the tabIndex being set here?
                          className={clsx("slowfadein", {
                            [classes.highlight]: selectedId === organisation.id,
                          })}
                          style={{ cursor: "pointer" }}
                          onClick={event =>
                            handleSelect(event, organisation.id)
                          }
                        >
                          <TableCell>
                            <TruncateAsRequired
                              title={organisation.displayName}
                              maxWidth="40vw"
                            >
                              {organisation.displayName}
                            </TruncateAsRequired>
                          </TableCell>
                        </TableRow>
                      ))}
                      {data.length < 7 && (
                        <TableRow style={{ height: 33 * (7 - data.length) }}>
                          <TableCell />
                        </TableRow>
                      )}
                    </>
                  )}
                </>
              )}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={totalRows}
            rowsPerPage={7}
            page={
              pagination.offset !== 0 ? pagination.offset / pagination.limit : 0
            }
            backIconButtonProps={{
              "aria-label": "Previous Page",
            }}
            nextIconButtonProps={{
              "aria-label": "Next Page",
            }}
            onChangePage={handleChangePage}
          />
        </DialogContent>
        <DialogActions>
          <Grid container>
            <Grid item xs={4}>
              {/* spacing */}
            </Grid>
            <Grid item xs={8} align="right">
              <Button onClick={toggleOpen} className={classes.cancelButton}>
                Close
              </Button>
              <Button
                color="primary"
                size="small"
                variant="contained"
                disabled={selectedId === parentSelectedId}
                onClick={handleSave}
              >
                Assign
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  )
}

const useStyles = makeStyles(theme => ({
  dialogPaper: {
    width: 750,
  },
  title: {
    fontWeight: 400,
  },
  highlight: {
    backgroundColor: lighten(theme.palette.primary.light, 0.85),
  },
  dangerButton: {
    backgroundColor: theme.palette.danger.main,
    color: "white",
  },
  cancelButton: {
    marginRight: theme.spacing(1),
  },
}))

export default withContext(OrganisationSelect)
