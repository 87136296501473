import React, { useState } from "react"
import classNames from "classnames"
import { makeStyles } from "@material-ui/core/styles"

import { withContext } from "../../../../Utils/context"
import axios from "axios"
import { Link } from "react-router-dom"
import format from "date-fns/format"
import NoData from "../../../layout/NoData"
import DeleteIcon from "@material-ui/icons/Delete"

import {
  Grid,
  Paper,
  InputLabel,
  IconButton,
  Typography,
  Button,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
} from "@material-ui/core"
import {
  DateRange as DateRangeIcon,
  MailOutline as MailIcon,
} from "@material-ui/icons"
import green from "@material-ui/core/colors/green"

import SendIcon from "@material-ui/icons/Send"

import OrganisationSelect from "./OrganisationSelect"
import TruncateAsRequired from "../../../layout/TruncateAsRequired"

const USER_API = process.env.REACT_APP_USER_URL
const ORG_API = process.env.REACT_APP_ORG_URL

const nowUTC = date => {
  let now = date ? new Date(date) : new Date()
  now.setTime(now.getTime() + now.getTimezoneOffset() * 60 * 1000)
  return now
}

const DeleteCompany = ({ handler, selectedId }) => (
  <IconButton
    disabled={selectedId === null || selectedId === "null"}
    aria-label="Remove assigned company"
    onClick={handler}
    edge="end"
  >
    <DeleteIcon fontSize="small" />
  </IconButton>
)

const UpdateForm = ({ user: initialUser, onUserUpdate, createSuccess }) => {
  const classes = useStyles()

  // TODO re-use setUser from parent
  const [user, setUser] = useState(initialUser)
  const [loading, setLoading] = useState(false)
  const [confirmDisable, setConfirmDisable] = useState(false)
  const [confirmEnable, setConfirmEnable] = useState(false)
  const [confirmDelete, setConfirmDelete] = useState(false)
  const [confirmNewPassword, setConfirmNewPassword] = useState(false)
  const [passwordLoading, setPasswordLoading] = useState(false)

  const handleOrganisationChange = async organisationId => {
    setConfirmDelete(false)
    setLoading(true)
    await axios.patch(`${USER_API}/${user.id}`, { organisationId })

    if (organisationId) {
      axios.get(`${ORG_API}/${organisationId}`).then(res => {
        // TODO: Add error handling on response.
        const organisationName = res?.data?.record?.displayName
        const updatedUser = { ...user, organisationId, organisationName }

        setUser(updatedUser)
        onUserUpdate(updatedUser)
      })
    } else {
      const updatedUser = {
        ...user,
        organisationId: null,
        organisationName: null,
      }
      setUser(updatedUser)
      onUserUpdate(updatedUser)
    }
    setLoading(false)
    const updateMsg = organisationId
      ? "User's company successfully updated!"
      : "User's company successfully removed!"
    createSuccess(updateMsg)
  }

  const handleStatusChange = async status => {
    setConfirmDisable(false)
    setConfirmEnable(false)

    setUser({
      ...user,
      accountStatus: status.toUpperCase(),
    })

    setLoading(true)
    await axios.put(`${USER_API}/${user.id}`, { status })

    // TODO: Add error handling on response.
    setLoading(false)
    onUserUpdate({
      ...user,
      accountStatus: status.toUpperCase(),
    })
    createSuccess("User status successfully updated!")
  }
  const handleSendNewPassword = () => {
    setPasswordLoading(true)
    axios
      .patch(`${USER_API}/${user.primaryIdentityId}/idm`, {
        isPermanent: false,
      })
      .then(res => {
        createSuccess("Password successfully reset.")
      })
      .catch(err => {
        console.log(err, "error")
        createSuccess("Something went wrong! Pease contact support.")
      })
      .finally(() => {
        setPasswordLoading(false)
        setConfirmNewPassword(false)
      })
  }

  const formPaper = classNames(classes.fillAvailable, "slowfadein")
  return (
    <Paper className={formPaper}>
      <Grid container justify="space-between" className={classes.root}>
        <Grid item xs={12}>
          <InputLabel shrink htmlFor="partner-input">
            Email
          </InputLabel>
          {user.emailAddress ? (
            <a
              href={"mailto:" + user.emailAddress}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                width: "100%",
                display: "inline-flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <TruncateAsRequired title={user.emailAddress}>
                {user.emailAddress}
              </TruncateAsRequired>
              <Tooltip
                title="Send email"
                placement="left"
                style={{ flexGrow: 0 }}
              >
                <MailIcon className={classes.mailIcon} />
              </Tooltip>
            </a>
          ) : (
            <NoData />
          )}

          <InputLabel
            shrink
            htmlFor="partner-input"
            className={classes.rawLabel}
          >
            Member of
          </InputLabel>
          <Grid container alignItems="center">
            <Grid item style={{ flex: 1 }}>
              {user.organisationId && (
                <Link to={"/company/" + user.organisationId}>
                  <TruncateAsRequired
                    title={user.organisationName}
                    color="primary"
                  >
                    {user.organisationName}
                  </TruncateAsRequired>
                </Link>
              )}
            </Grid>
            <OrganisationSelect
              loading={loading}
              selectedId={user.organisationId}
              onSave={handleOrganisationChange}
            />
            <DeleteCompany
              selectedId={user.organisationId}
              handler={() => setConfirmDelete(true)}
            />
          </Grid>
          {/* Delete Confirm Dialog */}
          <Dialog
            open={confirmDelete}
            onClose={() => setConfirmDelete(false)}
            aria-labelledby="disable-confirm-title"
            aria-describedby="disable-confirm-description"
          >
            <DialogTitle id="disable-confirm-title">
              Confirm Remove From Company
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Remove this user from <strong>{user.organisationName}</strong> ?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setConfirmDelete(false)}>Cancel</Button>
              <Button
                onClick={() => handleOrganisationChange(null)}
                color="primary"
                autoFocus
              >
                Remove
              </Button>
            </DialogActions>
          </Dialog>
          <InputLabel
            shrink
            htmlFor="created-date-input"
            className={classes.rawLabel}
          >
            Created on
          </InputLabel>
          {user.createdDate ? (
            <Grid
              container
              spacing={1}
              className={classes.lightGrey}
              alignContent="center"
            >
              <Grid item>
                <DateRangeIcon fontSize="small" style={{ marginTop: 2 }} />
              </Grid>
              <Grid item>
                <Typography>
                  {format(nowUTC(user.createdDate), "MMMM dd, yyyy")}
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <NoData />
          )}
        </Grid>
        {/* THIS IS TO FIX SAFARI BUG */}
        <Grid
          item
          style={{
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "flex-end",
          }}
          xs={12}
        >
          <Grid container spacing={2}>
            {user.accountStatus === "ACTIVE" ? (
              <>
                {/* Button */}
                <Grid item>
                  <Button
                    variant="contained"
                    size="small"
                    className={classes.disable}
                    onClick={() => setConfirmDisable(true)}
                    disabled={
                      loading ||
                      !user.firstName ||
                      !user.lastName ||
                      user.identity.identityProvider === "EXTERNAL_PROVIDER"
                    }
                  >
                    Disable
                  </Button>
                </Grid>
                {/* Confirm Dialog */}
                <Dialog
                  open={confirmDisable}
                  onClose={() => setConfirmDisable(false)}
                  aria-labelledby="disable-confirm-title"
                  aria-describedby="disable-confirm-description"
                >
                  <DialogTitle id="disable-confirm-title">
                    Confirm Disable User
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="disable-confirm-description">
                      Disable the account for{" "}
                      <strong>
                        {user.firstName} {user.lastName}
                      </strong>{" "}
                      ?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setConfirmDisable(false)}>
                      Cancel
                    </Button>
                    <Button
                      onClick={() => handleStatusChange("disabled")}
                      color="primary"
                      autoFocus
                    >
                      Disable
                    </Button>
                  </DialogActions>
                </Dialog>
              </>
            ) : (
              <>
                {/* Button */}
                <Grid item>
                  <Button
                    variant="contained"
                    size="small"
                    className={classes.enable}
                    onClick={() => setConfirmEnable(true)}
                    disabled={loading || !user.firstName || !user.lastName}
                  >
                    Enable
                  </Button>
                </Grid>
                {/* Confirm Dialog */}
                <Dialog
                  open={confirmEnable}
                  onClose={() => setConfirmEnable(false)}
                  aria-labelledby="enable-confirm-title"
                  aria-describedby="enable-confirm-description"
                >
                  <DialogTitle id="enable-confirm-title">
                    Confirm Enable User
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="enable-confirm-description">
                      Enable the account for{" "}
                      <strong>
                        {user.firstName} {user.lastName}
                      </strong>{" "}
                      ?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setConfirmEnable(false)}>
                      Cancel
                    </Button>
                    <Button
                      onClick={() => handleStatusChange("active")}
                      color="primary"
                      autoFocus
                    >
                      Enable
                    </Button>
                  </DialogActions>
                </Dialog>
              </>
            )}
            <>
              <Grid item>
                <Button
                  style={{ minWidth: "180px" }}
                  fullWidth
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => setConfirmNewPassword(true)}
                  endIcon={<SendIcon />}
                >
                  Reset Password
                </Button>
              </Grid>

              {/* Confirm New Password Dialog */}
              <Dialog
                open={confirmNewPassword}
                onClose={() => setConfirmNewPassword(false)}
                aria-labelledby="disable-confirm-title"
                aria-describedby="disable-confirm-description"
              >
                <DialogTitle id="disable-confirm-title">
                  Reset Password
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="disable-confirm-description">
                    This will reset the user’s password. A temporary password
                    will be emailed that the user will have to change at next
                    login. Do you wish to proceed?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setConfirmNewPassword(false)}>
                    Cancel
                  </Button>
                  <Button
                    onClick={handleSendNewPassword}
                    color="primary"
                    autoFocus
                  >
                    Continue
                  </Button>
                  {passwordLoading && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </DialogActions>
              </Dialog>
            </>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    height: 320,
    padding: theme.spacing(3),
  },
  rawLabel: {
    marginTop: theme.spacing(2),
  },
  lightGrey: {
    color: "rgba(0, 0, 0, 0.60)",
  },
  updateWrapper: {
    position: "relative",
  },
  updateProgress: {
    color: theme.palette.success.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  mailIcon: {
    fontSize: "1.2rem",
    verticalAlign: "middle",
  },
  enable: {
    backgroundColor: theme.palette.success.light,
  },
  disable: {
    backgroundColor: theme.palette.danger.dark,
    color: "#fff",
  },
  fillAvailable: {
    height: "95%",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    bottom: "25%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}))

export default withContext(UpdateForm)
