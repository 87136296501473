import React, { useState, useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles"

import { withContext } from "../../../../Utils/context"
import axios from "axios"

import NumberAbbreviator from "../../../../Utils/numberAbbreviator"

import { Box, Grid, Typography } from "@material-ui/core"
import Icon from "@material-ui/core/Icon"

import { Group as GroupIcon, Devices as DevicesIcon } from "@material-ui/icons"

const REPORTING_API = process.env.REACT_APP_REPORTING_URL

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
  },
  count: {
    minWidth: theme.spacing(7),
  },
  icon: {
    fontSize: "1rem",
  },
  title: {
    wordBreak: "break-word",
  },
  errorBox: {
    padding: theme.spacing(8),
    width: "100%",
    fontWeight: 300,
  },
  leftGrid: {
    [theme.breakpoints.down("md")]: {
      paddingRight: theme.spacing(2),
    },
    [theme.breakpoints.down("xs")]: {
      paddingRight: theme.spacing(0),
    },
  },
  rightGrid: {
    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.spacing(2),
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: theme.spacing(4),
      paddingLeft: theme.spacing(0),
    },
  },
}))

const SummaryItem = ({ Icon, count, title }) => {
  const classes = useStyles()
  return (
    <Grid item xs={12}>
      <Grid container spacing={6} wrap="nowrap">
        <Grid item xs={1}>
          <Icon />
        </Grid>
        <Grid item xs={2} style={{ minWidth: "56px", paddingLeft: "16px" }}>
          <Typography className={classes.count}>
            {count ? NumberAbbreviator(count) : "0"}
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography className={classes.title}>{title}</Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

const Summary = props => {
  const classes = useStyles()
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [summary, setSummary] = useState({
    userCount: "-",
    deviceCount: "-",
    projectOwnerCount: "-",
    excelUserCount: "-",
    biToolCount: "-",
    projectCount: "-",
    totalProjectUsage: "-",
    monthProjectUsage: "-",
    totalViewerUsage: "-",
    monthViewerUsage: "-",
  })

  useEffect(() => {
    axios({
      method: "GET",
      url: `${REPORTING_API}/organisation/${props.organisationId}/summary/`,
    })
      .then(response => {
        if (response.data && response.data.summary) {
          setSummary(response.data.summary)
        } else {
          setErrorMessage("No company summary found")
          setError(true)
        }
      })
      .catch(_error => {
        setErrorMessage("Sorry an error occurred loading the company summary")
        setError(true)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const customIcons = {
    studioAppIcon: {
      icon:
        "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIyLjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCA0MCA0MCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNDAgNDA7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDojMDAwMDAwO30KPC9zdHlsZT4KPHRpdGxlPkEgTG9nbzwvdGl0bGU+CjxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgo8Zz4KCTxwb2x5Z29uIGNsYXNzPSJzdDAiIHBvaW50cz0iMTAuOSwyMS45IDIyLjcsMjEuOSAxNi41LDYuNSAJIi8+Cgk8cGF0aCBjbGFzcz0ic3QwIiBkPSJNMzYuMiwzMS41YzIuNC0zLjMsMy44LTcuMywzLjgtMTEuN2MwLTkuNy02LjktMTcuNy0xNi0xOS42TDM2LjIsMzEuNXoiLz4KCTxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik0xNC4zLDAuOGwtMC4xLTAuMUM2LDMuMSwwLDEwLjgsMCwxOS44YzAsNC4yLDEuMyw4LjEsMy41LDExLjNMMTQuMywwLjh6Ii8+Cgk8cGF0aCBjbGFzcz0ic3QwIiBkPSJNMjQsMjUuNkg5LjdsLTMuMiw5YzMuNiwzLjIsOC4zLDUuMiwxMy41LDUuMmMzLjIsMCw2LjItMC44LDguOS0yLjFMMjQsMjUuNnoiLz4KPC9nPgo8L3N2Zz4K",
    },
    excelPluginIcon: {
      icon:
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABmJLR0QA/wD/AP+gvaeTAAACmElEQVRoge2aP2gUQRTGf5tERcWgKRIQNRYiGMGLf4KgaARFDBZiIQh2KcRCa7GzSy0WBmuLIAq2aYIgFopgEUyhVjkCIoiCeBETXYvJkvHd7P+52WG9D6bYmXfvfd/btzuzcxPQWfQA+4BR4PBaGwU+AKdsBgos+toA7AeOrrUR4Agw4CA2fQV/1w8cYp3wQeAYsMkSr9zIImQn62SjbB/AckbLQhfSCwzzL+ExYKgCXoVwE3gFtIDQYbOOX44FSCFlfDSBCVB13pHsZED0jJWN3wT21EEIQNBjwYkX6ArxDbURUnSJYhPyoU9bMRhfDv/FHfkCzIm+LcDFGPuPwFvRNw4MpnCQGS78Oo6bNVeBhsH+dYz9CWE3QvKqIS1+7lVCksEL2mv2ssHuqUHwXJbgroSEwBVBMADmtfFVVPZ1XMsa3KWQRWBrAtGHYqwfWPJRSAjcFWR7gffAMrBbjN3LE9wgKA2lhLSAvcLhLeCB6GsAKz4LCYEnwuEgcFy7DoCXOfzljW+ltKJ2PiFTk0WCVyVkHvMkugP4XKWQvEuUPtSDLvET+J7Tl3VkVf0bOJng50IOX5WW1n1B/ByqpHQ8KyBE8khDKSGLwDbhcAa4IfqGgR8+C7kknDVQpbaEWhHruJNTiLPSmjFkZVYbvy3GNgILvgn5htr71XFG2Hylfcf9NPDHJyGTgmAAvDHYTdGOR74IeU77t8jVGNsWsEvYDqHulhMhSZ+6y8C06JuIsd0MPEbN/Do+AdsTYliDD1umEcKYfgkT3/psmfqwryUzXKhCanNHukJ8Q1eIR2iCErJSMZEyaALXo4ta/D2tz6KuDwxYPTmRxVmnjnA4F2KCjUM1XggxodJjTp0+4RN38OwdcNZmoL9AUe9PWmupxgAAAABJRU5ErkJggg==",
    },
    groupProjectIcon: {
      icon:
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAABmJLR0QA/wD/AP+gvaeTAAAEK0lEQVR4nO2duWsVQRzHP9F44pHYaQ4QkTSKWFtoY6vi2dqLiqX/g7UWUbGwUUS8FRRBUYwXKBGSKCh4VQr61EJRnsW8By/Jvp3ZnWuP3wd+hBwz+5vv9+3uzOzsBARBEARBEARBENIZBg4Ct4BJ4CfQ1EQDuAiMRMi3MgwCp4C/6AXvFl9b9QgZ2QH8IL/wnXE+cO6l5zDwDzfiN4HvYdMvNztwK347BAMGcXfZEQNycBo/4osBBgxj19sRAzIwJ+Fn24G5mnIfgN3AMqBnRujwZayvCD6OualJ6D2wIqV8bMF8RbBxzJQmkV2a8rGF8hnOxzFJl4wGsDSlzDJUD6kbTauMik0DWO6ywiQDdALqrvNVNgDM7nPGJN2EhYCIAZERAyIjBkRGDIiMGBAZMcAvvWimdapgwFFgAbPnpHQxhJp28cFG4C7wuxV3gA2mhXXDcdvyrmO1acMSGMpxPB1rSH6W8gNYZ5JU2Qw4i92Z7NqAyyllr7pIyHWDXMRJ8k8RuDYgbalOw0VCrhvk0oQ8Z4JrA2zLl9aAJjBK9jMhqgFV6AV1sh5YHDsJW8p6BjwD+j20txaXoClgL7AKGAD2AW8ylB8j/4OS2hswSfIntx94alDeRvw8+drW572CrA3ak1JXH/Akpayt+Hnyta3PewVZG7RSU18f8DihXBbxl6LmZVzkq6NyBoASeqyjTJYbbrvsFdSckW2+OkpnQNolqJO2kFk++TONu8xsE2pvwBTqMmPCcvKL344bwKLW3yzJka+O0hnQxM3NtJNu4rfjHrAVOJEjVx2lNKAJPCffQGomOvFtQ0dpDWiSfzTbxrf4lTfAxoQQ4tfCgDwmhBK/EAYcAa4B74BfHhv6CLMbc0jxC2FAyNCdCaHFr50BaSbEEL+WBjSBl8BmYF4rtgDjkXKx1W8aed4PiMmf1tf5EXOwfT+ip+s3hhXUHacGVO2ZcOkQAyKTZMDP4FnUmCQDPgfPosYkGfA2eBY1JsmA68GzqDFJXaoh1DyPbr+IuuK9G/oBOJMhIcGCbm4OABOkb1lQV4IMxD6hlgf+M0xK8MQh/OwbV9b4ZqCZ7WTeLLah3uyI3fgixAUDvZwbAGrV8ih+tzIrenwF1hpo5cWANoPAAdTipgn87axYpPiO+uSbiI9BfdNwuvdNRwJp+DhmkcjUfpkNjYwYEBkxIDI+DEjb0A/UimShhQ8Dbmt+v8XDMYUORlBdt27dsHGqPcfkdRxgynFNEq9QI+wqGhF9HACwCXjgqe6yE2Qc8BC476nuSuFzVLoJZYJ0dacTbCT8EDjmsX7BgF7gEvEn1IoSJs8TnLMAta1Y7MYXIc5ZamnFftQD/9gixIovJPwDiJBLT16g3st9jVpmPh9YSNyl5iFooN7Q3wl8jJyLIAiCIAiCIAiCIAiCEJH/9EldCFeSf7AAAAAASUVORK5CYII=",
    },
    usageChartIcon: {
      icon:
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAABmJLR0QA/wD/AP+gvaeTAAAC6ElEQVR4nO2dzW7TQBRGT0lWiKcA0RYEQoCq8JCw4TGoENAKARIC3qEseAJ2kNCA+G9YOJVAjfH8XPt67O9Is4pnPPlOMh7bEweEEEIIIYQQQgghOmNi3N4U2AJWxu2KBm4Cr4DfwC/gJXDDtUcj4hKwpPrU/12WwDXHfo2GJ5wN/7QcOvZrNHymXsCxY7+KYMugjaYDrsU+Bss57w6MHQlwRgKckQBnJMAZCXBGAvLInmJLQBpXgMfAHPgKPMfx2lfdWfBpGRozquA3XftykTAmATNgQf17febRqbEIaAp/RTUcdX7ppQ8CdoB94P267APbhu2HhL8CvhjuMxhvAXVj8nz9mkX7IeGvgIcG+4vGU0BTOAvyJMSEPwcuZuwrGS8Bt4GPAftfAHdabN9CdBYeAmLCSZFQTPjUdKpNAbHhx0ooKnzoVkBq+KESigsfuhOQG36ThCLDh24EWIVfJ6HY8KF9ATFTwUXktrOE9nsVPrQrICWctoX1jrYE5AwLt4APgXWLDh/aEWAxJltJ6HX4YC/A8oCYK6H34YOtgDZmI6kSiggf7AS0ORWMlVBM+GAjoIt5eKiEosKHfAFdngQ1SSgufMgT4HES1PYNnM5JFeB5BrrNv7cwHwCXDdvvlBQBRV976RuxAnbYPAQo/ERiBTwNqKPwI4gRMAG+B9RR+BHECJgCPxu2V/iRxA5Bb/6zrcJPIFbAHtUSvsHMw71JmYbuAa+phqNvwAEFz8Nz8P6d8GRd/8SgH0XiLWD06BcyzkiAMxLgjAQ4IwHOSIAzEuCMBDgjAc5IgDMS4IwEOCMBzkiAMxYClg2vh6yAKLl8onp68NWIzEx529DBsZQlcD02PItvwAuDNobABeBubCWLu1W7wBHVkpOx8wM4T/UY/yAsvgHvgPsG7YgMpoQvORxycR2Op8A9mle+DbUck3AQboNdqoPREZv/WWNoZQ48onqUpRBCCCGEEEIIIYQQQgghxBn+ANFgnegru+g8AAAAAElFTkSuQmCC",
    },
  }

  function ExcelPluginIcon(props) {
    return (
      <Icon {...props} fontSize="inherit">
        <img alt="Excel" src={customIcons.excelPluginIcon.icon} height="22" />
      </Icon>
    )
  }

  function StudioAppIcon(props) {
    return (
      <Icon {...props} fontSize="inherit">
        <img
          alt="Studio Logo"
          src={customIcons.studioAppIcon.icon}
          height="22"
        />
      </Icon>
    )
  }

  function GroupProjectIcon(props) {
    return (
      <Icon {...props} fontSize="inherit">
        <img
          alt="Group Project"
          src={customIcons.groupProjectIcon.icon}
          height="25"
        />
      </Icon>
    )
  }

  function UsageChartIcon(props) {
    return (
      <Icon {...props} fontSize="inherit">
        <img
          alt="Usage Chart"
          src={customIcons.usageChartIcon.icon}
          height="25"
        />
      </Icon>
    )
  }

  if (error) {
    return (
      <Box className={classes.errorBox}>
        <Typography variant="h5" align="center">
          {errorMessage}
        </Typography>
      </Box>
    )
  }

  return (
    <Grid className={classes.root} container>
      <Grid item xs={12} sm={6}>
        <Grid container spacing={4} className={classes.leftGrid}>
          <SummaryItem
            Icon={GroupIcon}
            count={summary.userCount}
            title="Total Users"
          />
          <SummaryItem
            Icon={DevicesIcon}
            count={summary.deviceCount}
            title="Registered Devices"
          />
          <SummaryItem
            Icon={StudioAppIcon}
            count={summary.projectOwnerCount}
            title="NLG Studio Project Owners"
          />
          <SummaryItem
            Icon={ExcelPluginIcon}
            count={summary.excelUserCount}
            title="Excel Users"
          />
          <SummaryItem
            Icon={DevicesIcon}
            count={summary.biToolCount}
            title="BI Users"
          />
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Grid container spacing={4} className={classes.rightGrid}>
          <SummaryItem
            Icon={GroupProjectIcon}
            count={summary.projectCount}
            title="Total Projects"
          />
          <SummaryItem
            Icon={UsageChartIcon}
            count={summary.totalProjectUsage}
            title="Total Project Usage"
          />
          <SummaryItem
            Icon={UsageChartIcon}
            count={summary.totalViewerUsage}
            title="Total Viewer Usage"
          />
          <SummaryItem
            Icon={UsageChartIcon}
            count={summary.monthProjectUsage}
            title="Current Month Project Usage"
          />
          <SummaryItem
            Icon={UsageChartIcon}
            count={summary.monthViewerUsage}
            title="Current Month Viewer Usage"
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default withContext(Summary)
