export default number => {
  const billion = 1000000000
  const million = 1000000
  const thousand = 1000
  // Floor * 10 / 10 is used to have only 1 dec
  if (number > billion) {
    return (Math.floor((number / billion) * 10) / 10).toString() + "b"
  } else if (number > million) {
    return (Math.floor((number / million) * 10) / 10).toString() + "m"
  } else if (number > thousand) {
    return (Math.floor((number / thousand) * 10) / 10).toString() + "k"
  } else {
    return number.toString()
  }
}
