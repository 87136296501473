import React, { useState, useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles"
import clsx from "clsx"
import classNames from "classnames"
import { useParams } from "react-router-dom"

import axios from "axios"

import { Grid, Typography, Paper, Chip } from "@material-ui/core"

import { StyledTabs, StyledTab } from "../../../layout/StyledTabs"
import TruncateAsRequired from "../../../layout/TruncateAsRequired"

import UpdateForm from "./UpdateForm"
import ProjectUsage from "./charts/ProjectUsage"
import Summary from "./Summary"
import { Tabs } from "./Tabs"

const ORG_API = process.env.REACT_APP_ORG_URL

const OrganisationDetailPage = () => {
  const classes = useStyles()
  const { organisationId } = useParams()

  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [organisation, setOrganisation] = useState(null)
  const [upperTabValue, setUpperTabValue] = useState(0)

  const formPaper = classNames(classes.minFixedHeight, "slowfadein")
  const summaryPanelPaper = classNames(classes.minFixedHeight, "slowfadein")
  const chartPaper = classNames(
    classes.fixedHeight,
    "slowfadein",
    classes.paddingBottom,
  )

  useEffect(() => {
    // Get organisation.
    axios
      .get(`${ORG_API}/${organisationId}`)
      .then(response => {
        // Check if organisation in response.
        if (response.data && response.data.record && response.data.record.id) {
          setOrganisation(response.data.record)
        } else {
          setErrorMessage("No company found")
          setError(true)
        }
      })
      .catch(_error => {
        setErrorMessage("Sorry an error occurred loading the company")
        setError(true)
      })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleUpperTabChange = (_event, newValue) => setUpperTabValue(newValue)

  // After organisation form has updated, this needs to be updated for the displayName.
  // UpdateForm props are not rerendered.
  const handleOrganisationUpdate = newOrganisation =>
    setOrganisation(newOrganisation)

  if (error) {
    return (
      <Paper className={classes.errorPaper}>
        <Typography variant="h5" align="center">
          {errorMessage}
        </Typography>
      </Paper>
    )
  }
  if (!organisation) {
    return <div>Fetching data...</div>
  }
  return (
    <>
      <Grid container alignItems="center" className={classes.companyName}>
        <Grid item>
          <TruncateAsRequired
            title={organisation.displayName}
            maxWidth="80vw"
            component="h4"
            variant="h6"
            className={classes.title}
          >
            {organisation.displayName}
          </TruncateAsRequired>
        </Grid>
        <Grid item>
          <Chip
            label={organisation.accountStatus}
            size="small"
            className={clsx(classes.chip, {
              [classes.success]: organisation.accountStatus === "ACTIVE",
              [classes.warning]: organisation.accountStatus === "SUSPENDED",
              [classes.danger]: organisation.accountStatus === "DISABLED",
            })}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} style={{ maxWidth: "100%" }}>
        <Grid item xs={12} sm={12} md={5}>
          <Typography variant="h6" className={classes.subtitleTabAlign}>
            Company Details
          </Typography>
          <Paper className={formPaper}>
            <UpdateForm
              organisation={organisation}
              onOrganisationUpdate={handleOrganisationUpdate}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={7}>
          <Typography variant="h6" className={classes.subtitle}>
            <StyledTabs
              value={upperTabValue}
              onChange={handleUpperTabChange}
              aria-label="company tabs"
            >
              <StyledTab label="Summary" />
              <StyledTab label="Usage Chart" />
            </StyledTabs>
          </Typography>

          {upperTabValue === 0 ? (
            <Paper className={summaryPanelPaper}>
              <Summary organisationId={organisation.id} />
            </Paper>
          ) : (
            <Paper className={chartPaper}>
              <ProjectUsage organisationId={organisation.id} />
            </Paper>
          )}
        </Grid>
        <Tabs organisation={organisation} />
      </Grid>
    </>
  )
}

const useStyles = makeStyles(theme => ({
  errorPaper: {
    padding: theme.spacing(8),
    width: "100%",
    fontWeight: 300,
  },
  title: {
    fontWeight: 500,
    color: "#4a4a4a",
    fontSize: "24px",
  },
  chip: {
    fontSize: "8pt",
    color: "#000",
    fontWeight: 500,
    marginLeft: 10,
  },
  success: {
    backgroundColor: theme.palette.success.light,
  },
  warning: {
    backgroundColor: theme.palette.warning.light,
  },
  danger: {
    backgroundColor: theme.palette.danger.dark,
    color: "#fff",
  },
  subtitle: {
    fontWeight: 300,
    color: "#4a4a4a",
    fontSize: "16px",
    marginBottom: theme.spacing(1),
  },
  subtitleTabAlign: {
    fontWeight: 300,
    color: "#4a4a4a",
    fontSize: "16px",
    marginBottom: 20,
    marginTop: 10,
  },
  paper: {
    padding: theme.spacing(5),
  },
  fixedHeight: {
    height: "450px",
    [theme.breakpoints.only("md")]: {
      height: "480px",
    },
  },
  minFixedHeight: {
    minHeight: 450,
    [theme.breakpoints.only("md")]: {
      minHeight: 480,
    },
  },
  paddingBottom: {
    padding: 0,
    paddingBottom: 40,
  },
  companyName: {
    marginBottom: theme.spacing(3),
  },
}))

export default OrganisationDetailPage
