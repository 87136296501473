import React from "react"
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts"

import format from "date-fns/format"
import diffMonths from "date-fns/differenceInMonths"
import diffDays from "date-fns/differenceInDays"

import abbreviateNumber from "../../../../../Utils/numberAbbreviator"
import {
  Card,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Typography,
} from "@material-ui/core"

// prettier-ignore
const strokes = ["#358dff", "#f9ce32", "#48c462", "#ec4d6a", "#54dac5", "#8446de", "#f9cd32", "#36c1bf", "#e39663", "#7865ca", "#648ce5", "#443fc4", "#65a0e5", "#394276",]
const strokeTotalWc = "#adcbe3"
const CHART_MARGINS = { top: 38, left: 34, right: 34, bottom: 30 }

const ProjectsWordCountChart = ({
  data,
  dates,
  topProjects,
  selectedProjects,
  showLegend = true,
}) => {
  // get selected project and format it to lookup
  const formatedSelectedProjects = selectedProjects?.map(
    x => x.projectSetId + "-" + x.usageType,
  )

  const defaults = ["totalWordCount", "date"]

  // Find the selected project and get their data only
  const truncatedData = data.map(obj => {
    return Object.fromEntries(
      Object.entries(obj)
        .map(([key, v]) => [key, v])
        .filter(
          ([k]) =>
            formatedSelectedProjects?.includes(k) || defaults.includes(k),
        ),
    )
  })
  // Find the selected project from the top projects
  const truncatedTopProjects = Object.fromEntries(
    Object.entries(topProjects)
      .map(([key, v]) => [key, v])
      .filter(([k]) => formatedSelectedProjects?.includes(k)),
  )

  const dm = diffMonths(new Date(dates.endDate), new Date(dates.startDate))
  const dd = diffDays(new Date(dates.endDate), new Date(dates.startDate))

  const showTotal = data?.[0]?.hasOwnProperty("totalWordCount")

  const withTotal = showTotal
    ? [{ value: "Total Word Count", color: strokeTotalWc }]
    : []

  const legends = withTotal.concat(
    Object.values(truncatedTopProjects).map((name, i) => ({
      value: name,
      color: strokes[i % strokes.length],
      iconType: "line",
    })),
  )

  const formatTick = tick => {
    const d = new Date(tick)
    if (dd <= 1) {
      return d.getHours() % 3 === 0 ? format(d, "hh:mm") : ""
    } else if (dm < 4) {
      const date = d.getDate()
      return date === 1
        ? format(d, "d MMM")
        : date % 10 === 0 && date < 30
        ? format(d, "d")
        : ""
    }
    const m = d.getMonth()
    return m === 0 ? format(d, "yyyy") : m % 3 === 0 ? format(d, "MMM") : ""
  }

  const TooltipContent = props => {
    const x = props.payload?.[0]
    if (!props.active || !x) return null

    return (
      <Card>
        <List
          dense
          subheader={<ListSubheader>{props.label} (UTC)</ListSubheader>}
        >
          {Object.entries(x.payload)
            .filter(([_, wc]) => wc > 0)
            .map(([id, wc]) => (
              <ListItem key={id}>
                <ListItemText>
                  {truncatedTopProjects[id] ? (
                    truncatedTopProjects[id] + "-" + id + ": " + wc
                  ) : (
                    <Typography variant="subtitle2">
                      Total for all selected projects: {wc}
                    </Typography>
                  )}
                </ListItemText>
              </ListItem>
            ))}
        </List>
      </Card>
    )
  }

  return (
    <ResponsiveContainer>
      <ComposedChart data={truncatedData} margin={CHART_MARGINS}>
        <CartesianGrid vertical={false} horizontal stroke="#eee" />
        <XAxis
          dataKey="date"
          interval={0}
          padding={{ right: 24 }}
          tickFormatter={formatTick}
          tick={{ fontSize: 12, fill: "#9e9e9e" }}
          tickMargin={4}
          stroke="#eee"
        />
        {showTotal && (
          <YAxis
            yAxisId="left"
            orientation="left"
            stroke="white"
            tickFormatter={abbreviateNumber}
            tick={{ fontSize: 12, fill: "#9e9e9e", dx: 4 }}
            label={{
              value: "Total Word Count",
              fill: "#9e9e9e",
              angle: -90,
              position: "left",
            }}
          />
        )}
        <YAxis
          yAxisId="right"
          orientation="right"
          stroke="white"
          tickFormatter={abbreviateNumber}
          tick={{ fontSize: 12, fill: "#9e9e9e", dx: 4 }}
          label={{
            value: "Project Word Count",
            fill: "#9e9e9e",
            angle: 90,
            position: "right",
          }}
        />
        <Tooltip content={<TooltipContent />} />
        {showLegend && <Legend payload={legends} />}
        {showTotal && (
          <Bar yAxisId="left" dataKey="totalWordCount" fill={strokeTotalWc} />
        )}
        {Object.entries(truncatedTopProjects).map(([projectSetId], i) => (
          <Line
            key={projectSetId}
            type="monotone"
            yAxisId="right"
            dataKey={projectSetId}
            stroke={strokes[i % strokes.length]}
            strokeWidth={2}
            dot={false}
          />
        ))}
      </ComposedChart>
    </ResponsiveContainer>
  )
}

export default ProjectsWordCountChart
