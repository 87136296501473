import React, { useState, useEffect, useRef } from "react"
import { makeStyles } from "@material-ui/core/styles"
import classNames from "classnames"

import { withContext } from "../../../../../Utils/context"
import axios from "axios"
import { format } from "date-fns"

import {
  Toolbar,
  IconButton,
  Divider,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  TablePagination,
  Grid,
  CircularProgress,
  Typography,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Tooltip,
  Box,
  Paper,
  List,
  ListItemIcon,
  ListItemText,
  ListItem,
} from "@material-ui/core"
import {
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  CheckBox as CheckBoxIcon,
  FilterList as FilterListIcon,
  VpnKey as GenericLicenseIcon,
} from "@material-ui/icons"
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord"
import Searchbar from "../../../../layout/Searchbar"
import DatePicker from "../../../../layout/DatePicker"

const REPORTING_API = process.env.REACT_APP_REPORTING_URL

const ICONS = {
  API: {
    imageLink: require("../../../../assets/usage-icons-studio-square.svg"),
    imageRemoveLink: require("../../../../assets/studio-remove.png"),
    removeDisplayName: "Remove API",
    displayName: "API",
  },
  excel: {
    imageLink: require("../../../../assets/usage-icons-excel-square.svg"),
    imageRemoveLink: require("../../../../assets/excel-remove.png"),
    removeDisplayName: "Remove Excel",
    displayName: "Excel",
  },
  powerbi: {
    imageLink: require("../../../../assets/usage-icons-power-b-square.svg"),
    imageRemoveLink: require("../../../../assets/powerBI-remove.png"),
    removeDisplayName: "Remove Power BI",
    displayName: "Power BI",
  },
  tableau: {
    imageLink: require("../../../../assets/usage-icons-tableau-square.svg"),
    imageRemoveLink: require("../../../../assets/powerBI-remove.png"),
    removeDisplayName: "Remove Tableau",
    displayName: "Tableau",
  },
  tibco: {
    imageLink: require("../../../../assets/usage-icons-tibco-square.svg"),
    imageRemoveLink: require("../../../../assets/powerBI-remove.png"),
    removeDisplayName: "Remove TIBCO",
    displayName: "TIBCO",
  },
}

const Projects = ({
  dates,
  onDatesChange,
  onSelectProject,
  selectedProjects,
  setSelectedProjects,
  offset,
  setOffset,
  userId,
  changeSortOrder,
  projectLoading,
  setProjectLoading,
}) => {
  const classes = useStyles()

  const [loading, setLoading] = useState(true)
  const [firstLoad, setFirstload] = useState(true)
  const [error, setError] = useState(false)
  const [searchBy, setSearchBy] = useState("")
  const [sortBy, setSortBy] = useState({
    key: "wordCount",
    value: "desc",
  })
  const [data, setData] = useState([])
  const [totalRows, setTotalRows] = useState(0)
  const [limit, setLimit] = useState(10)
  const [selectAll, setSelectAll] = useState(false)
  const [filterDialog, setFilterDialog] = useState(false)
  const [includeWords, setIncludeWords] = useState("")
  const [excludeWords, setExcludeWords] = useState("")
  const [includeWordsRestore, setIncludeWordsRestore] = useState("")
  const [excludeWordsRestore, setExcludeWordsRestore] = useState("")
  const [showSelected, setShowSelected] = useState(false)
  const [usageType, setUsageType] = useState({
    list: [
      { name: "Power BI", searchName: "powerbi", isChecked: false },
      { name: "Tableau", searchName: "tableau", isChecked: false },
      { name: "Excel", searchName: "excel", isChecked: false },
      { name: "API", searchName: "API", isChecked: false },
    ],
  })
  const [filterUsageType, setFilterUsageType] = useState([])
  const searchBarRef = useRef()
  // regex to parse all the search terms
  const regextoParseSearch = /-?"[^"]*"|'[^']*'|\s+|\s*((?:(?!\s)[^\s,])+)/gi
  const projectPaper = classNames(classes.fixedHeight, "slowfadein")

  useEffect(() => {
    setLoading(true)
    setError(false)

    let payload = {
      sortBy,
      limit,
      offset,
      startDate: format(dates.startDate, "yyyy-MM-dd"),
      endDate: format(dates.endDate, "yyyy-MM-dd"),
    }

    if (searchBy !== "") {
      const results = searchBy
        .trim()
        .match(regextoParseSearch)
        .filter(item => item !== " ")

      const excludeList = results.filter(
        item =>
          item.includes(`-`) &&
          item.startsWith(`-`) &&
          ![`-`, `-"`, `"-`, `-""`, `"-"`, ` -""`].includes(item),
      )
      const includeList = results.filter(
        item => !item.startsWith(`-`) || [`-`, `"-"`, `"-`].includes(item),
      )

      let includeWordsString = ""
      let excludeWordsString = ""

      const excludeItems = excludeList?.map(phrase => {
        let truncatedPhrase = phrase.replace(/['"]+/g, "")
        truncatedPhrase = truncatedPhrase.startsWith(`-`)
          ? truncatedPhrase.replace("-", "")
          : truncatedPhrase
        excludeWordsString = excludeWordsString + ` "${truncatedPhrase}"`
        return { excludeBy: truncatedPhrase }
      })

      const includeItems = includeList
        ?.map(phrase => {
          const truncatedPhrase = phrase.replace(/['"]+/g, "")
          if (truncatedPhrase && truncatedPhrase.trim() !== "") {
            includeWordsString = includeWordsString + ` "${truncatedPhrase}"`
            return { searchBy: truncatedPhrase }
          }
        })
        .filter(a => Boolean(a))

      setIncludeWordsRestore(includeWordsString)
      setExcludeWordsRestore(excludeWordsString)
      setExcludeWords(excludeWordsString)
      setIncludeWords(includeWordsString)

      payload.filterBy =
        (Array.isArray(excludeItems) && excludeItems.length > 0) ||
        (Array.isArray(includeItems) && includeItems.length > 0)
          ? [...includeItems, ...excludeItems]
          : []
    } else {
      setIncludeWords("")
      setExcludeWords("")
    }

    if (filterUsageType.length > 0 && !usageType.all) {
      const searchFound = payload?.filterBy?.find(
        o => o["searchBy"] || o["excludeBy"],
      )
      if (searchFound && (searchFound.searchBy || searchFound.excludeBy)) {
        payload.filterBy.push({
          usageTypes: filterUsageType.map(i => i.searchName),
        })
      } else {
        payload.filterBy = [
          { usageTypes: filterUsageType.map(i => i.searchName) },
        ]
      }
    }

    if (selectAll) {
      payload.selectAll = true
    }

    if (showSelected) {
      let projectsSetIdUsageType = []
      if (selectedProjects.length > 0) {
        const projectsSelected = selectedProjects.map(x => {
          return { projectSetId: x.projectSetId, usageType: x.usageType }
        })
        projectsSetIdUsageType = [{ projectSetIds: projectsSelected }]
      }
      payload.filterBy = projectsSetIdUsageType
    }

    axios
      .post(`${REPORTING_API}/t120/user/${userId}/project`, payload)
      .then(response => {
        const { records, totalTableCount } = response.data.results

        // show top 5 results selected
        const recordsTruncated = selectAll ? records : records.slice(0, 5)

        if (selectAll || firstLoad) {
          setSelectedProjects(recordsTruncated)
        }

        // If selectAll is checked, then records.length > pagination limit
        // so clamp data to pagination limit
        setData(records)
        setTotalRows(totalTableCount)
        setProjectLoading(false)
        setFirstload(false)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
        setProjectLoading(false)
        setError(true)
      })
      .finally(() => {
        if (!firstLoad) searchBarRef.current.handleFocusFromParent()
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dates,
    sortBy,
    offset,
    limit,
    selectAll,
    showSelected,
    searchBy,
    filterUsageType,
  ])

  const handleSelectAll = event => {
    setProjectLoading(true)
    setSelectAll(event.target.checked)
    event.target.checked ? setOffset(0) : setSelectedProjects([])
  }

  const handleSearchChange = value => {
    setSearchBy(value)
    setSelectAll(false)
    value ? setProjectLoading(true) : setProjectLoading(false)
    setOffset(0)
    searchBarRef.current.handleFocusFromParent()
  }

  const handleSort = key => {
    setSelectAll(false)
    setOffset(0)
    const value = key === sortBy.key && sortBy.value === "asc" ? "desc" : "asc"

    setSortBy({ key, value })
    changeSortOrder({ key, value })
  }

  const handleChangePage = (_event, newPage) => {
    if (selectAll) {
      // TODO
    }
    setOffset(newPage * limit)
  }

  const handleChangeRowsPerPage = ({ target: { value } }) => {
    // TODO clean this up
    if (!error && !loading) {
      setOffset(0)
      setLimit(value)
    }
  }

  const handleShowSelected = _ => {
    const next = !showSelected
    setShowSelected(next)
    if (next) {
      setOffset(0)
    }
    setProjectLoading(true)
  }

  const selectProject = project => {
    setSelectAll(false)
    onSelectProject(project)
  }

  const isSelected = ({ projectSetId, usageType }) => {
    const index = selectedProjects.findIndex(
      x => x.projectSetId === projectSetId && x.usageType === usageType,
    )
    return index === -1 ? false : index >= 0 ? true : null
  }

  const handleKeyPress = event => {
    if (event.key === "Enter") {
      applyFilterChanges()
    }
  }

  const handleIncludeWordsChange = event => {
    setIncludeWords(event.target.value)
  }

  const handleExcludeWordsChange = event => {
    setExcludeWords(event.target.value)
  }

  const handleUsageChange = e => {
    const { checked, name } = e.target
    const list = usageType.list.map(item =>
      item.name === name ? { ...item, isChecked: checked } : item,
    )
    setUsageType({ list })
  }

  const handleUsageClear = (_ev, filter) => {
    // Uncheck the checkboxes
    const list = usageType.list.map(item =>
      item.name === filter ? { ...item, isChecked: false } : item,
    )
    setUsageType({ list })
    // modify usage payload
    const newList = filterUsageType.filter(item => item.name !== filter)
    setFilterUsageType(newList)
  }

  const clearFilter = () => {
    const list = usageType.list.map(item => ({ ...item, isChecked: false }))
    setUsageType({ list })
    setIncludeWords("")
    setExcludeWords("")
  }

  const filterDefaults = () => {
    // for the filter
    if (filterUsageType.length > 0) {
      const mergeUsageType = usageType.list.map(item =>
        filterUsageType.some(i => i.name.includes(item.name))
          ? { ...item, isChecked: true }
          : item,
      )
      const list = Object.entries(mergeUsageType).map(e => e[1])
      setUsageType({ list: list })
    } else {
      const list = usageType.list.map(item => ({ ...item, isChecked: false }))
      setUsageType({ list })
    }
    // Restore search if cleared in the filter box
    if (searchBy !== "") {
      setIncludeWords(includeWordsRestore)
      setExcludeWords(excludeWordsRestore)
    }
  }

  const applyFilterChanges = () => {
    setSelectAll(false)
    let newSearchBy = ""

    if (includeWords !== "") {
      const results = includeWords
        .match(regextoParseSearch)
        .filter(item => item !== " ")

      const includeList = results.filter(
        item => !item.startsWith(`-`) || [`-`, `"-"`, `"-`].includes(item),
      )
      let includeWordsString = ""

      includeList.forEach(phrase => {
        const truncatedPhrase = phrase.replace(/['"]+/g, "")
        if (truncatedPhrase === `-`) {
          includeWordsString = includeWordsString + ` ${truncatedPhrase}`
        } else {
          includeWordsString = includeWordsString + ` "${truncatedPhrase}"`
        }
      })
      newSearchBy = newSearchBy + `${includeWordsString}`
    }

    if (excludeWords !== "") {
      const results = excludeWords
        .match(regextoParseSearch)
        .filter(item => item !== " ")

      const excludeList = results.filter(
        item =>
          (item.includes(`-`) && !item.startsWith(`-`)) ||
          ![`-`, `-"`, `"-`, `-""`, `"-"`, ` -""`].includes(item),
      )
      let excludeWordsString = ""

      excludeList.forEach(phrase => {
        const truncatedPhrase = phrase.replace(/['"]+/g, "")
        excludeWordsString = excludeWordsString + ` -"${truncatedPhrase}"`
      })
      newSearchBy = newSearchBy + `${excludeWordsString}`
    }

    setFilterUsageType(usageType.list.filter(item => item.isChecked))
    setSearchBy(newSearchBy)
    // Apply clear text box
    if ((excludeWords === "" && includeWords === "") || excludeWords === "-") {
      searchBarRef.current.handleClearFromParent()
    }
    setFilterDialog(false)
  }

  const cancelFilter = () => {
    searchBarRef.current.handleFocusFromParent()
    setFilterDialog(false)
  }

  const UsageTypeIcons = ({ icon, tooltip, useCloseIcon = false }) => {
    const closeIcon = useCloseIcon
      ? ICONS[icon]?.removeDisplayName
      : ICONS[icon]?.displayName
    const iconName = closeIcon ? closeIcon : icon
    return (
      <>
        <Tooltip title={tooltip ? iconName : null} placement="right-end">
          {ICONS[icon] ? (
            <img
              style={{ width: "20px", marginRight: "10px" }}
              src={
                useCloseIcon
                  ? ICONS[icon]?.imageRemoveLink
                  : ICONS[icon]?.imageLink
              }
              alt={ICONS[icon]?.displayName}
            />
          ) : (
            <GenericLicenseIcon />
          )}
        </Tooltip>
      </>
    )
  }

  const Body = ({ tableData }) => {
    if (loading || error) {
      return (
        <TableRow style={{ height: 33 * limit }}>
          <TableCell padding="checkbox" align="center" colSpan={4}>
            {loading ? (
              <CircularProgress />
            ) : (
              <Typography variant="body1">
                Sorry an error occurred fetching
                <br />
                data for the project report
              </Typography>
            )}
          </TableCell>
        </TableRow>
      )
    }

    if (tableData.length === 0) {
      return (
        <TableRow style={{ height: 33 * limit }}>
          <TableCell padding="checkbox" align="center" colSpan={4}>
            <Typography variant="body1">
              No project data found for date range
            </Typography>
          </TableCell>
        </TableRow>
      )
    }

    return (
      <>
        {tableData.map((project, i) => (
          <TableRow key={i} className="slowfadein">
            <TableCell>
              <IconButton
                className={classes.iconButton}
                onClick={() => selectProject(project)}
              >
                {isSelected(project) ? (
                  <CheckBoxIcon color="primary" className={classes.icon} />
                ) : (
                  <CheckBoxOutlineBlankIcon className={classes.icon} />
                )}
              </IconButton>
            </TableCell>
            <TableCell style={{ fontSize: 14 }}>
              {project.projectName}
            </TableCell>
            <TableCell style={{ fontSize: 14 }}>
              <UsageTypeIcons icon={project.usageType} tooltip={true} />
            </TableCell>
            <TableCell align="right">
              {project.wordCount.toLocaleString()}
            </TableCell>
          </TableRow>
        ))}
        {tableData.length < limit && (
          <TableRow style={{ height: 33 * (limit - tableData.length) }}>
            <TableCell colSpan={4} />
          </TableRow>
        )}
      </>
    )
  }

  const filterStateActive = filterUsageType.length > 0 || searchBy !== ""
  const visibleData = showSelected
    ? data.filter(isSelected)
    : selectAll
    ? data.slice(offset, offset + limit)
    : data

  return (
    <>
      <Grid container justify="space-between" alignItems="center">
        <Grid item xs={6}>
          <Typography variant="h6" className={classes.subtitle}>
            Project List
          </Typography>
        </Grid>
        <Grid item xs={6} style={{ paddingRight: "15px" }}>
          <Box borderRadius="14px" style={{ border: "1px solid #979797" }}>
            <Searchbar
              ref={searchBarRef}
              placeholder="Search by project name, project owner"
              disabled={projectLoading}
              onChange={handleSearchChange}
              value={searchBy}
              style={{ color: "red" }}
            />
          </Box>
        </Grid>
      </Grid>
      <Box m={2} />
      <Paper elevation={3} className={projectPaper}>
        <Toolbar className={classes.toolbar}>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    icon={
                      <CheckBoxOutlineBlankIcon
                        fontSize="small"
                        className={classes.icon}
                      />
                    }
                    checkedIcon={
                      <CheckBoxIcon fontSize="small" className={classes.icon} />
                    }
                    onChange={handleSelectAll}
                    checked={selectAll}
                    disabled={projectLoading}
                  />
                }
                label={<Typography variant="body2">Select all</Typography>}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    icon={
                      <CheckBoxOutlineBlankIcon
                        fontSize="small"
                        className={classes.icon}
                      />
                    }
                    checkedIcon={
                      <CheckBoxIcon fontSize="small" className={classes.icon} />
                    }
                    onChange={handleShowSelected}
                    checked={showSelected}
                  />
                }
                label={<Typography variant="body2">Show selected</Typography>}
                disabled={selectAll || projectLoading}
              />
            </Grid>
            <Grid item>
              {/*filterUsageType.length > 0
                ? filterUsageType.map(i => (
                    <>
                      <IconButton
                        className={classes.iconButton}
                        aria-label="Close"
                        onClick={event => handleUsageClear(event, i.name)}
                      >
                        <UsageTypeIcons
                          icon={i.searchName}
                          tooltip={true}
                          useCloseIcon={true}
                        />
                      </IconButton>
                    </>
                  ))
                : null*/}
              <Tooltip title="Advanced search and filter" placement="top">
                <IconButton
                  aria-label="Filter List"
                  size="small"
                  onClick={() => setFilterDialog(true)}
                  style={
                    filterStateActive
                      ? { color: "#ff9800" }
                      : { color: "#0000008a" }
                  }
                >
                  <FilterListIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>

          <Dialog
            open={filterDialog}
            onEnter={filterDefaults}
            aria-labelledby="filter-dialog-title"
            maxWidth="md"
            fullWidth={true}
          >
            <Grid container>
              <Grid item xs={12} md={8}>
                <DialogTitle id="filter-dialog-title">
                  Find projects with...
                </DialogTitle>
                <DialogContent>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={4}>
                      All these words:
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        value={includeWords}
                        variant="outlined"
                        fullWidth
                        autoFocus
                        onChange={handleIncludeWordsChange}
                        inputProps={{ style: { padding: 7 } }}
                        onKeyDown={handleKeyPress}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      None of these words:
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        value={excludeWords}
                        variant="outlined"
                        fullWidth
                        onChange={handleExcludeWordsChange}
                        inputProps={{ style: { padding: 7 } }}
                        onKeyDown={handleKeyPress}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      By usage type:
                    </Grid>
                    <Grid item xs={8}>
                      {usageType.list.map(i => (
                        <>
                          <FormControlLabel
                            control={
                              <Checkbox
                                color="primary"
                                icon={
                                  <CheckBoxOutlineBlankIcon fontSize="small" />
                                }
                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                name={i.name}
                                value={i.name}
                                checked={i.isChecked}
                                onChange={handleUsageChange}
                              />
                            }
                            label={i.name}
                          />
                        </>
                      ))}
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Grid
                    container
                    justify="space-between"
                    style={{
                      padding: "15px",
                    }}
                  >
                    <Grid item>
                      <Button variant="outlined" onClick={clearFilter}>
                        Clear
                      </Button>
                    </Grid>
                    <Grid item>
                      <Grid container>
                        <Grid item justify="flex-start">
                          <Button onClick={cancelFilter}>Cancel</Button>
                        </Grid>
                        <Grid item justify="flex-end">
                          <Button
                            onClick={applyFilterChanges}
                            variant="contained"
                            color="primary"
                          >
                            Apply
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </DialogActions>
              </Grid>
              <Grid item xs={12} md={1} className={classes.divider}>
                <Divider
                  orientation="vertical"
                  flexitem="true"
                  style={{
                    width: "2px",
                    backgroundColor: "rgb(0, 0, 0)",
                  }}
                />
              </Grid>

              <Grid item xs={12} md={3} className={classes.helperText}>
                <Box m={0.5} />
                <Box p={1}>
                  <List className={classes.fixedList}>
                    <Typography component="div">
                      <b>To filter your search here:</b>
                    </Typography>
                    <ListItem
                      className={classes.fixedListItem}
                      alignItems="flex-start"
                    >
                      <ListItemIcon className={classes.listIcon}>
                        <FiberManualRecordIcon className={classes.bulletIcon} />
                      </ListItemIcon>
                      <ListItemText className={classes.listText}>
                        <Typography variant="body2">
                          Type text that you want included in the search results
                          in the “All these words” box.
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <ListItem
                      className={classes.fixedListItem}
                      alignItems="flex-start"
                    >
                      <ListItemIcon className={classes.listIcon}>
                        <FiberManualRecordIcon className={classes.bulletIcon} />
                      </ListItemIcon>
                      <ListItemText className={classes.listText}>
                        <Typography variant="body2">
                          Type text that you want excluded in the “None of these
                          words” box.
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <ListItem
                      className={classes.fixedListItem}
                      alignItems="flex-start"
                    >
                      <ListItemIcon className={classes.listIcon}>
                        <FiberManualRecordIcon className={classes.bulletIcon} />
                      </ListItemIcon>
                      <ListItemText className={classes.listText}>
                        <Typography variant="body2">
                          Place quotes around phrases.
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <ListItem
                      className={classes.fixedListItem}
                      alignItems="flex-start"
                    >
                      <ListItemIcon className={classes.listIcon}>
                        <FiberManualRecordIcon className={classes.bulletIcon} />
                      </ListItemIcon>
                      <ListItemText className={classes.listText}>
                        <Typography variant="body2">
                          Words can be actual words, partial words, or strings
                          of letters and/or numbers.
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <Box m={1} />
                    <Typography component="div">
                      <b>
                        Using the search bar when advanced search is closed:{" "}
                      </b>
                    </Typography>
                    <ListItem
                      className={classes.fixedListItem}
                      alignItems="flex-start"
                    >
                      <ListItemIcon className={classes.listIcon}>
                        <FiberManualRecordIcon className={classes.bulletIcon} />
                      </ListItemIcon>
                      <ListItemText className={classes.listText}>
                        <Typography variant="body2">
                          The same rules above can be used in the search bar
                          directly. <br />
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <ListItem className={classes.fixedListItem}>
                      <ListItemText className={classes.listTextIndented}>
                        <Typography variant="body2">Examples:</Typography>
                      </ListItemText>
                    </ListItem>
                    <ListItem
                      className={classes.fixedListItem}
                      alignItems="flex-start"
                    >
                      <ListItemText className={classes.listTextIndent}>
                        <List>
                          <ListItem
                            className={
                              (classes.fixedListItem,
                              classes.fixedListItemIndented)
                            }
                            alignItems="flex-start"
                          >
                            <ListItemIcon className={classes.listIcon}>
                              <FiberManualRecordIcon
                                className={classes.bulletIcon}
                              />
                            </ListItemIcon>
                            <ListItemText className={classes.listText}>
                              <Typography variant="body2">
                                "live project”
                              </Typography>
                            </ListItemText>
                          </ListItem>
                          <ListItem
                            className={
                              (classes.fixedListItem,
                              classes.fixedListItemIndented)
                            }
                            alignItems="flex-start"
                          >
                            <ListItemIcon className={classes.listIcon}>
                              <FiberManualRecordIcon
                                className={classes.bulletIcon}
                              />
                            </ListItemIcon>
                            <ListItemText className={classes.listText}>
                              <Typography variant="body2">
                                live project
                              </Typography>
                            </ListItemText>
                          </ListItem>
                          <ListItem
                            className={
                              (classes.fixedListItem,
                              classes.fixedListItemIndented)
                            }
                            alignItems="flex-start"
                          >
                            <ListItemIcon className={classes.listIcon}>
                              <FiberManualRecordIcon
                                className={classes.bulletIcon}
                              />
                            </ListItemIcon>
                            <ListItemText className={classes.listText}>
                              <Typography variant="body2">
                                "live" "project"
                              </Typography>
                            </ListItemText>
                          </ListItem>
                        </List>
                      </ListItemText>
                    </ListItem>
                    <ListItem
                      className={classes.fixedListItem}
                      alignItems="flex-start"
                    >
                      <ListItemIcon className={classes.listIcon}>
                        <FiberManualRecordIcon className={classes.bulletIcon} />
                      </ListItemIcon>
                      <ListItemText className={classes.listText}>
                        <Typography variant="body2">
                          Put a minus (-) in front of strings or phrases you
                          want to exclude. <br />
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <ListItem className={classes.fixedListItem}>
                      <ListItemText className={classes.listTextIndented}>
                        <Typography variant="body2">Examples:</Typography>
                      </ListItemText>
                    </ListItem>
                    <ListItem className={classes.fixedListItem}>
                      <ListItemText className={classes.listTextIndent}>
                        <List>
                          <ListItem
                            className={
                              (classes.fixedListItem,
                              classes.fixedListItemIndented)
                            }
                            alignItems="flex-start"
                          >
                            <ListItemIcon className={classes.listIcon}>
                              <FiberManualRecordIcon
                                className={classes.bulletIcon}
                              />
                            </ListItemIcon>
                            <ListItemText className={classes.listText}>
                              <Typography variant="body2">
                                -test -server
                              </Typography>
                            </ListItemText>
                          </ListItem>
                          <ListItem
                            className={
                              (classes.fixedListItem,
                              classes.fixedListItemIndented)
                            }
                            alignItems="flex-start"
                          >
                            <ListItemIcon className={classes.listIcon}>
                              <FiberManualRecordIcon
                                className={classes.bulletIcon}
                              />
                            </ListItemIcon>
                            <ListItemText className={classes.listText}>
                              <Typography variant="body2">
                                -"test server"
                              </Typography>
                            </ListItemText>
                          </ListItem>
                          <ListItem
                            className={
                              (classes.fixedListItem,
                              classes.fixedListItemIndented)
                            }
                            alignItems="flex-start"
                          >
                            <ListItemIcon className={classes.listIcon}>
                              <FiberManualRecordIcon
                                className={classes.bulletIcon}
                              />
                            </ListItemIcon>
                            <ListItemText className={classes.listText}>
                              <Typography variant="body2">
                                -"test" -"server"
                              </Typography>
                            </ListItemText>
                          </ListItem>
                        </List>
                      </ListItemText>
                    </ListItem>
                  </List>
                </Box>
                <Box m={1} />
              </Grid>
            </Grid>
          </Dialog>
        </Toolbar>

        <Divider />
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortBy.key === "projectName"}
                  direction={sortBy.value}
                  onClick={() => handleSort("projectName")}
                >
                  Project name
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <Tooltip
                  title="Open filter to select usage types to view"
                  placement="top"
                >
                  <TableSortLabel hideSortIcon>Usage type</TableSortLabel>
                </Tooltip>
              </TableCell>
              <TableCell align="right">
                <TableSortLabel
                  active={sortBy.key === "wordCount"}
                  direction={sortBy.value}
                  onClick={() => handleSort("wordCount")}
                >
                  Word count
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <Body tableData={visibleData} />
          </TableBody>
        </Table>
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <DatePicker
              disabled={projectLoading}
              dates={dates}
              onDatesChange={onDatesChange}
              style={{
                fontSize: "0.875rem",
                fontWeight: "400",
                lineHeight: "1.43",
                letterSpacing: "0.01071em",
                color: "rgba(0, 0, 0, 0.87)",
              }}
            />
          </Grid>
          <Grid item>
            <TablePagination
              rowsPerPageOptions={[10, 20, 30]}
              component="div"
              count={totalRows}
              rowsPerPage={limit}
              page={offset !== 0 ? offset / limit : 0}
              backIconButtonProps={{
                "aria-label": "Previous Page",
              }}
              nextIconButtonProps={{
                "aria-label": "Next Page",
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Grid>
        </Grid>
      </Paper>
    </>
  )
}

const useStyles = makeStyles(theme => ({
  toolbar: {
    paddingLeft: theme.spacing(2.75),
    paddingRight: theme.spacing(1.5),
  },
  flex: {
    flex: 1,
  },
  iconButton: {
    padding: 4,
  },
  icon: {
    fontSize: 20,
  },
  fixedHeight: {
    minHeight: 535,
  },
  subtitle: {
    fontWeight: 300,
    color: "#4a4a4a",
    fontSize: "16px",
  },
  divider: {
    maxWidth: "2%",
    flexBasis: "2%",
    backgroundColor: "#f1f3f1",
    [theme.breakpoints.down("sm")]: {
      flexBasis: "0%",
      maxWidth: "0%",
    },
  },
  helperText: {
    maxWidth: "31%",
    flexBasis: "31%",
    backgroundColor: "#f1f3f1",
    [theme.breakpoints.down("sm")]: {
      flexBasis: "100%",
      maxWidth: "100%",
      paddingLeft: "20px",
    },
  },
  fixedList: {
    width: "100%",
    backgroundColor: "#f1f3f1",
    position: "relative",
    overflow: "auto",
    maxHeight: 270,
    // keep scrollbar appearing
    "&::-webkit-scrollbar": {
      "-webkit-appearance": "none",
      width: "7px",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "4px",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      "-webkit-box-shadow": "0 0 1px rgba(255, 255, 255, 0.5)",
    },
  },
  fixedListItem: {
    paddingLeft: 0,
    paddingRight: "5px",
  },
  fixedListItemIndented: {
    paddingBottom: "2px",
    paddingTop: "2px",
  },
  listTextIndent: {
    marginLeft: "8px",
    marginTop: "-20px",
    marginBottom: "-10px",
  },
  listText: {
    margin: 0,
  },
  listTextIndented: {
    marginLeft: "26px",
    marginTop: "-5px",
  },
  listIcon: {
    minWidth: "25px",
    marginTop: "5px",
  },
  bulletIcon: {
    fontSize: "10px",
    color: "#000000",
  },
}))

export default withContext(Projects)
