import React from "react"
import { TablePagination } from "@material-ui/core"

const id = x => x
const defaultRowsPerPageOptions = [10, 20, 30]

export default ({
  data,
  rowCount = 0,
  className = "pagination__hide-count",
  offset,
  rowsPerPageOptions = defaultRowsPerPageOptions,
  component = "div",
  rowsPerPage = defaultRowsPerPageOptions[0],
  onChangePage = id,
  onChangeRowsPerPage = id,
  ...otherProps
}) => {
  const page = offset !== 0 ? offset / rowsPerPage : 0

  // This is a hack to get around the lack of a count of total items.
  // Setting count manually to trick `TablePagination` into thinking there's
  // enough pages to be able to click next, but no further
  const count = rowCount
    ? rowCount
    : !data
    ? 0
    : data?.length <= rowsPerPage
    ? data.length
    : (page + 1) * rowsPerPage + 1

  return (
    <TablePagination
      className={className}
      rowsPerPageOptions={rowsPerPageOptions}
      component={component}
      rowsPerPage={rowsPerPage}
      count={count}
      page={page}
      backIconButtonProps={{ "aria-label": "Previous Page" }}
      nextIconButtonProps={{ "aria-label": "Next Page" }}
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangeRowsPerPage}
      {...otherProps}
    />
  )
}
