import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { withContext } from "../../../../Utils/context"
import { maxCharLength } from "../../../../Utils/helpers"
import axios from "axios"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Fab,
  FormControl,
  InputLabel,
  Select,
  Input,
  MenuItem,
  Button,
  CircularProgress,
  Typography,
} from "@material-ui/core"
import AddIcon from "@material-ui/icons/Add"
import { useHistory } from "react-router-dom"

const ORG_API = process.env.REACT_APP_ORG_URL

const useStyles = makeStyles(theme => ({
  icon: {
    marginRight: theme.spacing(1),
    fontSize: ".8em",
  },
  progressWrap: {
    padding: theme.spacing(6),
  },
  progress: {
    position: "absolute",
    left: "50%",
  },
  addIcon: {
    fontSize: "1em",
  },
}))

const checkName = searchTerm =>
  axios
    .post(ORG_API, {
      limit: 1,
      offset: 0,
      sortBy: { key: "displayName", value: "asc" },
      searchBy: searchTerm,
    })
    .catch(console.error)
    .then(res => res?.data?.records)
    .then(companies =>
      companies?.some(
        c => c.displayName.toLowerCase() === searchTerm.toLowerCase(),
      ),
    )

const useDebounce = (searchTerm, delay = 1000) => {
  const [debouncedVal, setDebouncedVal] = React.useState(searchTerm)

  React.useEffect(() => {
    const timerRef = setTimeout(() => setDebouncedVal(searchTerm), delay)

    return () => clearTimeout(timerRef)
  }, [searchTerm, delay])

  return debouncedVal
}

function CreationModal() {
  const classes = useStyles()
  const history = useHistory()
  const [open, setOpen] = React.useState(false)
  const [displayName, setDisplayName] = React.useState("")
  const [accountStatus, setAccountStatus] = React.useState("ACTIVE")
  const [loading, setLoading] = React.useState(false)
  const [checkingDuplicate, setCheckingDuplicate] = React.useState(false)
  const [error, setError] = React.useState(false)
  const [displayNameMinLength, setDisplayNameMinLength] = React.useState(false)
  const [displayNameMaxLength, setDisplayNameMaxLength] = React.useState(false)
  const [displayNameMatch, setDisplayNameMatch] = React.useState(false)

  const debouncedDisplayName = useDebounce(displayName, 500)

  const toggleDialog = () => setOpen(!open)

  React.useEffect(() => {
    const updateDuplicateStatus = () => {
      if (!debouncedDisplayName) {
        setCheckingDuplicate(false)
        return
      }

      // reset all error msg state
      setDisplayNameMinLength(false)
      setDisplayNameMaxLength(false)
      setDisplayNameMatch(false)

      if (debouncedDisplayName.length > maxCharLength) {
        setDisplayNameMaxLength(true)
        return
      }

      if (debouncedDisplayName.length < 2) {
        setDisplayNameMinLength(true)
        return
      }

      checkName(debouncedDisplayName)
        .then(setDisplayNameMatch)
        .then(_ => setCheckingDuplicate(false))
    }

    updateDuplicateStatus()
  }, [debouncedDisplayName])

  const handleNameChange = ev => {
    const val = ev.target.value
    setCheckingDuplicate(true)
    setDisplayName(val)
  }

  const handleStatusChange = ({ target: { value } }) => setAccountStatus(value)

  const handleCreate = () => {
    setLoading(true)
    setError(false)
    axios
      .post(`${ORG_API}/create`, {
        displayName: debouncedDisplayName.trim(),
        accountStatus,
      })
      .then(({ data }) => history.push(`/company/${data.organisationId}`))
      .catch(error => {
        setLoading(false)
        setError(true)
        console.error(error)
      })
  }

  const createDisabled =
    displayNameMatch ||
    displayNameMinLength ||
    displayNameMaxLength ||
    loading ||
    checkingDuplicate

  const displayNameErrorMsg = displayNameMatch
    ? "A company with this name already exists."
    : displayNameMinLength
    ? "Company name cannot have less than 2 characters."
    : displayNameMaxLength
    ? `Company name cannot have more than ${maxCharLength} characters.`
    : false

  return (
    <React.Fragment>
      <Fab
        variant="extended"
        color="primary"
        size="small"
        className={classes.icon}
        onClick={toggleDialog}
      >
        <AddIcon fontSize="small" className={classes.addIcon} />
        Create Company
      </Fab>
      <Dialog
        open={open}
        onClose={toggleDialog}
        aria-labelledby="form-dialog-title"
        aria-describedby="form-dialog-description"
        maxWidth="xs"
      >
        <DialogTitle id="form-dialog-title">Create Company</DialogTitle>
        {loading ? (
          <div className={classes.progressWrap}>
            <CircularProgress className={classes.progress} />
          </div>
        ) : (
          <React.Fragment>
            {error ? (
              <Typography variant="body1">
                Sorry an error occurred creating the company
              </Typography>
            ) : (
              <DialogContent>
                <DialogContentText
                  id="form-dialog-description"
                  style={{ wordBreak: "unset" }}
                >
                  To create a company, enter a name and select an account status
                </DialogContentText>
                <TextField
                  id="displayName"
                  label="Company name"
                  error={
                    !!displayNameMatch ||
                    !!displayNameMinLength ||
                    !!displayNameMaxLength
                  }
                  helperText={displayNameErrorMsg}
                  value={displayName}
                  name="displayName"
                  onChange={handleNameChange}
                  margin="normal"
                  fullWidth
                  required
                  variant="outlined"
                />
                <FormControl fullWidth required margin="normal">
                  <InputLabel htmlFor="status-helper">
                    Account status
                  </InputLabel>
                  <Select
                    value={accountStatus}
                    name="accountStatus"
                    onChange={handleStatusChange}
                    input={<Input name="status" id="status-helper" />}
                  >
                    <MenuItem value="ACTIVE">Active</MenuItem>
                    <MenuItem value="DISABLED">Disabled</MenuItem>
                    <MenuItem value="SUSPENDED">Suspended</MenuItem>
                  </Select>
                </FormControl>
              </DialogContent>
            )}
          </React.Fragment>
        )}
        <DialogActions>
          <Button onClick={toggleDialog} size="small" disabled={loading}>
            Cancel
          </Button>
          <Button
            onClick={handleCreate}
            variant="contained"
            color="primary"
            size="small"
            disabled={createDisabled}
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default withContext(CreationModal)
