import { Button, Divider, Grid, IconButton, TextField } from "@material-ui/core"
import { DatePicker } from "@material-ui/pickers"
import { makeStyles } from "@material-ui/styles"
import clsx from "clsx"
import {
  format,
  startOfDay,
  isSameDay,
  isValid,
  isFuture,
  isAfter,
  isBefore,
  toDate,
} from "date-fns"
import React, { useEffect, useState } from "react"
import ReactTextMask from "react-text-mask"

const nowUTC = date => {
  let now = date ? new Date(date) : new Date()
  now.setTime(now.getTime() + now.getTimezoneOffset() * 60 * 1000)
  return now
}

const MaskedInput = ({ inputRef, ...other }) => (
  <ReactTextMask
    {...other}
    ref={ref => inputRef(ref ? ref.inputElement : null)}
    mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
    placeholderChar={"\u2000"}
    keepCharPositions
    guide
  />
)

const DateRangePicker = ({ initialDateRange, minDate, onAccept, onCancel }) => {
  const classes = useStyles()
  const { startDate, endDate } = initialDateRange

  const [pickerStartDate, setPickerStartDate] = useState(startDate)
  const [pickerEndDate, setPickerEndDate] = useState(endDate)

  const [inputStartDate, setInputStartDate] = useState(
    format(startDate, "MM/dd/yyyy"),
  )
  const [inputEndDate, setInputEndDate] = useState(
    format(endDate, "MM/dd/yyyy"),
  )

  const [selectStart, setSelectStart] = useState(true)
  const [endDateValid, setEndDateValid] = useState(false)
  const [startDateValid, setStartDateValid] = useState(false)
  const [invalidInputStartDate, setInvalidInputStartDate] = useState({
    invalidDate: false,
    invalidDateRange: false,
    invalidStartDate: false, //date before start date
  })

  const [invalidInputEndDate, setInvalidInputEndDate] = useState({
    invalidDate: false,
    invalidDateRange: false,
    invalidEndDate: false, // date after start date
  })

  useEffect(() => {
    const isEndDateValid =
      !invalidInputEndDate.invalidDate &&
      !invalidInputEndDate.invalidDateRange &&
      !invalidInputEndDate.invalidEndDate

    const isStartDateValid =
      !invalidInputStartDate.invalidDate &&
      !invalidInputStartDate.invalidDateRange &&
      !invalidInputStartDate.invalidStartDate

    setEndDateValid(isEndDateValid)
    setStartDateValid(isStartDateValid)
  }, [inputEndDate, inputStartDate, invalidInputStartDate, invalidInputEndDate])

  const renderDay = (date, renderSelectedDate, dayInCurrentMonth) => {
    let dateClone = new Date(date)
    let newStartClone = new Date(pickerStartDate)
    let newEndClone = new Date(pickerEndDate)
    let start = startOfDay(newStartClone)
    let end = startOfDay(newEndClone)

    const dayIsBetween = dateClone >= start && dateClone <= end
    const isFirstDay = isSameDay(dateClone, newStartClone)
    const isLastDay = isSameDay(dateClone, newEndClone)
    const isFutureDay = nowUTC() < dateClone

    const wrapperClassName = clsx(classes.dayWrapper, {
      [classes.highlight]: dayIsBetween && !isFutureDay,
      [classes.firstHighlight]: isFirstDay,
      [classes.endHighlight]: isLastDay,
    })

    const dayClassName = clsx(classes.day, {
      [classes.nonCurrentMonthDay]: !dayInCurrentMonth || isFutureDay,
      [classes.highlightNonCurrentMonthDay]: !dayInCurrentMonth && dayIsBetween,
      [classes.highlight]: dayIsBetween && !isFutureDay,
      [classes.firstSelection]:
        (isFirstDay && !isFutureDay) || (isLastDay && !isFutureDay),
    })

    return (
      <div className={classes.topDayWrapper}>
        <div className={wrapperClassName}>
          <IconButton className={dayClassName}>
            <span> {format(dateClone, "d")} </span>
          </IconButton>
        </div>
      </div>
    )
  }

  const handleInputChange = name => e => {
    const { value } = e.target
    const date = toDate(new Date(value))
    const isStartDate = name === "START_DATE"
    const isEndDate = name === "END_DATE"
    const isDateValid =
      isValid(date) && value && value.match(/\d/g).length === 8

    if (isStartDate) setInputStartDate(value)
    if (isEndDate) setInputEndDate(value)

    // invalid date
    if (!isDateValid) {
      isStartDate &&
        setInvalidInputStartDate({
          ...invalidInputStartDate,
          invalidDate: true,
        })
      isEndDate &&
        setInvalidInputEndDate({
          ...invalidInputEndDate,
          invalidDate: true,
        })
      return
    }

    const validDate = toDate(new Date(value))
    const isBetweenValidRange =
      !isFuture(validDate) && !isBefore(validDate, minDate)
    const startDateAfterEndDate = isAfter(
      validDate,
      toDate(new Date(inputEndDate)),
    )
    const endDateBeforeStartDate = isBefore(
      validDate,
      toDate(new Date(inputStartDate)),
    )
    if (!isBetweenValidRange && isStartDate)
      setInvalidInputStartDate({
        ...invalidInputStartDate,
        invalidDateRange: true,
        invalidDate: false,
      })

    if (!isBetweenValidRange && isEndDate)
      setInvalidInputEndDate({
        ...invalidInputEndDate,
        invalidDateRange: true,
        invalidDate: false,
      })

    if (startDateAfterEndDate && isStartDate)
      setInvalidInputStartDate({
        invalidDateRange: false,
        invalidStartDate: true,
        invalidDate: false,
      })

    if (endDateBeforeStartDate && isEndDate)
      setInvalidInputEndDate({
        invalidDateRange: false,
        invalidEndDate: true,
        invalidDate: false,
      })

    if (!startDateAfterEndDate && isBetweenValidRange && isStartDate) {
      setInvalidInputStartDate({
        invalidDateRange: false,
        invalidDate: false,
        invalidStartDate: false,
      })
      if (invalidInputEndDate.invalidEndDate) {
        setInvalidInputEndDate({
          ...invalidInputEndDate,
          invalidEndDate: false,
        })
        setInputEndDate(inputEndDate)
        setPickerEndDate(toDate(new Date(inputEndDate)))
      }
      setInputStartDate(format(validDate, "MM/dd/yyyy"))
      setPickerStartDate(validDate)
    }
    if (!endDateBeforeStartDate && isBetweenValidRange && isEndDate) {
      setInvalidInputEndDate({
        invalidDateRange: false,
        invalidDate: false,
        invalidEndDate: false,
      })
      if (invalidInputStartDate.invalidStartDate) {
        setInvalidInputStartDate({
          ...invalidInputStartDate,
          invalidStartDate: false,
        })
        setInputStartDate(inputStartDate)
        setPickerStartDate(toDate(new Date(inputStartDate)))
      }
      setInputEndDate(format(validDate, "MM/dd/yyyy"))
      setPickerEndDate(validDate)
    }
  }

  const handlePickerDateChange = date => {
    if (!selectStart && date >= pickerStartDate) {
      setPickerEndDate(date)
      setInputEndDate(format(date, "MM/dd/yyyy"))
      setInvalidInputEndDate({
        invalidDateRange: false,
        invalidDate: false,
        invalidEndDate: false,
      })
      return
    }

    if (date <= pickerEndDate) {
      setPickerStartDate(date)
      setInputStartDate(format(date, "MM/dd/yyyy"))
      setInvalidInputStartDate({
        invalidDateRange: false,
        invalidDate: false,
        invalidEndDate: false,
      })
    }
  }

  const handleApplyPicker = () =>
    onAccept({ startDate: pickerStartDate, endDate: pickerEndDate })

  return (
    <React.Fragment>
      <Grid container className={classes.menuHeader}>
        <Grid item onClick={() => setSelectStart(true)}>
          <TextField
            label="From date (UTC)"
            value={inputStartDate}
            onFocus={() => setSelectStart(true)}
            onChange={handleInputChange("START_DATE")}
            InputProps={{ inputComponent: MaskedInput, tabIndex: "1" }}
            variant="outlined"
            margin="dense"
            helperText={
              invalidInputStartDate.invalidDate
                ? "Invalid date"
                : invalidInputStartDate.invalidDateRange
                ? "Invalid date range"
                : invalidInputStartDate.invalidStartDate
                ? "Start date cannot be after end date"
                : "MM/DD/YYYY"
            }
            error={Boolean(
              invalidInputStartDate.invalidDate ||
                invalidInputStartDate.invalidDateRange ||
                invalidInputStartDate.invalidStartDate,
            )}
            className={clsx(classes.keyboardInput, {
              [classes.selectedKeyboard]: selectStart,
            })}
          />
        </Grid>
        <Grid item>&nbsp;-&nbsp;</Grid>
        <Grid item onClick={() => setSelectStart(false)}>
          <TextField
            label="To date (UTC)"
            value={inputEndDate}
            onFocus={() => setSelectStart(false)}
            onChange={handleInputChange("END_DATE")}
            InputProps={{ inputComponent: MaskedInput, tabIndex: "1" }}
            variant="outlined"
            margin="dense"
            helperText={
              invalidInputEndDate.invalidDate
                ? "Invalid date"
                : invalidInputEndDate.invalidDateRange
                ? "Invalid date range"
                : invalidInputEndDate.invalidEndDate
                ? "End date cannot be before start date"
                : "MM/DD/YYYY"
            }
            error={Boolean(
              invalidInputEndDate.invalidDate ||
                invalidInputEndDate.invalidDateRange ||
                invalidInputEndDate.invalidEndDate,
            )}
            className={clsx(classes.keyboardInput, {
              [classes.selectedKeyboard]: !selectStart,
            })}
          />
        </Grid>
      </Grid>

      <Divider className={classes.selectionDivider} />
      {selectStart ? (
        <DatePicker
          disableToolbar
          disableFuture={true}
          minDate={minDate}
          maxDate={nowUTC()}
          variant="static"
          value={pickerStartDate}
          renderDay={renderDay}
          onChange={handlePickerDateChange}
          invalidDateMessage={"Invalid date"}
        />
      ) : (
        <DatePicker
          disableToolbar
          disableFuture={true}
          minDate={minDate}
          maxDate={nowUTC()}
          maxDateMessage={"To cannot exceed today's date"}
          variant="static"
          value={pickerEndDate}
          renderDay={renderDay}
          onChange={handlePickerDateChange}
          invalidDateMessage={"Invalid date"}
        />
      )}
      <Divider className={classes.selectionDivider} />
      <Grid container justify="flex-end">
        <Grid item>
          <Button
            tabIndex="4"
            size="small"
            className={classes.customButton}
            onClick={onCancel}
          >
            Cancel
          </Button>
        </Grid>

        <Grid item>
          <Button
            tabIndex="3"
            color="primary"
            className={clsx(classes.customButton, classes.applyButton)}
            size="small"
            onClick={handleApplyPicker}
            disabled={!endDateValid || !startDateValid}
          >
            Apply
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

const useStyles = makeStyles(theme => ({
  selectButton: {
    color: "#757575",
    marginLeft: theme.spacing(2),
    fontSize: 13,
    fontWeight: 400,
    textTransform: "none",
  },
  menuHeader: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  headerDate: {
    color: "rgba(0, 0, 0, 0.38)",
    fontSize: 11,
  },
  headerText: {
    marginBottom: theme.spacing(0.5),
  },
  selectionDivider: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
  menuItem: {
    padding: 3,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    minHeight: 0,
    fontSize: 15,
    fontWeight: 500,
  },
  selected: {
    color: "#346df1",
    backgroundColor: "#fff !important",
  },
  bottomDiv: {
    height: theme.spacing(1),
  },
  keyboardInput: {
    width: 120,
  },
  selectedKeyboard: {
    background: "#c8dcfc",
  },
  topDayWrapper: {
    margin: "2px 0px",
  },
  dayWrapper: {
    padding: "0 6px",
  },
  highlight: {
    background: "#c8dcfc",
  },
  firstHighlight: {
    extend: "highlight",
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
    paddingLeft: 0,
    marginLeft: 6,
  },
  endHighlight: {
    extend: "highlight",
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%",
    paddingRight: 0,
    marginRight: 6,
  },
  day: {
    width: 28,
    height: 28,
    fontSize: 12,
    lineHeight: 0,
    color: "inherit",
    "&.nonCurrentMonthDay": {
      color: theme.palette.text.disabled,
    },
  },
  nonCurrentMonthDay: {
    color: theme.palette.text.disabled,
  },
  highlightNonCurrentMonthDay: {
    color: "#676767",
  },
  firstSelection: {
    background: "#3466f1",
    color: theme.palette.common.white,
  },
  customButton: {
    marginBottom: theme.spacing(0.5),
    color: "#757575",
    fontSize: 15,
    fontWeight: 400,
    textTransform: "none",
  },
  applyButton: {
    marginRight: theme.spacing(1),
    color: "#2565e5",
  },
}))

DateRangePicker.defaultProps = {
  initialDateRange: {
    startDate: null,
    endDate: null,
  },
  minDate: format(new Date("01/01/2018"), "MM/dd/yyyy"),
  maxDate: format(nowUTC(), "MM/dd/yyyy"),
  onAccept: null,
  onCancel: null,
}

export default DateRangePicker
