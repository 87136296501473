import React from "react"
import { Box, Tooltip, Typography } from "@material-ui/core"
import { useTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"

export const TruncateAsRequired = ({ title, children, maxWidth }) => {
  if (!title) title = typeof children === "string" ? children : ""

  return (
    <Tooltip title={title} interactive placement="left">
      <span
        className="text-overflow-container"
        style={{ maxWidth: maxWidth ?? "100%" }}
      >
        <span className="text-overflow-content">{children}</span>
      </span>
    </Tooltip>
  )
}

// // Extra component because this is a common use case
// export const TableCellTruncated = ({ title, children, ...rest }) => (
//   <TableCell {...rest}>
//     <TruncateAsRequired title={title}>{children}</TruncateAsRequired>
//   </TableCell>
// )

// This is deprecated and should be removed.
// Please use the component above for newer cases
const TruncateAsRequiredLegacy = ({
  title = "",
  maxWidth = "20vw",
  style,
  children,
  placement = "left",
  fullWidthAt = "xs",
  ...rest
}) => {
  const theme = useTheme()
  const breakpointMatches = useMediaQuery(theme.breakpoints.down(fullWidthAt))
  // Needs to be a combination of `inline-flex` on the parent and
  // `inline-block` on the child text-node to show ellipses properly
  const maxWidthResponsive = breakpointMatches ? (maxWidth = "100%") : maxWidth
  const textNodeStyle = {
    textOverflow: "ellipsis",
    display: "inline-block",
    maxWidth: maxWidthResponsive,
    overflow: "hidden",
    whiteSpace: "nowrap",
    wordBreak: "break-all",
    ...style,
  }

  return (
    <Tooltip title={title} interactive placement={placement}>
      <Box display="inline-flex">
        <Typography style={textNodeStyle} {...rest}>
          {children}
        </Typography>
      </Box>
    </Tooltip>
  )
}

export default TruncateAsRequiredLegacy
