import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import { IconButton, InputBase } from "@material-ui/core"
import { Close as CloseIcon, Search as SearchIcon } from "@material-ui/icons"

const useStyles = makeStyles(_theme => ({
  root: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    flex: 1,
  },
  input: {
    flex: 1,
    fontSize: "14px",
  },
  iconButton: {
    padding: 5,
  },
}))

const Searchbar = forwardRef((props, ref) => {
  const { placeholder, disabled, onChange, style } = props
  const classes = useStyles({ ...style })
  const [value, setValue] = useState(props.value || "")
  const [timerId, setTimerId] = useState(null)
  const inputRef = useRef()

  useEffect(() => {
    if (props.value) {
      setValue(props.value)
    }
  }, [props.value])

  function handleChange({ target: { value } }) {
    clearTimeout(timerId)
    setValue(value)
    setTimerId(setTimeout(() => onChange(value), 1000))
  }

  function handleKeyDown({ target, key }) {
    if (key === "Enter") {
      if (target.value !== value) {
        clearTimeout(timerId)
        onChange(value)
      }
    }
  }

  function handleClear() {
    clearTimeout(timerId)
    setValue("")
    onChange("")
  }

  useImperativeHandle(ref, () => ({
    handleFocusFromParent: () => {
      const timeout = setTimeout(() => {
        if (inputRef.current) inputRef.current.focus()
      }, 1000)

      return () => {
        clearTimeout(timeout)
      }
    },
    handleClearFromParent: () => {
      handleClear()
    },
  }))

  return (
    <div className={classes.root}>
      <IconButton
        className={classes.iconButton}
        aria-label="Search"
        onClick={handleClear}
        disabled={disabled || value.length < 1}
      >
        {value.length > 0 ? <CloseIcon /> : <SearchIcon />}
      </IconButton>
      <InputBase
        inputRef={inputRef}
        className={classes.input}
        value={value}
        placeholder={placeholder || "Search"}
        inputProps={{ "aria-label": "Search" }}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        disabled={disabled}
      />
    </div>
  )
})

Searchbar.propTypes = {
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
}

export default Searchbar
