import React from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import classNames from "classnames"

import { Link } from "react-router-dom"
import { format } from "date-fns"

import { TableRow, TableCell, Chip } from "@material-ui/core"
import TruncateAsRequired from "../../../layout/TruncateAsRequired"

const nowUTC = date => {
  let now = date ? new Date(date) : new Date()
  now.setTime(now.getTime() + now.getTimezoneOffset() * 60 * 1000)
  return now
}

const PartnerTableRow = ({
  partnerId,
  displayName,
  organisationCount,
  accountStatus,
  createdDate,
}) => {
  const classes = useStyles()

  let accountStatusStyle
  switch (accountStatus) {
    case "ACTIVE":
      accountStatusStyle = classNames(classes.chip, classes.success)
      break
    case "SUSPENDED":
      accountStatusStyle = classNames(classes.chip, classes.warning)
      break
    case "DISABLED":
      accountStatusStyle = classNames(classes.chip, classes.disabled)
      break
    default:
      accountStatusStyle = classes.chip
      break
  }

  return (
    <TableRow className="slowfadein">
      <TableCell>
        <TruncateAsRequired title={displayName} maxWidth="50vw">
          <Link to={"/partner/" + partnerId}>{displayName}</Link>
        </TruncateAsRequired>
      </TableCell>

      <TableCell align="center">{organisationCount}</TableCell>
      <TableCell align="center">
        <Chip
          label={accountStatus}
          size="small"
          className={accountStatusStyle}
        />
      </TableCell>
      <TableCell align="right">
        {format(nowUTC(createdDate), "MMMM dd, yyyy")}
      </TableCell>
    </TableRow>
  )
}

const useStyles = makeStyles(theme => ({
  chip: {
    fontSize: "8pt",
    fontWeight: 500,
  },
  success: {
    backgroundColor: theme.palette.success.light,
  },
  warning: {
    backgroundColor: theme.palette.warning.light,
  },
  disabled: {
    backgroundColor: theme.palette.danger.dark,
    color: "#fff",
  },
  primary: {
    backgroundColor: theme.palette.primary.light,
    color: "#fff",
  },
  mailIcon: {
    fontSize: "16px",
    verticalAlign: "middle",
    marginRight: "4px",
    color: "rgba(0, 0, 0, 0.38)",
  },
}))

PartnerTableRow.propType = {
  partnerId: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  organisationCount: PropTypes.string.isRequired,
  accountStatus: PropTypes.string.isRequired,
  createdDate: PropTypes.string.isRequired,
}

export default PartnerTableRow
