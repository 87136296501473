const csp_auth = "csp_auth"

export default {
  defaultKey: csp_auth,
  get(key = csp_auth) {
    try {
      const jsonString = localStorage.getItem(key)
      const jsValue = JSON.parse(jsonString)
      return jsValue ? jsValue : null
    } catch (e) {
      console.warn("Localstorage access errorred", e)
      return null
    }
  },

  set(jsonLikeValue, key = csp_auth) {
    try {
      const string = JSON.stringify(jsonLikeValue)
      localStorage.setItem(key, string)
    } catch (e) {
      console.warn("Writing to localstorage errorred.", e)
    }
  },

  remove(key = csp_auth) {
    try {
      localStorage.removeItem(key)
    } catch (e) {
      console.warn("Removing item from localstorage errorred")
    }
  },
}
