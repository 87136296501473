import React from "react"

import Button from "@material-ui/core/Button"
import { Box, Container } from "@material-ui/core/"
import Typography from "@material-ui/core/Typography"

import ArrowBackIcon from "@material-ui/icons/ArrowBack"
import HomeIcon from "@material-ui/icons/Home"

import grey from "@material-ui/core/colors/grey"

export default () => (
  <Container maxWidth="sm">
    <Typography
      variant="h1"
      component="h1"
      style={{ fontSize: "10em", marginTop: "10vh", color: grey[700] }}
    >
      404
    </Typography>
    <Typography variant="h5" style={{ fontSize: "3em", color: grey[700] }}>
      This page does not exist
    </Typography>

    <Box marginTop="10px">
      <Button
        startIcon={<ArrowBackIcon fontSize="small" />}
        size="small"
        onClick={() => window.history.back()}
      >
        Previous page
      </Button>
      <Button
        startIcon={<HomeIcon fontSize="small" />}
        size="small"
        href="/"
        style={{ margin: 10 }}
      >
        Home
      </Button>
    </Box>
  </Container>
)
