import React, { useEffect, useState, useContext, useCallback } from "react"
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Typography,
  makeStyles,
} from "@material-ui/core"
import DownloadIcon from "@material-ui/icons/GetApp"
import CopyIcon from "@material-ui/icons/FileCopyOutlined"
import MailOutlineIcon from "@material-ui/icons/MailOutline"

import axios from "axios"
import { downloadFile } from "../../../../Utils/helpers"
import { AuthContext } from "../../../../Utils/context"
import { parseJwt } from "../../../../Utils/helpers"
import ls from "../../../../Utils/localStorage"

const PARTNER_API = process.env.REACT_APP_PARTNER_URL

const Shell = ({ children }) => (
  <Box p={2}>
    <Typography variant="h6">Developer Keys</Typography>
    <Divider />
    <Box m={2} />
    {children}
  </Box>
)

export const Keys = ({ partner }) => {
  const classes = useStyles()
  const { copyToClipboard } = useContext(AuthContext)
  const [user, setUser] = React.useState("")

  // type state =
  //   | { stage: 'LOADING' }
  //   | { stage: 'ERROR'   }
  //   | { stage: 'EMPTY'   }
  //   | { stage: 'SUCCESS', publicKey, token}
  const [state, setState] = useState({ stage: "LOADING" })

  useEffect(() => {
    const token = ls.get().token
    if (!token) {
      return
    }

    const { given_name, family_name, email } = parseJwt(token)
    if (given_name && family_name) {
      setUser(given_name + " " + family_name)
    } else {
      setUser(email)
    }
  }, [])

  const getKeys = useCallback(() => {
    setState({ stage: "LOADING" })
    axios
      .get(`${PARTNER_API}/${partner.id}/getPublicKeySet`)
      .then(({ data: { publicKey, token, keyId } }) =>
        setState({ stage: "SUCCESS", publicKey, token, keyId }),
      )
      .catch(e =>
        e.response?.status === 404
          ? setState({ stage: "EMPTY" })
          : setState({ stage: "ERROR" }),
      )
  }, [partner.id])

  const createKeySet = () => {
    setState({ stage: "LOADING" })
    axios
      .post(`${PARTNER_API}/${partner.id}/createKeySet`)
      .then(({ data: { publicKey, token, keyId } }) =>
        setState({ stage: "SUCCESS", publicKey, token, keyId }),
      )
      .catch(() => setState({ s: "EMPTY" }))
  }

  useEffect(() => getKeys(), [getKeys])

  const revokeKeyMailTo = `mailto:internalsupport@arria.com?subject=Request to revoke a partner key&body=${encodeURIComponent(
    `  To: Arria Platform Support

  Please initiate the key cancellation process for the following entity:

  Entity Name:
  ${partner.displayName}

  Entity Id:
  ${partner.id}

  Key Id:
  ${state.keyId}

  Request removal of all keys for entity: No
  Requestor: ${user}
  
  Please note this request requires Platform Management approval before being completed.`,
  )}`

  const saveKeyToFile = () => {
    const fileName =
      "AC-" +
      partner.displayName
        .slice(0, 80 - 7 /* account for "AC-" and  ".pem" */)
        .replace(/\s/g, "-") +
      ".pem"

    downloadFile(fileName, state.publicKey, "text/plain")
  }

  if (state.stage === "LOADING")
    return (
      <Shell>
        <CircularProgress />
      </Shell>
    )

  if (state.stage === "ERROR")
    return (
      <Shell>
        <Typography variant="body2">
          An error occurred getting keys for this partner.
          <br />
          Try refreshing the page.
        </Typography>
      </Shell>
    )

  if (state.stage === "EMPTY")
    return (
      <Shell>
        <Typography variant="body2">
          Developer keys are used for authentication with Arria APIs.
          <br />
          Like passwords, developer keys should remain a secret.
        </Typography>
        <Box m={2} />
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={createKeySet}
        >
          Create a keyset
        </Button>
      </Shell>
    )

  return (
    <Shell>
      <Typography variant="subtitle2">Arria Connect Token</Typography>
      <pre title={state.token} className={classes.token}>
        {state.token.slice(0, 320)}...
      </pre>
      <Button
        variant="contained"
        color="primary"
        size="small"
        className={classes.button}
        onClick={() => copyToClipboard(state.token)}
        startIcon={<CopyIcon className={classes.icon} />}
      >
        Copy to clipboard
      </Button>

      <Box m={2} />

      <Typography variant="subtitle2">Public Key</Typography>
      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={saveKeyToFile}
        className={classes.button}
        startIcon={<DownloadIcon className={classes.icon} />}
      >
        Save key to file
      </Button>
      <Box m={2} />
      <Typography variant="subtitle2">
        Send Request to Revoke This Key
      </Typography>
      <Button
        variant="contained"
        color="primary"
        size="small"
        className={classes.revokeButton}
        href={revokeKeyMailTo}
        startIcon={<MailOutlineIcon className={classes.icon} />}
      >
        Revoke Key Request
      </Button>
    </Shell>
  )
}

const useStyles = makeStyles(theme => ({
  button: {
    margin: "8px 0",
    fontSize: "0.8em",
  },
  revokeButton: {
    backgroundColor: theme.palette.danger.dark,
    "&:hover": {
      backgroundColor: "#ba000d",
    },
  },
  icon: {
    fontSize: "initial",
  },
  token: {
    backgroundColor: "rgba(0, 0, 0, 0.03)",
    borderRadius: 4,
    fontFamily: "Roboto Mono, monospace",
    fontSize: 12,
    margin: "5px 0",
    overflow: "hidden",
    padding: 4,
    maxWidth: 410,
    color: "rgba(0,0,0,0.7)",

    whiteSpace: "unset",
    lineBreak: "anywhere",
  },
}))
