import React, { useEffect, useState } from "react"
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  makeStyles,
} from "@material-ui/core"
import axios from "axios"
import { Link } from "react-router-dom"
import Pagination from "../../../layout/Pagination"
import { TruncateAsRequired } from "../../../layout/TruncateAsRequired"

import { parse, format } from "date-fns"

const useStyles = makeStyles(({ spacing }) => ({
  table: {
    padding: `${spacing(2)}px 0`,
  },
  tableContainer: {
    width: "100%",
  },
  status: {
    textAlign: "center",
    padding: spacing(3),
  },
  empty: {
    minHeight: 300,
  },
}))

const DEFAULT_MESSAGE = "No device used"
export default ({ id }) => {
  const classes = useStyles()
  const [orderBy, setOrderBy] = useState("lastUsagePeriod")
  const [order, setOrder] = useState("desc")
  const [limit, setLimit] = useState(10)
  const [offset, setOffset] = useState(0)

  const [devices, setDevices] = useState(null)
  const [message, setMessage] = useState(DEFAULT_MESSAGE)

  const emptyMsg = <span style={{ color: "grey" }}>Not Available</span>

  useEffect(() => {
    const url = `${process.env.REACT_APP_REPORTING_URL}/organisation/${id}/devices`
    const payload = { sortBy: { key: orderBy, value: order }, limit, offset }

    setDevices(null)
    setMessage(DEFAULT_MESSAGE)
    axios
      .post(url, payload)
      .then(res => res?.data?.devices || [])
      .then(setDevices)
      .catch(err => {
        setDevices([])
        console.error("Getting devices errorred", err)
        setMessage("An error occurred fetching devices")
      })
  }, [id, orderBy, order, limit, offset])

  const handleSortDirectionChange = key => () => {
    setOffset(0)
    setOrderBy(key)

    if (orderBy === key) {
      // toggle current order
      setOrder(order === "asc" ? "desc" : "asc")
      return
    }

    // use sensible default order
    setOrder(key.endsWith("UsagePeriod") ? "desc" : "asc")
  }
  const handleChangeRowsPerPage = ev => {
    setOffset(0)
    setLimit(ev.target.value)
  }

  const handleChangePage = (_, newPage) => setOffset(newPage * limit)

  const toDateString = d =>
    d ? format(parse(d, "yyyyMMddHH", new Date()), "MMM d, yyyy") : emptyMsg

  const ColTitle = ({ field, label, width = "auto" }) => (
    <TableCell width={width}>
      <TableSortLabel
        active={orderBy === field}
        onClick={handleSortDirectionChange(field)}
        direction={
          orderBy === field
            ? order
            : field.endsWith("UsagePeriod")
            ? "desc"
            : "asc"
        }
      >
        {label}
      </TableSortLabel>
    </TableCell>
  )

  const Head = () => (
    <TableHead>
      <TableRow>
        <ColTitle label="Device ID" field="deviceId" />
        <ColTitle label="Viewer" field="displayName" width="20%" />
        <ColTitle label="Viewer Type" field="sourceType" />
        <ColTitle label="First Usage Date" field="firstUsagePeriod" />
        <ColTitle label="Last Usage Date" field="lastUsagePeriod" />
      </TableRow>
    </TableHead>
  )

  const DeviceRow = ({ device }) => (
    <TableRow>
      <TableCell style={{ width: "25%" }}>
        <TruncateAsRequired>{device.deviceId ?? emptyMsg}</TruncateAsRequired>
      </TableCell>
      <TableCell style={{ width: "20%" }}>
        {device.projectViewer !== "null" ? (
          <TruncateAsRequired title={device.displayName}>
            <Link to={"/user/" + device.userId}>{device.displayName}</Link>
          </TruncateAsRequired>
        ) : (
          emptyMsg
        )}
      </TableCell>
      <TableCell style={{ width: "10%" }}>
        {device.sourceType ? device.sourceType : emptyMsg}
      </TableCell>
      <TableCell style={{ width: "15%" }}>
        {toDateString(device.firstUsagePeriod)}
      </TableCell>
      <TableCell style={{ width: "15%" }}>
        {toDateString(device.lastUsagePeriod)}
      </TableCell>
    </TableRow>
  )

  const Status = ({ text, children }) => (
    <TableBody>
      <TableRow style={{ height: 200 }}>
        <TableCell colSpan={6} style={{ border: "none", textAlign: "center" }}>
          {text ? (
            <Typography variant="subtitle2" className={classes.status}>
              {text}
            </Typography>
          ) : (
            children
          )}
        </TableCell>
      </TableRow>
    </TableBody>
  )

  return (
    <TableContainer className={classes.tableContainer}>
      <Table
        className={classes.table}
        aria-label="Company devices list"
        size="small"
      >
        <Head />
        {devices === null ? (
          <Status>
            <CircularProgress />
          </Status>
        ) : devices.length === 0 ? (
          <Status text={message} />
        ) : (
          <TableBody>
            {devices.slice(0, limit).map((d, idx) => (
              <DeviceRow device={d} key={d.deviceId + idx} />
            ))}
            {devices.length < limit && (
              <TableRow style={{ height: (limit - devices.length - 1) * 33 }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        )}
      </Table>
      {devices?.length > 0 && (
        <Pagination
          data={devices}
          offset={offset}
          rowsPerPage={limit}
          rowsPerPageOptions={[10, 20, 30]}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
    </TableContainer>
  )
}
