import React, { useState, useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles"

import { withContext } from "../../../../../Utils/context"
import axios from "axios"
import { format } from "date-fns"

import { Divider, Typography, Grid } from "@material-ui/core"

import {
  ResponsiveContainer,
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Line,
  Tooltip,
} from "recharts"

import DatePicker from "../../../../layout/DatePicker"
import ChartTooltip from "../../../../layout/ChartTooltip"
import TickFormatter from "../../../../../Utils/tickFormatter"
import NumberAbbreviator from "../../../../../Utils/numberAbbreviator"

const REPORTING_API = process.env.REACT_APP_REPORTING_URL

const useStyles = makeStyles(theme => ({
  chartDivider: {
    marginBottom: 4,
  },
  keyWrapper: {
    marginTop: theme.spacing(-0.7),
    marginRight: theme.spacing(2),
  },
  keyLabel: {
    color: "#757575",
    fontSize: 13,
    fontWeight: 400,
  },
}))

const nowUTC = date => {
  let now = date ? new Date(date) : new Date()
  now.setTime(now.getTime() + now.getTimezoneOffset() * 60 * 1000)
  return now
}

function Chart(props) {
  const classes = useStyles()
  const [dates, setDates] = useState({
    startDate: nowUTC().setDate(nowUTC().getDate() - 27),
    endDate: nowUTC(),
  })
  const [data, setData] = useState([])

  useEffect(() => {
    axios({
      method: "POST",
      url: `${REPORTING_API}/c150/organisation/${props.organisationId}/wordcount/`,
      data: {
        startDate: format(dates.startDate, "yyyy-MM-dd"),
        endDate: format(dates.endDate, "yyyy-MM-dd"),
      },
    }).then(wordCountResponse => {
      axios({
        method: "POST",
        url: `${REPORTING_API}/c130/organisation/${props.organisationId}/user/total`,
        data: {
          startDate: format(dates.startDate, "yyyy-MM-dd"),
          endDate: format(dates.endDate, "yyyy-MM-dd"),
        },
      }).then(userResponse => {
        let newData = []
        wordCountResponse.data.results.map(item => {
          const index = userResponse.data.results.findIndex(
            x => x.date === item.date,
          )
          if (index !== -1) {
            newData.push({
              date: item.date,
              wordCount: item.wordCount,
              userCount: userResponse.data.results[index].userCount,
            })
          }
          return null
        })
        setData(newData)
      })
      // setData(response.data.results)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dates])

  const handleDatesChange = dates => {
    // NOTE: This is to prevent the date range and data format error on the chart
    setData([])
    setDates(dates)
  }

  return (
    <>
      <ResponsiveContainer>
        <LineChart
          data={data}
          margin={{
            top: 38,
            left: 18,
            right: 18,
            bottom: 10,
          }}
        >
          <CartesianGrid vertical={false} horizontal stroke="#eee" />
          <XAxis
            dataKey="date"
            interval={0}
            padding={{ left: 24, right: 24 }}
            tickFormatter={dateToFormat =>
              TickFormatter(dates, dateToFormat, data)
            }
            tick={{ fontSize: 12, fill: "#9e9e9e" }}
            tickMargin={4}
            stroke="#eee"
          />
          <YAxis
            tick={{ fontSize: 12, fill: "#9e9e9e", dx: 4 }}
            orientation="right"
            yAxisId="right"
            stroke="#fff"
            label={{
              value: "Word count",
              fill: "#9e9e9e",
              angle: 90,
              position: "right",
            }}
            tickFormatter={NumberAbbreviator}
          />
          <YAxis
            tick={{ fontSize: 12, fill: "#9e9e9e" }}
            yAxisId="left"
            stroke="#fff"
            domain={[
              0,
              data.length !== 0
                ? Math.ceil(data[data.length - 1].userCount * 1.3)
                : 4,
            ]}
            allowDecimals={false}
            label={{
              value: "User count",
              fill: "#9e9e9e",
              angle: -90,
              position: "left",
            }}
          />
          <Tooltip content={<ChartTooltip />} />
          <Line
            type="monotone"
            yAxisId="right"
            dataKey="wordCount"
            stroke="#346df1"
            strokeWidth={1.8}
            dot={false}
          />
          <Line
            type="monotone"
            yAxisId="left"
            dataKey="userCount"
            stroke="#C51C1C"
            strokeWidth={1.8}
            dot={false}
          />
        </LineChart>
      </ResponsiveContainer>
      <Divider className={classes.chartDivider} />
      <Grid container justify="space-between">
        <Grid item>
          <DatePicker dates={dates} onDatesChange={handleDatesChange} />
        </Grid>
        <Grid item>
          <div
            style={{
              marginTop: 4,
              marginRight: 21,
            }}
          >
            <div
              style={{
                height: 12,
                width: 12,
                backgroundColor: "#346df1",
                display: "inline-block",
                marginRight: 7,
              }}
            />
            <Typography
              className={classes.keyLabel}
              style={{
                display: "inline-block",
                marginRight: 21,
              }}
            >
              Word Count
            </Typography>
            <div
              style={{
                height: 12,
                width: 12,
                backgroundColor: "#C51C1C",
                display: "inline-block",
                marginRight: 7,
              }}
            />
            <Typography
              className={classes.keyLabel}
              style={{
                display: "inline-block",
              }}
            >
              User Count
            </Typography>
          </div>
        </Grid>
      </Grid>
    </>
  )
}

export default withContext(Chart)
