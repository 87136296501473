import React from "react"
import { Typography, Paper, Grid, makeStyles } from "@material-ui/core"
import grey from "@material-ui/core/colors/grey"

import Projects from "./Projects"
import Devices from "./Devices"
import Licenses from "./Licenses"

import {
  Redirect,
  Route,
  Switch,
  NavLink,
  useRouteMatch,
} from "react-router-dom"

export function Tabs({ user }) {
  const classes = useStyles()
  const { path, url } = useRouteMatch()

  const TabLink = ({ to, children }) => (
    <NavLink
      to={to}
      activeClassName={classes.activeTab}
      className={classes.tabLink}
    >
      <Typography variant="h6" className={classes.tabLabel}>
        {children}
      </Typography>
    </NavLink>
  )

  return (
    <>
      <div className={classes.tabs}>
        <TabLink to={`${url}/projects`}>Reports</TabLink>
        <TabLink to={`${url}/devices`}>Devices</TabLink>
        <TabLink to={`${url}/licenses`}>Licenses</TabLink>
      </div>

      <Grid item xs={12}>
        <Switch>
          <Route path={`${path}/projects`}>
            <Projects user={user} />
          </Route>
          <Route path={`${path}/devices`}>
            <Paper className="slowfadein">
              <Devices user={user} />
            </Paper>
          </Route>
          <Route path={`${path}/licenses`}>
            <Licenses user={user} />
          </Route>
          <Redirect from={path} to={`${path}/projects`} />
        </Switch>
      </Grid>
    </>
  )
}

const useStyles = makeStyles(({ spacing, palette }) => ({
  tabs: {
    margin: spacing(2),
    marginTop: spacing(4),
    display: "flex",
    borderBottom: `1px solid lightgrey`,
    width: "100%",
  },
  tabLink: {
    color: grey[500],
    marginRight: spacing(2),
  },
  tabLabel: {
    padding: 4,
    fontSize: "1.1em",
  },
  activeTab: {
    color: palette.primary.main,
    borderBottom: `2px solid ${palette.primary.main}`,
  },
}))
